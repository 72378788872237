import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Collapse,
  Card,
  CardBody,
  FormGroup,
  Badge,
  Label,
  Input,
  Button,
  Col,
  Row,
  UncontrolledTooltip,
} from "reactstrap";

import {
  RESCHEDULE_HEADHUNTER_WORKSPACE,
  HostMexxarWS,
  RESCHEDULE_REGISTRATION_WORKSPACE,
  RESCHEDULE_MAINTENANCE_WORKSPACE,
} from "../../../configs/api-config";

import axios from "axios";
import moment from "moment";
import { connect } from "react-redux";
import { forEach, filter } from "lodash";
import { useSpring, animated } from "react-spring";
import { useToasts } from "react-toast-notifications";
import { Client as StompClient } from "@stomp/stompjs";
import NoConnection from "../../errorPages/NoConnection";

import ToastCommon from "../../../components/toastCommon";
import NoSearchResults from "../../errorPages/NoSearchResults";
import loadingAnimation from "../../../../src/images/puff.svg";
import { useIsMounted } from "../../../customHooks/useIsMounted";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle,
  faHeadphones,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import useDidMountEffect from "../../../components/Modal/ModalBody/intialRunUseEffect";
import SortFunctionDescendingReschedule from "../../../components/sortFunction/sortByDateDescending.js";
import HeaduntingCallBackWidget from "../../../widgets/working-card-widget/headhunting-call-back-widget";
import { FunnelIcon } from "../../../assets/icons/svg";
import useReschdulesFetch from "../../../customHooks/useReschdulesFetch";
import DateInput from "../../../util/common/DateInput.js";
import dateFormatter from "../../../util/common/dateFormatter.js";

const CallBackPage = (props) => {
  const { updateTargets, employeeID, page } = props;


  const currentDate = new Date();
  const today = moment(moment(currentDate)).format("YYYY/MM/DD");
  const tommorow = moment(moment(currentDate).add(1, "days")).format(
    "YYYY/MM/DD"
  );

  const [checkDate, setCheckDate] = useState(false);
  const [isOpenFilter, setIsOpenFilter] = useState(false);

  const [dateFrom, setDateFrom] = useState(today);
  const [dateTo, setDateTo] = useState(tommorow);

  const [callResponse, setCallResponse] = useState("");

  const [query, setQuery] = useState("");
  const [reset, setReset] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchAdvanced, setSearchAdvanced] = useState(false);

  var Api;

  if (page == "HEADHUNTING") {
    Api = RESCHEDULE_HEADHUNTER_WORKSPACE;
  } else if (page == "REGISTRATION") {
    Api = RESCHEDULE_REGISTRATION_WORKSPACE;
  } else if (page == "MAINTENANCE") {
    Api = RESCHEDULE_MAINTENANCE_WORKSPACE;
  }
  let url =
    Api +
    "/filter?startDate=" +
    moment(dateFrom).format("YYYY/MM/DD") +
    "&endDate=" +
    moment(dateTo).format("YYYY/MM/DD") +
    "&status=ACTIVE" +
    "&callResponse=" +
    callResponse;

  const { loading, error, rows, hasMore, totalElements } = useReschdulesFetch(
    url,
    query,
    pageNumber,
    searchAdvanced,
    reset,
    employeeID
  );

  function handleSearch(e) {
    setQuery(e.target.value);
  }

  const toggleFilter = () => setIsOpenFilter(!isOpenFilter);

  //....reset function......//
  const resetFilters = () => {
    setReset(!reset);
    setCallResponse("");
    setDateFrom(moment(today).format("YYYY/MM/DD"));
    setDateTo(moment(tommorow).format("YYYY/MM/DD"));
  };

  /******* Use to set page number to default value of 1 when changing the employee or search advance filters  *******/
  useDidMountEffect(() => {
    setPageNumber(1);
  }, [searchAdvanced, employeeID]);

  const checkDateBefore = () => {
    setCheckDate(moment(dateFormatter.formatDateToMMDDYYYY(dateFrom)).isBefore(dateFormatter.formatDateToMMDDYYYY(dateTo)));
  };

  useDidMountEffect(() => {
    checkDateBefore();
  }, [dateTo, dateFrom]);

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const animationProps = useSpring({
    opacity: 1,
    transform: "translate(0px, 0px)",
    from: { opacity: 0, transform: "translate(-100px, 0px)" },
  });

  useEffect(() => {
    updateTargets();
    checkDateBefore();

    let client = new StompClient();
    client.configure({
      brokerURL: HostMexxarWS + "wsc",
      onConnect: () => {
        //console.log("onConnect");
        client.subscribe(
          `/headhunter/${JSON.parse(localStorage.user).id}`,
          (message) => {
            // getAllRows();
          }
        );
      },
    });
    client.activate();
  }, [employeeID]);

  return (
    <>
      <div className="form-inline">
        <FontAwesomeIcon icon={faHeadphones} className={"ml-4 "} />

        <Label className={"ml-3 mr-3"}>
          {totalElements}{" "}
          {totalElements.length == 1 ? "Reschedule" : "Reschedules"}
        </Label>
        <Button onClick={toggleFilter} className="btn bg-dark-lt text-dark">
          <FunnelIcon />
          <span className="mx-1">Filter</span>
        </Button>
        <Input
          onChange={handleSearch}
          type="text"
          id="searchBar"
          className="form-control no-border no-shadow no-bg typeahead tt-input"
          placeholder="Search Candidates..."
          autoComplete="off"
          spellCheck="false"
          dir="auto"
          style={{
            position: "relative",
            verticalAlign: "top",
            backgroundColor: "transparent",
            // textTransform: "lowercase",
          }}
        />

        <UncontrolledTooltip target="searchBar">
          Search candidates by Name/Id/Email/Phone No.
        </UncontrolledTooltip>
      </div>
      <br></br>
      <Collapse isOpen={isOpenFilter}>
        <Card>
          <CardBody>
            <Row>
              <Col>
                <Label for="exampleDate">Start Date</Label>

                <DateInput
                  id="dateFrom"
            
                  value={dateFormatter.formatDateToDDMMYYYY(dateFrom)}
                  onChange={(date) =>
                    setDateFrom(dateFormatter.formatDateToYYYYMMDDSLASHFROMDDMMYYYY(date))
                  }
                />
                {!checkDate && (
                  <ul
                    className="list-unstyled text-sm mt-1 text-muted filled"
                    id="parsley-id-119"
                  >
                    <li className="parsley-required text-danger">
                      <FontAwesomeIcon
                        icon={faExclamationCircle}
                        className="mx-1 bg"
                      />
                      Invalid Date
                    </li>
                  </ul>
                )}
              </Col>
              <Col>
                <Label for="exampleDate">End Date</Label>

                <DateInput
                  id="dateTo"
               value={dateFormatter.formatDateToDDMMYYYY(dateTo)}
                  onChange={(date) =>
                    setDateTo(dateFormatter.formatDateToYYYYMMDDSLASHFROMDDMMYYYY(date))
                  }
                />
              </Col>

              <Col>
                <Label>Call Response</Label>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value=""
                      checked={callResponse === ""}
                      onChange={(e) => setCallResponse(e.target.value)}
                    />
                    <Badge color="info">All</Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value="HOT"
                      checked={callResponse === "HOT"}
                      onChange={(e) => setCallResponse(e.target.value)}
                    />
                    <Badge color="danger">Hot</Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value="NEUTRAL"
                      checked={callResponse === "NEUTRAL"}
                      onChange={(e) => setCallResponse(e.target.value)}
                    />
                    <Badge color="secondary">Neutral</Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value="COLD"
                      checked={callResponse === "COLD"}
                      onChange={(e) => setCallResponse(e.target.value)}
                    />
                    <Badge color="primary">Cold</Badge>
                  </Label>
                </FormGroup>
              </Col>

              <Col></Col>
              <Col></Col>

              <Col>
                <div className="d-flex flex-column align-items-end">
                  <Button
                    color="success m-1 btn-sm"
                    style={{ width: "70px" }}
                    onClick={() => {
                      setSearchAdvanced(!searchAdvanced);
                    }}
                    disabled={!checkDate}
                  >
                    Search
                  </Button>
                  <Button
                    color="danger m-1 btn-sm"
                    style={{ width: "70px" }}
                    onClick={resetFilters}
                  >
                    Reset
                  </Button>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Collapse>

      {error && (
        <div>
          <NoConnection error={error}></NoConnection>
        </div>
      )}

      {rows?.map((row, i) => {
        if (rows.length === i + 1) {
          return (
            <animated.div
              style={animationProps}
              ref={lastBookElementRef}
              key={row.email}
            >
              <HeaduntingCallBackWidget key={i} userProfile={row} />
            </animated.div>
          );
        } else {
          return (
            <animated.div style={animationProps} key={row.email}>
              <HeaduntingCallBackWidget key={i} userProfile={row} />
            </animated.div>
          );
        }
      })}

      {rows?.length == 0 && !loading && <NoSearchResults page="reschedule" />}

      {loading && !error && (
        <div className="d-flex justify-content-center align-items-center">
          <div>
            <img src={loadingAnimation} style={{ height: 100 }}></img>
          </div>
        </div>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps)(CallBackPage);
