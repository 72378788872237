import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Collapse,
  Card,
  CardBody,
  Label,
  Input,
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  FormGroup,
  Badge
} from "reactstrap";

import {
  CLIENTS,
  WARDS,
  SHIFTS,
  CREATE_BOOKING,
  HOSTMexxar,
  ClientLable,
} from "../../../configs/api-config";

import axios from "axios";
import moment from "moment";
import { connect } from "react-redux";
import { forEach, isNull, filter } from "lodash";
import NoConnection from "../../errorPages/NoConnection";
import loadingAnimation from "../../../../src/images/puff.svg";
import NoSearchResults from "../../errorPages/NoSearchResults";
import { useIsMounted } from "../../../customHooks/useIsMounted";
import BookingSummeryWorkSpace from "../../../widgets/booking-summery-card-booking-workspace/booking-summery-card-booking-workspace";
import { FunnelIcon } from "../../../assets/icons/svg";
import ExternalShiftsCardWidget from "../../../widgets/working-card-widget/externalShiftsCardWidget";
import CancelShiftsCardWidget from "../../../widgets/working-card-widget/cancelShiftCardWidget";
import dateFormatter from "../../../util/common/dateFormatter";
import DateInput from "../../../util/common/DateInput";
import useBookingCancelFetch from "../../../customHooks/useBookingCancelFetch";
import useDidMountEffect from "../../../components/Modal/ModalBody/intialRunUseEffect";
import { useSpring, animated } from "react-spring";


const BookingShiftsCancellation = (props) => {

  const currentDate = new Date();


  const yesterDay = dateFormatter.formatDateToYYYYMMDDSlash(moment(currentDate).subtract(7, "days"));
  const tommorow = dateFormatter.formatDateToYYYYMMDDSlash(moment(currentDate).add(7, "days"));


  const filterByName = (name, dataSet) => {
    let filtered = dataSet;
    if (name !== "") {
      filtered = dataSet.filter((item) => {
        let searchableString = `${item.candidateName} ${item.referenceNo} ${item.candidatesId}   ${item.clientName} ${item.ward.name}   `;
        let p = searchableString;
        return p.toLowerCase().includes(name.toLowerCase());
      });
    }
    let lengthofSearch = filtered.length;
    return { filtered, lengthofSearch };
  };


  const [search, setSearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [getEndDate, setGetEndDate] = useState(tommorow);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [getStartDate, setGetStartDate] = useState(yesterDay);
  const [query, setQuery] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchAdvanced, setSearchAdvanced] = useState(false);
  const [reset, setReset] = useState(false);
  const [resetState, setResetState] = useState(false);
  const [shiftType, setShiftType] = useState("");
  const [status, setStatus] = useState("");


  const animationProps = useSpring({
    opacity: 1,
    transform: "translate(0px, 0px)",
    from: { opacity: 0, transform: "translate(-100px, 0px)" },
  });


  const toggleFilter = () => setIsOpenFilter(!isOpenFilter);

  let url = HOSTMexxar + "bookings/cancellationHistory";

  const { loading, error, rows, hasMore, totalElements } = useBookingCancelFetch(
    url,
    query,
    pageNumber,
    searchAdvanced,
    reset,
    status,
    getStartDate,
    getEndDate,
    shiftType,
    search
  );

  const updateUserProfileStatus = (id, newStatus) => {
    rows(prevProfiles =>
      prevProfiles.map(profile =>
        profile.id === id ? { ...profile, onboardingStatus: newStatus } : profile
      )
    );
  };


  function handleSearchOnChange(e) {
    if (e.target.value == "") {
      setQuery(null);
    } else {
      setQuery(e.target.value);
    }
    setPageNumber(1);
  }

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  console.log("status", status);

  const resetFilters = () => {
    setReset(!reset);
    setStatus("");

    setPageNumber(1);
    setGetStartDate(yesterDay)
    setGetEndDate(tommorow)
    setShiftType("");
  };

  useDidMountEffect(() => {
    setPageNumber(1);
    return () => { };
  }, [searchAdvanced, reset]);
  return (
    <>

      <div className="form-inline">
        <Button onClick={toggleFilter} className="btn bg-dark-lt text-dark">
          <FunnelIcon />
          <span className="mx-1">Filter</span>
        </Button>
        <Input
          id="searchBar"
          onChange={(e) => handleSearchOnChange(e)}
          type="text"
          className="form-control no-border no-shadow no-bg typeahead tt-input"
          placeholder="Search Bookings..."
          autoComplete="off"
          spellCheck="false"
          dir="auto"
          style={{
            position: "relative",
            verticalAlign: "top",
            backgroundColor: "transparent",
            // textTransform: "lowercase",
          }}
        />
        <UncontrolledTooltip target="searchBar">
          Search by Candidate name/Candidate id/Reference No./{ClientLable}/Department
        </UncontrolledTooltip>
      </div>
      <br></br>
      <Collapse isOpen={isOpenFilter}>
        <Card>
          <CardBody>
            <Row>
              <Col>
                <Label for="exampleDate">Start Date</Label>
                <DateInput
  id="startDate"
  value={dateFormatter.formatDateToDDMMYYYY(getStartDate)}
  onChange={(date) => setGetStartDate(dateFormatter.formatDateToYYYYMMDD(date))}
/>
              </Col>
              <Col>
                <Label for="exampleDate">End Date</Label>
              
                <DateInput
  id="endDate"
  value={dateFormatter.formatDateToDDMMYYYY(getEndDate)}
  onChange={(date) => setGetEndDate(dateFormatter.formatDateToYYYYMMDD(date))}
/>
              </Col>
             
              
         
                <Label for="exampleDate">End Date</Label>
                
              <Col>
                <Label>Shift Type</Label>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value=""
                      checked={shiftType === ""}
                      onChange={(e) => setShiftType(e.target.value)}
                      style={{ cursor: "pointer" }}
                    />
                    <Badge color="info">All</Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value="E"
                      checked={shiftType === "E"}
                      onChange={(e) => setShiftType(e.target.value)}
                      style={{ cursor: "pointer" }}
                    />
                    <Badge color="danger">E</Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value="LD"
                      checked={shiftType === "LD"}
                      onChange={(e) => setShiftType(e.target.value)}
                      style={{ cursor: "pointer" }}
                    />
                    <Badge color="secondary">LD</Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value="N"
                      checked={shiftType === "N"}
                      onChange={(e) => setShiftType(e.target.value)}
                      style={{ cursor: "pointer" }}
                    />
                    <Badge color="primary">N</Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value="L"
                      checked={shiftType === "L"}
                      onChange={(e) => setShiftType(e.target.value)}
                      style={{ cursor: "pointer" }}
                    />
                    <Badge color="success">L</Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value="TW"
                      checked={shiftType === "TW"}
                      onChange={(e) => setShiftType(e.target.value)}
                      style={{ cursor: "pointer" }}
                    />
                    <Badge color="warning">TW</Badge>
                  </Label>
                </FormGroup>
              </Col>


              <Col>
                <Label>Status</Label>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value=""
                      checked={status === ""}
                      onChange={(e) => setStatus(e.target.value)}
                      style={{ cursor: "pointer" }}
                    />
                    <Badge color="info">All</Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value="CANCELED_BY_HOSPITAL"
                      checked={status === "CANCELED_BY_HOSPITAL"}
                      onChange={(e) => setStatus(e.target.value)}
                      style={{ cursor: "pointer" }}
                    />
                    <Badge color="danger">Hospital</Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value="CANCELED_BY_CANDIDATE"
                      checked={status === "CANCELED_BY_CANDIDATE"}
                      onChange={(e) => setStatus(e.target.value)}
                      style={{ cursor: "pointer" }}
                    />
                    <Badge color="secondary">Candidate</Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value="BOOKED_BY_ERROR"
                      checked={status === "BOOKED_BY_ERROR"}
                      onChange={(e) => setStatus(e.target.value)}
                      style={{ cursor: "pointer" }}
                    />
                    <Badge color="success">Added by an Error</Badge>
                  </Label>
                </FormGroup>


              </Col>



              <Col></Col>

              <Col>
                <div className="d-flex flex-column align-items-end">
                  <Button
                    color="success m-1 btn-sm"
                    style={{ width: "70px" }}
                    onClick={() => {
                      setSearchAdvanced(!searchAdvanced);
                    }}
                  >
                    Search
                  </Button>
                  <Button
                    color="danger m-1 btn-sm"
                    onClick={resetFilters}
                    style={{ width: "70px" }}
                  >
                    Reset
                  </Button>
                </div>
              </Col>
            </Row>
            <Row></Row>
          </CardBody>
        </Card>
      </Collapse>
      {error && (
        <div>
          <NoConnection error={error}></NoConnection>
        </div>
      )}


      {rows?.map((row, i) => {
        if (rows.length === i + 1) {
          return (
            <animated.div
              style={animationProps}
              ref={lastBookElementRef}
              key={row.email}
            >
              <CancelShiftsCardWidget
                key={i}
                userProfile={row}
                updateUserProfileStatus={updateUserProfileStatus}
                reset={() => setResetState(!resetState)}
              ></CancelShiftsCardWidget>
            </animated.div>
          );
        } else {
          return (
            <animated.div style={animationProps} key={row.email}>
              <CancelShiftsCardWidget
                key={i}
                userProfile={row}
                updateUserProfileStatus={updateUserProfileStatus}
                reset={() => setResetState(!resetState)}
              ></CancelShiftsCardWidget>
            </animated.div>
          );
        }
      })}
      {
        rows && !loading ? (
          filterByName(searchQuery, rows).length > 0 ? (
            filterByName(searchQuery, rows).map((row, i) => (
              <CancelShiftsCardWidget
                key={i}
                userProfile={row}
                updateUserProfileStatus={updateUserProfileStatus}
                reset={() => setResetState(!resetState)}
              ></CancelShiftsCardWidget>
            ))
          ) : null
        ) : null
      }



      {loading && !error && (
        <div className="d-flex justify-content-center align-items-center">
          <div>
            <img src={loadingAnimation} style={{ height: 100 }}></img>
          </div>
        </div>
      )}

      {!loading && !error && rows.length == 0 && <NoSearchResults />}

    </>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {})(BookingShiftsCancellation);
