import React, { useState, useEffect } from "react";
import HeadhunterWorkspace from "./workspace/workspace";
import DashboardLayout from "../../layouts/dashboard-layout/dashboard";
import HeadHunterRegistrationStatus from "./registrationStatus/registrationStatus.js";
import HeadHunterRegistrationStatus2 from "./registrationStatus/registrationStatus2.js";
import HeadHunterCallBacks from "./callBacks/callBacks";
import {
  HEADHUNTER_WORKSPACE_SUMMARY,
  Add_NEW_EMPLOYEE,
} from "../../configs/api-config";
import axios from "axios";
import { connect } from "react-redux";
import { Input, Label, UncontrolledTooltip } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleUp,
  faArrowAltCircleDown,
} from "@fortawesome/free-solid-svg-icons";
import useDidMountEffect from "../../components/Modal/ModalBody/intialRunUseEffect";
import { filter } from "lodash";
import CallBackPage from "./callBacks/callBackPage";
import { useIsMounted } from "../../customHooks/useIsMounted";
import TabNavigationMenu from "../../util/common/tabNavigation";
import MovableWidget from "../../widgets/movableWidget";

const HeadHunter = (props) => {
  const { auth } = props;
  const MexxarApi = HEADHUNTER_WORKSPACE_SUMMARY;
  const MexxarApiEmployee = Add_NEW_EMPLOYEE;
  const isMounted = useIsMounted();

  const [summery, setSummery] = useState({});
  const [employee, setEmployee] = useState([]);
  const [showEmployee, setShowEmployee] = useState(false);
  const [defaultEmployee, setDefaultEmployee] = useState([]);
  const [rememberEmployee, setRememberEmployee] = useState(false);
  const [employeeID, setEmployeeID] = useState(auth.item.userTypeId);
  const [currentTab, setCurrentTab] = useState("headhunterWorkspace");

  const tab = window.location.hash.substring(1);
  useEffect(() => {
    if (tab === "") {

      setCurrentTab("headhunterWorkspace")
    } else {
      setCurrentTab(tab)
    }
  }, [tab])


  const switchTabHandler = (tab) => {
    setCurrentTab(tab);
  };
  const getData = () => {

    if (employeeID) {
      axios
        .get(MexxarApi + "?employeeId=" + Number(employeeID))
        .then((res) => {
          if (isMounted.current) {
            setSummery(res.data.body[0]);
          }
        })
        .catch((err) => {
          //   console.log(err);
          // toastCommon(true, "error");
        });
    }
  };

  const getEmployees = (workspaceEmployeeSave, employeeId) => {
    axios
      .get(MexxarApiEmployee)
      .then((res) => {
        if (isMounted.current) {
          let reponse = res.data.body;
    
          let names = [];
          reponse.forEach((item) => {
            names.push({
              id: item.id,
              firstName: item.firstName,
              lastName: item.lastName,
            });
          });
          setEmployee(names);

          if (workspaceEmployeeSave == "true") {
            let tempEmployee = filter(names, (employee) => {
              return employee.id === Number(employeeId);
            });
            setDefaultEmployee(tempEmployee);
          } else {
            let tempEmployee = filter(names, (employee) => {
              return employee.id === auth.item.userTypeId;
            });
            setDefaultEmployee(tempEmployee);
          }
        }
      })
      .catch((err) => {
        //console.log(err)
        // toastCommon(true, "error");
      });
  };

  useDidMountEffect(() => {
    localStorage.setItem("WorkspaceEmployeeSave", rememberEmployee);
    localStorage.setItem("WorkspaceEmployeeId", employeeID);
  }, [rememberEmployee]);

  useDidMountEffect(() => {
    localStorage.setItem("WorkspaceEmployeeSave", rememberEmployee);
    localStorage.setItem(
      "WorkspaceEmployeeId",
      employeeID == 0 ? auth.item.userTypeId : employeeID
    );
    getData();
  }, [employeeID]);

  useEffect(() => {
    const workspaceEmployeeSave = localStorage.getItem("WorkspaceEmployeeSave");
    const employeeId = localStorage.getItem("WorkspaceEmployeeId");

    getEmployees(workspaceEmployeeSave, employeeId);

    if (workspaceEmployeeSave == "true") {
      setRememberEmployee(true);
      setShowEmployee(true);
      setEmployeeID(Number(employeeId));
    } else {
      localStorage.setItem("WorkspaceEmployeeSave", false);
      localStorage.setItem("WorkspaceEmployeeId", auth.item.userTypeId);
      setEmployeeID(auth.item.userTypeId);
    }
  }, []);

  return (
    <>
      <DashboardLayout
        title="Headhunter Workspace"
        subTitle="Acheive Your Daily Targets"
        showHeadhunterTargets={true}
        RegisterNewButtonShow={true}
        summeryData={summery}
      >
        <div className="d-flex align-items-end flex-column">
          {showEmployee ? (
            <div>
              {" "}
              <FontAwesomeIcon
                icon={faArrowAltCircleUp}
                className="btn-raised"
                id="arrowUp"
                style={{ boxShadow: "none", cursor: "pointer" }}
                onClick={() => setShowEmployee(false)}
              ></FontAwesomeIcon>
            </div>
          ) : (
            <div>
              <FontAwesomeIcon
                icon={faArrowAltCircleDown}
                id="arrowDown"
                className="btn-raised"
                style={{ boxShadow: "none", cursor: "pointer" }}
                onClick={() => setShowEmployee(true)}
              ></FontAwesomeIcon>{" "}
              <UncontrolledTooltip placement="left" target="arrowDown">
                Click to show/hide consultants
              </UncontrolledTooltip>
            </div>
          )}

          {showEmployee ? (
            <div>
              <Label className={"mr-3"}>Get Candidates by Employee</Label>
              {employee.length > 0 ? (
                <Input
                  type="select"
                  id="assigneeId"
                  style={{ width: "200px" }}
                  name="assigneeId"
                  value={employeeID.id}
                  defaultValue={employeeID}
                  onChange={(e) => setEmployeeID(e.target.value)}
                >
                  {/* <option value={0}>Select</option> */}

                  {employee.map((item, id) => {
                    return (
                      <option key={id} value={item.id}>
                        {item.firstName} {item.lastName}
                      </option>
                    );
                  })}
                </Input>
              ) : null}
              <div>
                <small>
                  Remember
                  <Input
                    className="mx-2 pointer"
                    type="checkbox"
                    value="REMEMBEREMPLOYEE"
                    checked={rememberEmployee}
                    onChange={() => setRememberEmployee(!rememberEmployee)}
                  ></Input>
                </small>
              </div>
            </div>
          ) : null}
        </div>
        <TabNavigationMenu
          activated={currentTab}
          onChangeItem={switchTabHandler}
          itemList={[
            { name: "Workspace-Active", key: "headhunterWorkspace" },
            {
              name: "Workspace-Unverified",
              key: "headhunterWorkspaceUnverified",
            },
            { name: "Rescheduled", key: "reschedule" },
            { name: "My Cleared/In Progress Candidates ", key: "regStatus" },
            // { name: "My Cleared/In Progress Candidates New  ", key: "regStatus2" },
          ]}
        />

        {currentTab === "headhunterWorkspaceUnverified" && (
          <HeadhunterWorkspace
            updateTargets={() => getData()}
            employeeID={employeeID}
            candidateStatus="UNVERIFIED"
          />
        )}

        {currentTab === "headhunterWorkspace" && (
          <HeadhunterWorkspace
            updateTargets={() => getData()}
            employeeID={employeeID}
            candidateStatus="ACTIVE"
          />
        )}
        {currentTab === "reschedule" && (
          <CallBackPage
            updateTargets={() => getData()}
            employeeID={employeeID}
            page="HEADHUNTING"
          />
          // <HeadHunterCallBacks
          //   updateTargets={() => getData()}
          //   employeeID={employeeID}
          // />
        )}
        {currentTab === "regStatus" && (
          <HeadHunterRegistrationStatus employeeID={employeeID} />
        )}
        {/* {currentTab === "regStatus2" && (
          <HeadHunterRegistrationStatus2 
          // updateTargets={() => getData()}
          employeeID={employeeID} />
        )} */}
      </DashboardLayout>
    </>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps)(HeadHunter);
