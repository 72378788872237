//**************Important//**************
//If a ward is assign to a client. That ward have its own id as well wardId
//if we use /ward/ Api it returns id of the ward
//if we use /client-ward/ api it retrurns client's ward id, which comes as "id" in return object. which is what we should use in retriving and setting rates

import React, { useState, useEffect } from "react";
import {
  specialitiesGetAction,
  clientsGetAction,
  rolesGetAction,
  departmentsGetAction,
  bandsGetAction,
  gradesGetAction,
} from "../../../redux/actions/initialDataGetAction";

import axios from "axios";
import { connect } from "react-redux";
import store from "../../../redux/store";
import { forEach, filter } from "lodash";
import { useForm } from "react-hook-form";
import useDidMountEffect from "./intialRunUseEffect";
import { Button, Input, Form, FormGroup, Label, Col, Alert } from "reactstrap";
import {
  CLIENT_RATE_DATA,
  ClientLable,
  HOSTMexxar,
} from "../../../configs/api-config";
import ToastCommon from "../../toastCommon";
import { useToasts } from "react-toast-notifications";
import { UncontrolledTooltip } from "reactstrap/lib";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import sortByCustomOrder from "../../../util/common/sortByCustomOrder";
import { formatToHHMMSS } from "../../../util/common/formatToHHMMSS";
import moment from "moment";

function ClientRateModal(props) {
  const MexxarApiClientRate = HOSTMexxar + "client-rate-grade";

  const { addToast } = useToasts();

  const { register, handleSubmit, errors } = useForm();
  const { toggle, client, reduxClients, reduxDepartments, reduxGrades } = props;

  //drop down lists
  const [clients, setClients] = useState(null);
  const [grades, setGrades] = useState(null);

  const [departments, setDepartments] = useState(null);

  const [update, setUpdate] = useState(false);
  const [editable, setEditable] = useState(false);
  const [clientID, setClientID] = useState(client.id);

  const [escalationExpDate, setEscalationExpDate] = useState("");

  // current states
  const [currentGrade, setCurrentGrade] = useState(1);
  const [currentClient, setCurrentClient] = useState(-1);
  const [currentDepartment, setCurrentDepartment] = useState(-1);

  //prefixed rate types to set ids for each section
  const [rateTypes, setRateTypes] = useState([
    { type: "Rate Week Days", key: 0, shortForm: "WEEK" },
    { type: "Rate Saturday", key: 1, shortForm: "SAT" },
    ,
    { type: "Rate Sunday", key: 2, shortForm: "SUN" },
    { type: "Rate Bank Holiday", key: 3, shortForm: "BH" },
  ]);

  //For default rates
  const [defaultRatesNotAddedError, setDefaultRatesNotAddedError] =
    useState(false);
  const [isdefineRatesByWards, setIsdefineRatesByWards] = useState(false);

  const onSubmit = (data) => {
    //check if this is an update or new entry
    let defaultWardID = departments.find((x) => x.wardName === "Default Ward");

    let expDateTemp = grades?.some(
      (grade) => grade.id === Number(currentGrade) && grade.escalation
    )
      ? true
      : false;

    // console.log("angi inside onsubmit---1-->", data);

    console.log(
      "angi inside onsubmit---35--escalationExpDate>",
      escalationExpDate
    );
    console.log("angi inside onsubmit---35--current Grade>", currentGrade);

    if (update == false) {
      let tempArray = [];

      console.log("angi inside onsubmit---2-->", data);

      for (let i = 0; i < 4; i++) {
        let BonusRate = Number(data[i + "BonusRate"]);
        let BonusChargeRate = Number(data[i + "BonusChargeRate"]);
        let DayRate = Number(data[i + "DayRate"]);
        let DayChargeRate = Number(data[i + "DayChargeRate"]);
        let DStartTime = formatToHHMMSS(data[i + "DStartTime"]);
        let DEndTime = formatToHHMMSS(data[i + "DEndTime"]);
        let NightRate = Number(data[i + "NightRate"]);
        let NightChargeRate = Number(data[i + "NightChargeRate"]);
        let NStartTime = formatToHHMMSS(data[i + "NStartTime"]);
        let NEndTime = formatToHHMMSS(data[i + "NEndTime"]);

        tempArray.push(
          //......Bonus Day Rate .......//
          {
            dayType: i,
            payRollDayMode: "DAY",
            rate: BonusRate,
            chargeRate: BonusChargeRate,
            rateMode: "SPECIAL",
            clientId: clientID,
            clientWardId: currentDepartment,
            startTime: DStartTime,
            endTime: DEndTime,
            rateGradesId: Number(currentGrade),
            expireDate: expDateTemp ? escalationExpDate : "",
          },
          //......Bonus Night Rate .......//
          {
            dayType: i,
            payRollDayMode: "NIGHT",
            rate: BonusRate,
            chargeRate: BonusChargeRate,
            rateMode: "SPECIAL",
            clientId: clientID,
            clientWardId: currentDepartment,
            startTime: NStartTime,
            endTime: NEndTime,
            rateGradesId: Number(currentGrade),
            expireDate: expDateTemp ? escalationExpDate : "",
          },
          //......Day Rate.......//
          {
            dayType: i,
            payRollDayMode: "DAY",
            rate: DayRate,
            chargeRate: DayChargeRate,
            rateMode: "DEFAULT",
            clientId: clientID,
            clientWardId: currentDepartment,
            startTime: DStartTime,
            endTime: DEndTime,
            rateGradesId: Number(currentGrade),
            expireDate: expDateTemp ? escalationExpDate : "",
          },
          //......Night Rate.......//
          {
            dayType: i,
            payRollDayMode: "NIGHT",
            rate: NightRate,
            chargeRate: NightChargeRate,
            rateMode: "DEFAULT",
            clientId: clientID,
            clientWardId: currentDepartment,
            startTime: NStartTime,
            endTime: NEndTime,
            rateGradesId: Number(currentGrade),
            expireDate: expDateTemp ? escalationExpDate : "",
          }
        );
      }

      console.log("angi inside onsubmit---3-->", tempArray);

      forEach(tempArray, function (data) {
        axios
          .post(MexxarApiClientRate, data)
          .then((res) => {
            if (res.data.status == "success") {
              // getData(clientID);
              // setShowAddRow(!showAddRow);
              // toastCommon(true, "success");
            } else {
              //   toastCommon(true, "duplicate");
            }
          })
          .catch((err) => {
            ToastCommon(true, "error", addToast);
          });
      });
      // toggle(client);
    } else {
      let tempArray = [];

      for (let i = 0; i < 4; i++) {
        let BonusRate = Number(data[i + "BonusRate"]);
        let BonusChargeRate = Number(data[i + "BonusChargeRate"]);
        let DayRate = Number(data[i + "DayRate"]);
        let DayChargeRate = Number(data[i + "DayChargeRate"]);
        let DStartTime = formatToHHMMSS(data[i + "DStartTime"]);
        let DEndTime = formatToHHMMSS(data[i + "DEndTime"]);
        let NightRate = Number(data[i + "NightRate"]);
        let NightChargeRate = Number(data[i + "NightChargeRate"]);
        let NStartTime = formatToHHMMSS(data[i + "NStartTime"]);
        let NEndTime = formatToHHMMSS(data[i + "NEndTime"]);

        let DayRateId = data[i + "DayRate" + "ID"];
        let NightRateId = data[i + "NightRate" + "ID"];
        let BonusRateDayId = data[i + "BonusRateD" + "ID"];
        let BonusRateNightId = data[i + "BonusRateN" + "ID"];

        tempArray.push(
          //......Bonus Day Rate .......//
          {
            id: Number(BonusRateDayId),
            dayType: i,
            payRollDayMode: "DAY",
            rate: BonusRate,
            chargeRate: BonusChargeRate,
            rateMode: "SPECIAL",
            clientId: clientID,
            clientWardId: currentDepartment,
            startTime: DStartTime,
            endTime: DEndTime,
            rateGradesId: Number(currentGrade),
            expireDate: expDateTemp ? escalationExpDate : "",
          },

          //......Bonus Night Rate .......//
          {
            id: Number(BonusRateNightId),
            dayType: i,
            payRollDayMode: "NIGHT",
            rate: BonusRate,
            chargeRate: BonusChargeRate,
            rateMode: "SPECIAL",
            clientId: clientID,
            clientWardId: currentDepartment,
            startTime: NStartTime,
            endTime: NEndTime,
            rateGradesId: Number(currentGrade),
            expireDate: expDateTemp ? escalationExpDate : "",
          },
          //......Day Rate.......//
          {
            id: Number(DayRateId),
            dayType: i,
            payRollDayMode: "DAY",
            rate: DayRate,
            chargeRate: DayChargeRate,
            rateMode: "DEFAULT",
            clientId: clientID,
            clientWardId: currentDepartment,
            startTime: DStartTime,
            endTime: DEndTime,
            rateGradesId: Number(currentGrade),
            expireDate: expDateTemp ? escalationExpDate : "",
          },
          //......Night Rate.......//
          {
            id: Number(NightRateId),
            dayType: rateTypes?.[i]?.shortForm,
            payRollDayMode: "NIGHT",
            rate: NightRate,
            chargeRate: NightChargeRate,
            rateMode: "DEFAULT",
            clientId: clientID,
            clientWardId: currentDepartment,
            startTime: NStartTime,
            endTime: NEndTime,
            rateGradesId: Number(currentGrade),
            expireDate: expDateTemp ? escalationExpDate : "",
          }
        );
      }

      forEach(tempArray, function (data) {
        axios
          .put(MexxarApiClientRate, data)
          .then((res) => {
            if (res.data.status === "success") {
              // getData(clientID);
              // setShowAddRow(!showAddRow);
              // toastCommon(true, "success");
            } else {
              //   toastCommon(true, "duplicate");
            }
          })
          .catch((err) => {
            //console.log(err)
            //toastCommon(true, "error");
          });
      });
      // toggle(client);
    }
  };

  const typeFinder = (data) => {
    let shortForm;
    let type;
    let key;
    switch (data) {
      case "WEEK":
        shortForm = data;
        type = "Rate Week Days";
        key = 0;
        break;
      case "SAT":
        shortForm = data;
        type = "Rate Saturday";
        key = 1;
        break;
      case "SUN":
        shortForm = data;
        type = "Rate Sunday";
        key = 2;
        break;
      case "BH":
        shortForm = data;
        type = "Rate Bank Holiday";
        key = 3;
        break;
    }

    return { shortForm: shortForm, type: type, key: key };
  };

  const getClientRates = (clientId, currentDepartment, rateGradesId) => {
    let url = HOSTMexxar + "client-rate-grade/clientRateGrades";

    axios({
      method: "GET",
      url: url,
      params: {
        page: 1,
        offset: 20,
        clientId: clientId,
        clientWardId: currentDepartment,
        rateGradesId: rateGradesId,
      },
    }).then((res) => {
      let response = res.data.body.content;
      if (response?.length > 0) {
        setUpdate(true);
        setEscalationExpDate(
          moment(response?.[0]?.expireDate).format("YYYY-MM-DD")
        );
      } else {
        setUpdate(false);
        setEscalationExpDate("");
      }

      let DayType = ["WEEK", "SAT", "SUN", "BH"];
      let DayNight = ["DAY", "NIGHT"];
      let DefaultSpecial = ["D", "NIGHT"];

      let tempUpdatingArray = [];
      let tempArray = [];
      forEach(DayType, function (daytype) {
        //......filter Day Rate.........//

        let ByDayType = filter(response, (item) => {
          return item.dayType === daytype;
        });
        console.log(`Client ID: ${clientId}`);

        // let DayNight = filter(response, (item) => {
        //   return item.dayType === daytype;
        // });

        let DayNightNight = filter(ByDayType, (item) => {
          return item.payRollDayMode === "NIGHT";
        });

        let DayNightDay = filter(ByDayType, (item) => {
          return item.payRollDayMode === "DAY";
        });

        // Special

        let RateModeSpecial = filter(ByDayType, (item) => {
          return item.rateMode === "SPECIAL";
        });

        let RateModeSpecialDay = filter(RateModeSpecial, (item) => {
          return item.payRollDayMode === "DAY";
        });

        let RateModeSpecialNight = filter(RateModeSpecial, (item) => {
          return item.payRollDayMode === "NIGHT";
        });
        // Default

        let RateModeDefault = filter(ByDayType, (item) => {
          return item.rateMode === "DEFAULT";
        });

        let RateModeDefaultDay = filter(RateModeDefault, (item) => {
          return item.payRollDayMode === "DAY";
        });

        let RateModeDefaultNight = filter(RateModeDefault, (item) => {
          return item.payRollDayMode === "NIGHT";
        });

        tempArray.push({
          type: typeFinder(daytype).type,
          key: typeFinder(daytype).key,
          shortForm: daytype,

          nId: null,
          dId: null,
          sDId: null,
          sNId: null,

          nStart: null,
          nEnd: null,
          nRate: null,

          dStart: null,
          dEnd: null,
          dRate: null,

          bRate: null,
        });

        tempUpdatingArray.push({
          type: typeFinder(daytype).type,
          key: typeFinder(daytype).key,
          shortForm: daytype,

          nId:
            RateModeDefaultNight[0] === undefined
              ? ""
              : Number(RateModeDefaultNight[0].id),
          dId:
            RateModeDefaultDay[0] === undefined
              ? ""
              : Number(RateModeDefaultDay[0].id),
          sDId:
            RateModeSpecialDay[0] === undefined
              ? ""
              : Number(RateModeSpecialDay[0].id),
          sNId:
            RateModeSpecialNight[0] === undefined
              ? ""
              : Number(RateModeSpecialNight[0].id),

          nStart:
            RateModeDefaultNight[0] === undefined
              ? ""
              : RateModeDefaultNight[0].startTime,
          nEnd:
            RateModeDefaultNight[0] === undefined
              ? ""
              : RateModeDefaultNight[0].endTime,
          nRate:
            RateModeDefaultNight[0] === undefined
              ? ""
              : RateModeDefaultNight[0].rate,
          nChargeRate:
            RateModeDefaultNight[0] === undefined
              ? ""
              : RateModeDefaultNight[0].chargeRate,

          dStart:
            RateModeDefaultDay[0] === undefined
              ? ""
              : RateModeDefaultDay[0].startTime,
          dEnd:
            RateModeDefaultDay[0] === undefined
              ? ""
              : RateModeDefaultDay[0].endTime,
          dRate:
            RateModeDefaultDay[0] === undefined
              ? ""
              : RateModeDefaultDay[0].rate,
          dChargeRate:
            RateModeDefaultDay[0] === undefined
              ? ""
              : RateModeDefaultDay[0].chargeRate,

          bRate:
            RateModeSpecial[0] === undefined ? "" : RateModeSpecial[0].rate,
          bChargeRate:
            RateModeSpecial[0] === undefined
              ? ""
              : RateModeSpecial[0].chargeRate,
        });
        console.log("RateModeDefaultNight:", RateModeDefaultNight);
        console.log("nStart:", RateModeDefaultNight[0]?.startTime);
        console.log("nEnd:", RateModeDefaultNight[0]?.endTime);
      });

      // setRateTypes(tempArray);
      setRateTypes(tempUpdatingArray);
    });
  };

  console.log(
    "angi response?.[0]?.expireDate date---EscalationExpDate->",
    escalationExpDate
  );

  useDidMountEffect(() => {
    setEscalationExpDate("");
  }, [currentGrade]);

  useEffect(() => {
    setClients(reduxClients);
    setGrades(reduxGrades);
    setCurrentGrade(reduxGrades?.[0]?.id);
  }, [reduxClients, reduxDepartments, reduxGrades]);

  useEffect(() => {
    store.dispatch(specialitiesGetAction());
    store.dispatch(clientsGetAction());
    store.dispatch(departmentsGetAction());
    store.dispatch(rolesGetAction());
    store.dispatch(bandsGetAction());
    store.dispatch(gradesGetAction());

    setCurrentClient(client.id);
  }, []);

  useEffect(() => {
    if (currentClient && currentDepartment && departments && currentGrade) {
      let defaultWardID = departments?.find(
        (x) => x.wardName === "Default Ward"
      );

      if (defaultWardID?.id === undefined) {
        setDefaultRatesNotAddedError(true);
      } else {
        getClientRates(currentClient, currentDepartment, currentGrade);
        setDefaultRatesNotAddedError(false);
      }
    }
  }, [
    departments,
    currentClient,
    currentDepartment,
    currentGrade,
    isdefineRatesByWards,
  ]);

  useEffect(() => {
    axios
      .get(HOSTMexxar + "client-wards/clients/" + currentClient)
      .then((res) => {
        let sortedWards = sortByCustomOrder(
          res?.data?.body,
          ["Default Ward"],
          "wardName"
        );

        setDepartments(sortedWards);

        let defaultWardItem = sortedWards.find(
          (item) => item.wardName === "Default Ward"
        );

        // This means the codebase will set the clientWard parameter to the ID of the "Default Ward," enabling the user to define and retrieve rates without specifying a particular ward.
        if (defaultWardItem) {
          setCurrentDepartment(defaultWardItem.id); // Set the current department to the default ward ID
        } else {
          if (sortedWards.length > 0) {
            setCurrentDepartment(sortedWards?.[0].id);
          } else {
            setCurrentDepartment(-1);
          }
        }
      })
      .catch((err) => {
        // Handle error
        console.error("Error fetching client wards:", err);
      });
  }, [currentClient]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <>
        <FormGroup row>
          <Label sm={3}>{ClientLable} Name</Label>
          <Col sm={8}>
            <Input
              type="select"
              name="client"
              disabled
              className="pointer-disabled"
              value={currentClient}
              innerRef={register}
              onChange={(selected) => setCurrentClient(selected.target.value)}
            >
              {clients &&
                clients.map((client, key) => (
                  <option value={client.id} key={key}>
                    {client.name}
                  </option>
                ))}
            </Input>
          </Col>
        </FormGroup>

        <FormGroup row>
          <>
            <Label sm={3}>Department Name</Label>
            <Col sm={8}>
              <Input
                type="select"
                name="department"
                value={currentDepartment}
                innerRef={register}
                onChange={(e) => setCurrentDepartment(e.target.value)}
              >
                {departments &&
                  departments.map((department, key) => (
                    <option value={department.id} key={key}>
                      {department.wardName}
                    </option>
                  ))}
              </Input>
            </Col>
          </>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>Grade</Label>
          <Col sm={8}>
            <Input
              type="select"
              name="grade"
              defaultValue={currentGrade && currentGrade}
              innerRef={register}
              onChange={(selected) => setCurrentGrade(selected.target.value)}
            >
              {grades &&
                grades?.map((grade, key) => (
                  <option value={grade.id} key={key}>
                    {grade.name} - {grade.escalation && "Escalation"}
                  </option>
                ))}
            </Input>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label
            sm={3}
            hidden={
              grades?.some(
                (grade) => grade.id === Number(currentGrade) && grade.escalation
              )
                ? false
                : true
            }
          >
            Exp Date
          </Label>
          <Col sm={8}>
            <Input
              type="date"
              name="expDate"
              defaultValue={escalationExpDate}
              hidden={
                grades?.some(
                  (grade) =>
                    grade.id === Number(currentGrade) && grade.escalation
                )
                  ? false
                  : true
              }
              onChange={(e) => setEscalationExpDate(e.target.value)}
              innerRef={register({
                required: {
                  value: grades?.some(
                    (grade) =>
                      grade.id === Number(currentGrade) && grade.escalation
                  )
                    ? true
                    : false,
                  message: "This field is required!",
                },
              })}
            />
          </Col>
        </FormGroup>
      </>

      {defaultRatesNotAddedError ? (
        <div className="d-flex justify-content-center">
          <Alert color="danger">
            Please add "Default Ward" to this client before setting up the rates
          </Alert>
        </div>
      ) : (
        <div className="col-12 col-sm-12 ">
          <div>
            <div className="card-body">
              {rateTypes?.map((rateType, id) => {
                return (
                  <div className="d-flex flex-row" key={id}>
                    <div
                      className="d-flex justify-content-start align-items-center"
                      style={{ width: "300px" }}
                    >
                      {rateType.type}
                    </div>
                    <div
                      className="d-flex flex-column "
                      style={{ width: "900px" }}
                    >
                      {/* <div className="d-flex justify-content-center m-1">Week Days</div> */}
                      <div className="d-flex flex-row justify-content-center m-1">
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{ width: "150px" }}
                        >
                          Night
                        </div>
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{ width: "100px" }}
                        >
                          N.P.R
                          <FontAwesomeIcon
                            id="NPR"
                            className="ml-1"
                            icon={faExclamationCircle}
                          />
                        </div>

                        <UncontrolledTooltip target="NPR">
                          Night Pay Rate
                        </UncontrolledTooltip>
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{ width: "100px" }}
                        >
                          N.C.R
                          <FontAwesomeIcon
                            id="NCR"
                            className="ml-1"
                            icon={faExclamationCircle}
                          />
                        </div>
                        <UncontrolledTooltip target="NCR">
                          Night Charge Rate
                        </UncontrolledTooltip>

                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{ width: "150px" }}
                        >
                          Day
                        </div>
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{ width: "100px" }}
                        >
                          D.P.R
                          <FontAwesomeIcon
                            id="DPR"
                            className="ml-1"
                            icon={faExclamationCircle}
                          />
                        </div>
                        <UncontrolledTooltip target="DPR">
                          Day Pay Rate
                        </UncontrolledTooltip>

                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{ width: "100px" }}
                        >
                          D.C.R
                          <FontAwesomeIcon
                            id="DCR"
                            className="ml-1"
                            icon={faExclamationCircle}
                          />
                        </div>
                        <UncontrolledTooltip target="DCR">
                          Day Charge Rate
                        </UncontrolledTooltip>

                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{ width: "100px" }}
                        >
                          Bonus P
                        </div>
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{ width: "100px" }}
                        >
                          Bonus C
                        </div>
                      </div>
                      <div className="d-flex flex-row justify-content-center">
                        <div className="d-flex flex-column">
                          <div
                            className="d-flex justify-content-center align-items-center m-1"
                            style={{ width: "150px" }}
                          >
                            S-
                            <Input
                              className="mx-1"
                              type="time"
                              placeholder=""
                              name={rateType.key + "NStartTime"}
                              defaultValue={rateType.nStart}
                              innerRef={register({
                                required: {
                                  value: true,
                                  message: "This field is required!",
                                },
                              })}
                            />
                          </div>
                          <div
                            className="d-flex justify-content-center align-items-center m-1"
                            style={{ width: "150px" }}
                          >
                            E-
                            <Input
                              className="mx-1"
                              type="time"
                              placeholder=""
                              name={rateType.key + "NEndTime"}
                              defaultValue={rateType.nEnd}
                              innerRef={register({
                                required: {
                                  value: true,
                                  message: "This field is required!",
                                },
                              })}
                            />
                          </div>
                        </div>
                        <div
                          className="d-flex justify-content-center align-items-center m-1"
                          style={{ width: "100px" }}
                        >
                          <Input
                            type="text"
                            name={rateType.key + "NightRate"}
                            defaultValue={rateType.nRate}
                            onChange={(e) => {
                              // Allow only numbers with up to two decimal places
                              const newValue = e.target.value
                                .replace(/[^0-9.]/g, "") // Remove non-numeric and non-decimal point characters
                                .replace(/^(\d+\.?\d{0,2}).*$/, "$1"); // Restrict to two decimal places
                              e.target.value = newValue;
                              e.target.style.color =
                                parseFloat(newValue) > rateType.nChargeRate &&
                                rateType.nChargeRate !== undefined
                                  ? "red"
                                  : "inherit";
                            }}
                            innerRef={register({
                              required: {
                                value: true,
                                message: "This field is required!",
                              },
                            })}
                          />
                          <Input
                            type="hidden"
                            name={rateType.key + "NightRate" + "ID"}
                            defaultValue={rateType.nId}
                            innerRef={register({
                              required: {
                                value: false,
                                message: "",
                              },
                            })}
                          />
                        </div>
                        <div
                          className="d-flex justify-content-center align-items-center m-1"
                          style={{ width: "100px" }}
                        >
                          <Input
                            type="text"
                            name={rateType.key + "NightChargeRate"}
                            defaultValue={rateType.nChargeRate}
                            innerRef={register({
                              required: {
                                value: true,
                                message: "This field is required!",
                              },
                            })}
                          />
                          {/* <Input
                          type="hidden"
                          name={rateType.key + "NightChargeRate" + "ID"}
                          defaultValue={rateType.nId}
                          innerRef={register({
                            required: {
                              value: false,
                              message: "",
                            },
                          })}
                        /> */}
                        </div>
                        <div className="d-flex flex-column">
                          <div
                            className="d-flex justify-content-center align-items-center m-1"
                            style={{ width: "150px" }}
                          >
                            <Input
                              type="time"
                              placeholder=""
                              name={rateType.key + "DStartTime"}
                              defaultValue={rateType.dStart}
                              innerRef={register({
                                required: {
                                  value: true,
                                  message: "This field is required!",
                                },
                              })}
                            />
                          </div>
                          <div
                            className="d-flex justify-content-center align-items-center m-1"
                            style={{ width: "150px" }}
                          >
                            <Input
                              type="time"
                              placeholder=""
                              name={rateType.key + "DEndTime"}
                              defaultValue={rateType.dEnd}
                              innerRef={register({
                                required: {
                                  value: true,
                                  message: "This field is required!",
                                },
                              })}
                            />
                          </div>
                        </div>
                        <div
                          className="d-flex justify-content-center align-items-center m-1"
                          style={{ width: "100px" }}
                        >
                          <Input
                            type="text"
                            name={rateType.key + "DayRate"}
                            defaultValue={rateType.dRate}
                            onChange={(e) => {
                              // Allow only numbers with up to two decimal places
                              const newValue = e.target.value
                                .replace(/[^0-9.]/g, "") // Remove non-numeric and non-decimal point characters
                                .replace(/^(\d+\.?\d{0,2}).*$/, "$1"); // Restrict to two decimal places
                              e.target.value = newValue;
                              e.target.style.color =
                                parseFloat(newValue) > rateType.dChargeRate &&
                                rateType.dChargeRate !== undefined
                                  ? "red"
                                  : "inherit";
                            }}
                            innerRef={register({
                              required: {
                                value: true,
                                message: "This field is required!",
                              },
                            })}
                          />

                          <Input
                            type="hidden"
                            name={rateType.key + "DayRate" + "ID"}
                            defaultValue={rateType.dId}
                            innerRef={register({
                              required: {
                                value: false,
                                message: "",
                              },
                            })}
                          />
                        </div>
                        <div
                          className="d-flex justify-content-center align-items-center m-1"
                          style={{ width: "100px" }}
                        >
                          <Input
                            type="text"
                            name={rateType.key + "DayChargeRate"}
                            defaultValue={rateType.dChargeRate}
                            innerRef={register({
                              required: {
                                value: true,
                                message: "This field is required!",
                              },
                            })}
                          />
                          {/* <Input
                          type="hidden"
                          name={rateType.key + "DayChargeRate" + "ID"}
                          defaultValue={rateType.dId}
                          innerRef={register({
                            required: {
                              value: false,
                              message: "",
                            },
                          })}
                        /> */}
                        </div>
                        <div
                          className="d-flex justify-content-center align-items-center m-1"
                          style={{ width: "100px" }}
                        >
                          <Input
                            type="text"
                            name={rateType.key + "BonusRate"}
                            defaultValue={rateType.bRate}
                            innerRef={register({
                              required: {
                                value: true,
                                message: "This field is required!",
                              },
                            })}
                          />

                          <Input
                            type="hidden"
                            name={rateType.key + "BonusRateD" + "ID"}
                            defaultValue={rateType.sDId}
                            innerRef={register({
                              required: {
                                value: false,
                                message: "",
                              },
                            })}
                          />

                          <Input
                            type="hidden"
                            name={rateType.key + "BonusRateN" + "ID"}
                            defaultValue={rateType.sNId}
                            innerRef={register({
                              required: {
                                value: false,
                                message: "",
                              },
                            })}
                          />
                        </div>{" "}
                        <div
                          className="d-flex justify-content-center align-items-center m-1"
                          style={{ width: "100px" }}
                        >
                          <Input
                            type="text"
                            name={rateType.key + "BonusChargeRate"}
                            defaultValue={rateType.bChargeRate}
                            innerRef={register({
                              required: {
                                value: true,
                                message: "This field is required!",
                              },
                            })}
                          />

                          {/* <Input
                          type="hidden"
                          name={rateType.key + "BonusChargeRateD" + "ID"}
                          defaultValue={rateType.sDId}
                          innerRef={register({
                            required: {
                              value: false,
                              message: "",
                            },
                          })}
                        /> */}

                          {/* <Input
                          type="hidden"
                          name={rateType.key + "BonusChargeRateN" + "ID"}
                          defaultValue={rateType.chargeRate}
                          innerRef={register({
                            required: {
                              value: false,
                              message: "",
                            },
                          })}
                        /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}

              <div className="d-flex justify-content-end mt-3">
                <Button className="mr-2" color="success" type="submit">
                  {update ? "Update" : "Create"}
                </Button>
                <Button color="secondary" onClick={toggle}>
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </Form>
  );
}

function mapStateToProps(state) {
  return {
    reduxClients: state.initialDataGetReducer.clients,
    reduxDepartments: state.initialDataGetReducer.departments,
    reduxGrades: state.initialDataGetReducer.grades,
  };
}

export default connect(mapStateToProps, {})(ClientRateModal);
