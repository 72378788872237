import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { forEach } from "lodash";
import { useToasts } from "react-toast-notifications";
import { Button, Label, Input, Form, FormGroup, Row } from "reactstrap";
import { HOSTMexxar } from "../../../configs/api-config";
import {
  bandsGetAction,
  clientsGetAction,
  departmentsGetAction,
  rolesGetAction,
  specialitiesGetAction,
} from "../../../redux/actions/initialDataGetAction";
import store from "../../../redux/store";
import useDidMountEffect from "./intialRunUseEffect";
import ToastCommon from "../../toastCommon";
import DateInput from "../../../util/common/DateInput";
import dateFormatter from "../../../util/common/dateFormatter";

function AddShiftFormModal(props) {
  const { register, handleSubmit, watch, errors } = useForm();

  const {
    onCreate,
    closeModal,
    candidateId,
    reset,
    currentResetState,
    dayData,
    candidateWhenClick,
    auth,
    incommingPage,
    user,
    reduxSpecialities,
    reduxClients,
    reduxDepartments,
    reduxRoles,
    reduxBands,
  } = props;

  const MexxarApi = HOSTMexxar;

  const { addToast } = useToasts();

  //drop down lists
  const [roles, setRoles] = useState(null);
  const [clients, setClients] = useState(null);
  const [bands, setBands] = useState(null);

  const [specialities, setSpecialities] = useState(null);

  const [shifts, setShifts] = useState(null);

  const [selectedClient, setSelectedClient] = useState(0);
  const [selectedWard, setSelectedWard] = useState(0);
  const [dataErrorStatus, setDataErrorStatus] = useState(false);

  const [matchingLikeWardsWithClient, setMatchingLikeWardsWithClient] =
    useState(null);
  const [contactPersons, setContactPersons] = useState([{}]);

  const removeMTAddMore = () => {
    contactPersons.pop(contactPersons);
    setContactPersons([...contactPersons]);
  };

  let filtered = contactPersons.filter(
    (item) => Object.keys(item).length === 0
  );

  const onSubmit = (data, id) => {
    const formArray = [];

    // Loop through contact persons and collect form data
    // if (id != null) {
    contactPersons.forEach((data, id) => {
      const startTimeValue = watch(`startTime${id}`);
      const endTimeValue = watch(`endTime${id}`);
      const clientIdValue = watch(`clientId${id}`);
      const dateValue = watch(`date${id}`);

      // Add ":00" to startTime and endTime
      const startTime = startTimeValue + ":00";
      const endTime = endTimeValue + ":00";
      const date = new Date(dateFormatter.formatDateToYYYYMMDD(dateValue)).toISOString();
      const clientId = parseInt(clientIdValue);
      const employeeId = user.id;
      const sex = "";

      // submitData.employeeId = user.id;
      //         submitData.sex = "";

      const formValues = {
        refNo: watch(`refNo${id}`),
        // date: watch(`date${id}`),
        date,
        startTime,
        endTime,
        // clientId: watch(`clientId${id}`),
        clientId,
        employeeId,
        sex,
        ward: watch(`ward${id}`),
        note: watch(`note${id}`),
        assignment: watch(`assignment${id}`),
        secondaryAssignment: watch(`secondaryAssignment${id}`),
        training: watch(`training${id}`),
      };

      formArray.push(formValues);
    });
    // } else {
    //     console.error('id is null or undefined');
    //     // Handle this case as needed
    // }

    // Loop through the form data and make Axios calls
    for (let i = 0; i < formArray.length; i++) {
      try {
        axios({
          method: "POST",
          url: HOSTMexxar + "otherShift/dto",
          data: formArray[i],
        })
          .then((res) => {
            if (res.data.status === "success") {
              ToastCommon(true, "success", addToast);
              closeModal();
            }
          })
          .catch((error) => {
            ToastCommon(true, "error", addToast);
          });
      } catch (error) {
        // Handle errors
        console.error("Error making Axios call:", error);
        ToastCommon(true, "error", addToast);
      }
    }
  };

  useEffect(() => {
    setSpecialities(reduxSpecialities);
    setClients(reduxClients);
    setRoles(reduxRoles);
    setBands(reduxBands);
  }, [
    reduxSpecialities,
    reduxClients,
    reduxDepartments,
    reduxRoles,
    reduxBands,
  ]);

  useEffect(() => {
    store.dispatch(specialitiesGetAction());
    store.dispatch(clientsGetAction());
    store.dispatch(departmentsGetAction());
    store.dispatch(rolesGetAction());
    store.dispatch(bandsGetAction());
  }, []);

  const getwardsInSelectedClient = () => {
    axios
      .get(MexxarApi + "client-wards/clients/" + selectedClient?.id)
      .then((res) => {
        let wardsList = [];
        forEach(res.data.body, function (ward) {
          wardsList.push({
            id: ward.wardId,
            name: ward.wardName,
            label: ward.wardName,
          });
        });

        setSelectedWard(wardsList[0]);
        setMatchingLikeWardsWithClient(wardsList);
      });
  };

  useDidMountEffect(() => {
    getwardsInSelectedClient();
    return () => {};
  }, [selectedClient]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {contactPersons.map((data, id) => {
        return (
          <Row form key={id} onSubmit={handleSubmit(onSubmit)}>
            <div className="form-table">
              <div className="form-row">
                <div className="form-cell" style={{ width: "100px" }}>
                  <FormGroup>
                    <Label>Ref No</Label>
                    <Input
                      type="text"
                      invalid={errors.refNo}
                      placeholder="Ref No"
                      // name="refNo + id"
                      name={"refNo" + id}
                      defaultValue={data && data.refNo}
                      innerRef={register({ required: true })}
                    />
                    {errors.refNo && (
                      <ul
                        className="list-unstyled text-sm mt-1 text-muted filled"
                        id="parsley-id-119"
                      >
                        <li className="parsley-required text-danger">
                          This field is required
                        </li>
                      </ul>
                    )}
                  </FormGroup>
                </div>
                <div className="form-cell" style={{ width: "100px" }}>
                  <FormGroup>
                    <Label>Date</Label>
                    <DateInput
                      id={"date" + id}
                      register={register}
                      value={
                        data && data.date != null
                          ? dateFormatter.formatDateToDDMMYYYY(
                              data && data.date
                            )
                          : ""
                      }
                      onChange={(e) => {
                        setDataErrorStatus(
                          moment(dateFormatter.formatDateToYYYYMMDD(e)).format(
                            "l"
                          ) == moment().format("l")
                            ? false
                            : moment(
                                dateFormatter.formatDateToYYYYMMDD(e)
                              ).isBefore()
                        );
                      }}
                    ></DateInput>

                    {dataErrorStatus && (
                      <ul
                        className="list-unstyled text-sm mt-1 text-muted filled"
                        id="parsley-id-119"
                      >
                        <li className="parsley-required text-danger">
                          Invalid Date
                        </li>
                      </ul>
                    )}
                  </FormGroup>
                </div>
                <div className="form-cell">
                  <FormGroup>
                    <Label>Start Time</Label>
                    <Input
                      type="time"
                      placeholder="Start Time"
                      // name="startTime"
                      name={"startTime" + id}
                      defaultValue={data && data.startTime}
                      invalid={errors.startTime}
                      innerRef={register({ required: true })}
                    />
                    {errors.startTime && (
                      <ul
                        className="list-unstyled text-sm mt-1 text-muted filled"
                        id="parsley-id-119"
                      >
                        <li className="parsley-required text-danger">
                          This field is required
                        </li>
                      </ul>
                    )}
                  </FormGroup>
                </div>

                <div className="form-cell">
                  <FormGroup>
                    <Label>End Time</Label>
                    <Input
                      type="time"
                      invalid={errors.endTime}
                      placeholder="End Time"
                      // name="endTime"
                      name={"endTime" + id}
                      defaultValue={data && data.endTime}
                      innerRef={register({ required: true })}
                    />
                    {errors.endTime && (
                      <ul
                        className="list-unstyled text-sm mt-1 text-muted filled"
                        id="parsley-id-119"
                      >
                        <li className="parsley-required text-danger">
                          This field is required
                        </li>
                      </ul>
                    )}
                  </FormGroup>
                </div>

                <div className="form-cell" style={{ width: "100px" }}>
                  <FormGroup>
                    <Label>Client</Label>
                    {/* <Col sm={8}> */}
                    <Input
                      type="select"
                      id="clientId"
                      // name="clientId"
                      // defaultValue={0}
                      name={"clientId" + id}
                      defaultValue={data && data.clientId}
                      onChange={(e) => {
                        const selectedOption = clients.find(
                          (item) => item.id === parseInt(e.target.value)
                        );
                        setSelectedClient(selectedOption);
                      }}
                      invalid={errors.clientId}
                      innerRef={register({
                        required: {
                          value: true,
                          message: "This field is required!",
                        },
                      })}
                    >
                      <option value={0} id={0}>
                        Select Client
                      </option>
                      {clients?.map((item, id) => {
                        return (
                          <option value={item.id} key={id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </Input>
                    {/* </Col> */}
                  </FormGroup>
                </div>

                <div className="form-cell" style={{ width: "100px" }}>
                  <FormGroup>
                    <Label>Department</Label>
                    {/* <Col sm={8}> */}
                    <Input
                      type="select"
                      id="departmentId"
                      // name="ward"
                      invalid={errors.ward}
                      // defaultValue={0}
                      name={"ward" + id}
                      defaultValue={data && data.ward}
                      onChange={(e) => {
                        const selectedOption = matchingLikeWardsWithClient.find(
                          (item) => item.id === parseInt(e.target.value)
                        );
                        setSelectedWard(selectedOption);
                      }}
                      innerRef={register({
                        required: {
                          value: true,
                          message: "This field is required!",
                        },
                      })}
                    >
                      <option value={0}>Select Department</option>

                      {matchingLikeWardsWithClient &&
                        matchingLikeWardsWithClient.map((item, id) => {
                          return (
                            <option value={item.name} key={id}>
                              {item.name}
                            </option>
                          );
                        })}
                    </Input>
                    {errors.ward && (
                      <ul
                        className="list-unstyled text-sm mt-1 text-muted filled"
                        id="parsley-id-119"
                      >
                        <li className="parsley-required text-danger">
                          This field is required
                        </li>
                      </ul>
                    )}
                    {/* </Col> */}
                  </FormGroup>
                </div>

                <div className="form-cell" style={{ width: "100px" }}>
                  <FormGroup>
                    <Label>Note</Label>
                    {/* <Col sm={8}> */}
                    <Input
                      type="text"
                      placeholder="Note"
                      // name="note"
                      name={"note" + id}
                      defaultValue={data && data.note}
                      innerRef={register}
                    />
                    {/* </Col> */}
                  </FormGroup>
                </div>

                <div className="form-cell" style={{ width: "100px" }}>
                  <FormGroup>
                    <Label>Assignment</Label>
                    {/* <Col sm={8}> */}
                    <Input
                      type="text"
                      placeholder="Assignment"
                      // name="assignment"
                      name={"assignment" + id}
                      defaultValue={data && data.assignment}
                      innerRef={register}
                    />
                    {/* </Col> */}
                  </FormGroup>
                </div>

                <div className="form-cell" style={{ width: "150px" }}>
                  <FormGroup>
                    <Label>Secondary Assignment</Label>
                    {/* <Col sm={8}> */}
                    <Input
                      type="text"
                      placeholder="Secondary Assignment"
                      // name="secondaryAssignment"
                      name={"secondaryAssignment" + id}
                      defaultValue={data && data.secondaryAssignment}
                      innerRef={register}
                    />
                    {/* </Col> */}
                  </FormGroup>
                </div>

                <div className="form-cell" style={{ width: "100px" }}>
                  <FormGroup>
                    <Label>Training</Label>
                    {/* <Col sm={8}> */}
                    <Input
                      type="text"
                      placeholder="Training"
                      // name="training"
                      name={"training" + id}
                      defaultValue={data && data.training}
                      innerRef={register}
                    />
                    {/* </Col> */}
                  </FormGroup>
                </div>
                {/* <!-- Add more fields here in the same way --> */}
              </div>
              {/* <hr /> */}
            </div>
          </Row>
        );
      })}

      <div className="d-flex justify-content-end">
        <div className="m-2">
          <Button
            color="success font-weight-bold mb-2"
            style={{ borderRadius: "50px" }}
            onClick={() => setContactPersons([...contactPersons, {}])}
          >
            +
          </Button>
          {filtered.length > 1 ? (
            <Button
              color="danger font-weight-bold mb-2 mx-2"
              style={{
                borderRadius: "80px",
                width: "40px",
                height: "40px",
              }}
              onClick={() => removeMTAddMore()}
            >
              -
            </Button>
          ) : null}
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <Button className="mr-2" color="success" type="submit">
          Create
        </Button>
        <Button color="secondary" onClick={() => closeModal()}>
          Cancel
        </Button>
      </div>
    </Form>
  );
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    user: state.auth.item,
    reduxSpecialities: state.initialDataGetReducer.specialities,
    reduxClients: state.initialDataGetReducer.clients,
    reduxDepartments: state.initialDataGetReducer.departments,
    reduxRoles: state.initialDataGetReducer.roles,
    reduxBands: state.initialDataGetReducer.bands,
  };
}

export default connect(mapStateToProps, {})(AddShiftFormModal);
