import React, { useState, useEffect } from "react";
import {
  faChevronCircleDown,
  faChevronCircleUp,
  faArrowAltCircleDown,
  faArrowAltCircleUp,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Label, UncontrolledTooltip, Input, Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DashboardLayout from "../../layouts/dashboard-layout/dashboard";
import TabNavigationMenu from "../../util/common/tabNavigation";
import BookingWorkspace from "./workspace/booking-workspace";
import CallBackPage from "../headhunter/callBacks/callBackPage";
import BookingShifts from "./workspace/shift";
import ExternalShifts from "./workspace/externalShifts";
import BookingShiftsCancellation from "./workspace/cancelBooking";
import ModalComponent from "../../components/Modal/modalComponent";
import AddShiftForm from "../../components/Modal/ModalBody/AddShiftForm";
import { changeDashboard } from "../../redux/actions/appSettingsAction";
import { Add_NEW_EMPLOYEE, HOSTMexxar } from "../../configs/api-config";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import useDidMountEffect from "../../components/Modal/ModalBody/intialRunUseEffect";
// import { useLocation, useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Bookings = (props) => {
  const { settings, changeDashboard, auth } = props;
  const MexxarApiEmployee = Add_NEW_EMPLOYEE;

  const [employee, setEmployee] = useState([]);
  const [showEmployee, setShowEmployee] = useState(false);
  const [currentTab, setCurrentTab] = useState("bookingsWorkspace");
  const [employeeID, setEmployeeID] = useState(auth.item.userTypeId);
  const [reload, setReload] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const history = useHistory();
  const location = useLocation();
  const tab = window.location.hash.substring(1);
  useEffect(() => {
    if (tab === "") {
      setCurrentTab("bookingsWorkspace");
    } else {
      setCurrentTab(tab);
    }
  }, [tab]);
  const closeModalHandler = () => {
    setShowAddShiftModal(false);
    if (currentTab === "externalShifts") {
      // Update the refresh key to force re-render
      setRefreshKey((prevKey) => prevKey + 1);
    }
  };
  const switchTabHandler = (tab) => {
    setCurrentTab(tab);
    // const url = `#${tab}`;
    // if (window.location.hash !== url) {
    //   history.push(url);
    // }
  };

  const getEmployees = () => {
    axios
      .get(MexxarApiEmployee)
      .then((res) => {
        let reponse = res.data.body;
        let names = [];
        reponse.forEach((item) => {
          names.push({
            id: item.id,
            firstName: item.firstName,
            lastName: item.lastName,
          });
        });
        setEmployee(names);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const [showAddShiftModal, setShowAddShiftModal] = useState(false);
  const [rememberEmployee, setRememberEmployee] = useState(false);

  useDidMountEffect(() => {
    localStorage.setItem("WorkspaceEmployeeSave", rememberEmployee);
    localStorage.setItem("WorkspaceEmployeeId", employeeID);
  }, [rememberEmployee]);

 
  useEffect(() => {
    const workspaceEmployeeSave = localStorage.getItem("WorkspaceEmployeeSave");
    const employeeId = localStorage.getItem("WorkspaceEmployeeId");

    getEmployees(workspaceEmployeeSave, employeeId);

    if (workspaceEmployeeSave == "true") {
      setRememberEmployee(true);
      setShowEmployee(true);
      // setEmployeeName()
      setEmployeeID(Number(employeeId));
    } else {
      localStorage.setItem("WorkspaceEmployeeSave", false);
      localStorage.setItem("WorkspaceEmployeeId", auth.item.userTypeId);
      setEmployeeID(auth.item.userTypeId);
    }
  }, []);

  return (
    <DashboardLayout
      title="Booking Workspace"
      subTitle="Achieve Your Daily Targets"
      RegisterNewButtonShow={true}
    >
      <div className="d-flex flex-row-reverse">
        <div>
          {settings.isDashboardOpen ? (
            <FontAwesomeIcon
              icon={faChevronCircleUp}
              style={{ boxShadow: "none", cursor: "pointer" }}
              id="dashboardHideUp"
              className="fa-md btn-raised btn-wave"
              onClick={() => changeDashboard(false)}
            />
          ) : (
            <FontAwesomeIcon
              id="dashboardHideUp"
              icon={faChevronCircleDown}
              style={{ boxShadow: "none", cursor: "pointer" }}
              className="fa-md btn-raised btn-wave"
              onClick={() => changeDashboard(true)}
            />
          )}
          <UncontrolledTooltip placement="right" target="dashboardHideUp">
            Click to show/hide dashboard
          </UncontrolledTooltip>
        </div>
        <div className="d-flex align-items-end flex-column mx-2">
          {showEmployee ? (
            <FontAwesomeIcon
              icon={faArrowAltCircleUp}
              className="btn-raised"
              id="arrowDown"
              style={{ boxShadow: "none", cursor: "pointer" }}
              onClick={() => setShowEmployee(false)}
            />
          ) : (
            <FontAwesomeIcon
              icon={faArrowAltCircleDown}
              id="arrowDown"
              className="btn-raised"
              style={{ boxShadow: "none", cursor: "pointer" }}
              onClick={() => setShowEmployee(true)}
            />
          )}
          <UncontrolledTooltip placement="left" target="arrowDown">
            Click to show/hide consultants
          </UncontrolledTooltip>
          {showEmployee ? (
            <div>
              <Label className={"mr-3"}>Get Candidates by Employee</Label>
              <Input
                type="select"
                id="assigneeId"
                style={{ width: "200px" }}
                name="assigneeId"
                value={employeeID}
                onChange={(e) => setEmployeeID(e.target.value)}
              >
                {/* <option value={0}>Select</option> */}

                {employee &&
                  employee.map((item, id) => {
                    return (
                      <option key={id} value={item.id}>
                        {item.firstName} {item.lastName}
                      </option>
                    );
                  })}
              </Input>
              <div>
                <small>
                  Remember
                  <Input
                    className="mx-2 pointer"
                    type="checkbox"
                    value="REMEMBEREMPLOYEE"
                    checked={rememberEmployee}
                    onChange={() => setRememberEmployee(!rememberEmployee)}
                  ></Input>
                </small>
              </div>
            </div>
          ) : null}
        </div>
       
        </div>
      <Button onClick={() => setShowAddShiftModal(true)}>
        Add External Shift
      </Button>
      <TabNavigationMenu
        activated={currentTab}
        onChangeItem={switchTabHandler}
        itemList={[
          { name: "Workspace", key: "bookingsWorkspace" },
          { name: "Rescheduled", key: "reschedule" },
          { name: "Confirmed Bookings", key: "shifts" },
          { name: "Unconfirmed Bookings", key: "unconfirmedBookings" },
          { name: "Other Shifts", key: "externalShifts" },
          { name: "Cancelled Bookings", key: "cancellationBookings" },
        ]}
      />
      {currentTab === "bookingsWorkspace" && (
        <BookingWorkspace employeeID={employeeID} />
      )}
      {currentTab === "reschedule" && (
        <CallBackPage
          updateTargets={() => {}}
          employeeID={employeeID}
          page="MAINTENANCE"
        />
      )}
      {currentTab === "shifts" && (
        <BookingShifts
          incomingPage="bookingWorkspace"
          employeeID={employeeID}
        />
      )}
      {currentTab === "unconfirmedBookings" && (
        <BookingShifts
          incomingPage="unconfirmedBookings"
          employeeID={employeeID}
        />
      )}
      {currentTab === "externalShifts" && (
        <ExternalShifts
          incomingPage="externalShifts"
          employeeID={employeeID}
          key={refreshKey}
        />
      )}
      {currentTab === "cancellationBookings" && (
          <BookingShiftsCancellation
            incomingPage="cancellationBookings"
            employeeID={employeeID}
          />
        )}
{/*      
      <ModalComponent
        show={showAddShiftModal}
        header="Add External Shift"
        closeModal={() => setShowAddShiftModal(false)}
        style={{ width: "50px" }}
        className="modal-dialog modal-xl"
      >
        <AddShiftForm closeModal={() => setShowAddShiftModal(false)} />
      </ModalComponent> */}
            <ModalComponent
        show={showAddShiftModal}
        header="Add External Shift"
        closeModal={() =>{ closeModalHandler()}}
        style={{ width: "50px" }}
        className="modal-dialog modal-xl"
      >
        <AddShiftForm closeModal={() => {closeModalHandler()}} />
      </ModalComponent>
    
    </DashboardLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    settings: state.appSettings,
  };
};

export default connect(mapStateToProps, { changeDashboard })(Bookings);
