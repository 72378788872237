import React, { useCallback, useEffect, useRef, useState } from "react";
import "./feed-card-widget.style.css";
import {
  faEnvelope,
  faFileAlt,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { CALL_LOGS } from "../../configs/api-config";
import { useToasts } from "react-toast-notifications";
import useFetch from "../../../src/customHooks/useFetch";
import loadingAnimation from "../../../src/images/puff.svg";
import NoConnection from "../../pages/errorPages/NoConnection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NoSearchResults from "../../pages/errorPages/NoSearchResults";
import useDidMountEffect from "../../components/Modal/ModalBody/intialRunUseEffect";

import {
  Collapse,
  Card,
  CardBody,
  Badge,
  Label,
  Input,
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  FormGroup,
} from "reactstrap";
import axios from "axios";
import AddNoteModal from "../../components/AddANote/AddANote";
import ToastCommon from "../../components/toastCommon";
import { FunnelIcon } from "../../assets/icons/svg";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../configs/authConfig";
import dateFormatter from "../../util/common/dateFormatter";
import DateInput from "../../util/common/DateInput";

function FeedCard(props) {
  const { row, toggleStar } = props;

  const feedHeading = (data) => {
    let heading;
    switch (data) {
      case "OUTGOING_ANSWERED":
        heading = "Answered";
        break;
      case "OUTGOING_MISSED":
        heading = "Not Answered";
        break;
      case "VOICEMAIL":
        heading = "Left Voicemail & Whatsapp Sent";
        break;
      case "RESCHEDULED":
        heading = "Rescheduled";
        break;
      case "INCOMING_ANSWERED":
        heading = "Incomming Answered";
        break;
      case "INCOMING_MISSED":
        heading = "Incomming Missed";
        break;
      case "DO_NOT_CALL":
        heading = "Do not call";
        break;
      case "DORMANT":
        heading = "Dormant";
        break;
      case "GENERAL":
        heading = "Public Note";
        break;
    }

    return { heading: heading };
  };
  const isToday = (date) => moment(date).isSame(moment(), "day");
  const displayDateTime = isToday(row.endTime)
    ? row.receivedDateTime
    : row.endTime;

  return (
    <div className="card mb-2">
      <div
        className="p-3"
        style={{
          backgroundColor: row?.receivedDateTime ? "#2e3061" : null,
        }}
      >
        <div className=" list-item ">
          <div className="timeline timeline-theme animates animates-fadeInUp">
            <div className="tl-item active">
              <div className="tl-dot"></div>
              <div className="tl-content">
                {/* ////....if alert is required use this otherwise use badge   */}
                {/* {row.candidateStatus=="DORMANT" ? <div> <Alert color="warning"><FontAwesomeIcon className="mr-1" icon={faTimesCircle}/>This candidate is dormant</Alert></div> :"" }
                    {row.candidateStatus=="DO_NOT_CALL" ? <div><Alert color="danger"><FontAwesomeIcon className="mr-1" icon={faExclamationCircle}/>Candidate is requested not to contact</Alert></div> :"" }
                                */}

                {row.candidateStatus == "DORMANT" ? (
                  <Badge className={"bg-warning"}>
                    {feedHeading(row.candidateStatus).heading}
                  </Badge>
                ) : (
                  ""
                )}
                {row.candidateStatus == "DO_NOT_CALL" ? (
                  <Badge className={"bg-danger"}>
                    {feedHeading(row.candidateStatus).heading}
                  </Badge>
                ) : (
                  ""
                )}

                <div>
                  <div className=" ">{feedHeading(row.status).heading}</div>
                  <div className=" ">{row.subject}</div>
                  <div className="tl-content text-justify">{row.reason}</div>
                </div>
                <div
                  className="tl-date text-muted mt-1"
                  style={{ maxWidth: "300px" }}
                >
                  {moment(displayDateTime).format("ddd DD MMM YYYY")}
                  {" @ "}
                  {moment(displayDateTime).format("hh:mm a")} {"by"}{" "}
                  {row.employeeName}
                  {row?.from?.emailAddress?.name}
                </div>
              </div>

              {row?.endTime && (
                <div>
                  <FontAwesomeIcon
                    icon={faStar}
                    className={`star shadow-none btn-raised pointer ${
                      row.important ? "text-warning" : ""
                    }`}
                    onClick={() => toggleStar(row)}
                  />
                </div>
              )}

              {row?.receivedDateTime && (
                <div>
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    className={`star shadow-none btn-raised   "text-warning" : ""}`}
                    // onClick={() => toggleStar(row)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const FeedCardWidget = (props) => {
  const { userProfile, candidateId, reload, incommingPage, candidateData } =
    props;

  const currentDate = new Date();

  const threeMonthsBack = moment(
    moment(currentDate).subtract(90, "days")
  ).format("YYYY-MM-DD");
  const tommorow = moment(moment(currentDate).add(1, "days")).format(
    "YYYY-MM-DD"
  );

  const { addToast } = useToasts();
  const [pageNumber, setPageNumber] = useState(1);
  const [searchAdvanced, setsearchAdvanced] = useState(false);
  const [resetResults, setResetResults] = useState(false);
  const [getStartDate, setGetStartDate] = useState(dateFormatter.formatDateToDDMMYYYY(threeMonthsBack));
  const [getEndDate, setGetEndDate] = useState(dateFormatter.formatDateToDDMMYYYY(tommorow));

  const [totalPages, setTotalPages] = useState(0);

  let cancelTokenSource = axios.CancelToken.source();
  const [pageEnd, setPageEnd] = useState(false);
  const [importantStatus, setImportantStatus] = useState(null);
  const [emailItems, setEmailItems] = useState([]);
  const [searchStatus, setSearchStatus] = useState(false);

  let url = CALL_LOGS + "/filter?candidateId=" + candidateId;

  const { loading, error, rows, hasMore, totalElements } = useFetch(
    url,
    pageNumber,
    searchAdvanced,
    resetResults,
    reload,
    dateFormatter.formatDateToYYYYMMDDSlash(getStartDate),
    dateFormatter.formatDateToYYYYMMDDSlash(getEndDate),
    importantStatus
  );

  useDidMountEffect(() => {
    {
      reload && reset();
    }
  }, [reload]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);
  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const { instance, accounts } = useMsal();

  useEffect(() => {
    if (accounts.length > 0) {
      instance.setActiveAccount(accounts[0]);
    }
  }, [accounts, instance]);

  const toggleFilter = () => setIsOpenFilter(!isOpenFilter);

  useDidMountEffect(() => {
    setPageNumber(1);
  }, [searchAdvanced]);

  const reset = () => {
    setResetResults(!resetResults);
    setImportantStatus("");
    setGetStartDate(dateFormatter.formatDateToDDMMYYYY(threeMonthsBack));
    setGetEndDate(dateFormatter.formatDateToDDMMYYYY(tommorow));
    setPageNumber(1);
    setSearchStatus(false);
  };

  function getEmailList(pageNumber) {
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        const startDate = dateFormatter.formatDateToYYYYMMDD(getStartDate)+"T00:00:00.000Z";
        const endDate = dateFormatter.formatDateToYYYYMMDD(getEndDate)+"T00:00:00.000Z";
        const candidateEmail = candidateData?.email;
        const url = `https://graph.microsoft.com/v1.0/me/messages?$filter=receivedDateTime ge ${startDate} and receivedDateTime le ${endDate} and from/emailAddress/address eq '${candidateEmail}'`;
        const headers = {
          Authorization: "Bearer " + response.accessToken,
        };

        axios
          .get(url, {
            headers,
            cancelToken: cancelTokenSource.token,
          })
          .then((response) => {
            if (response) {
              console.log("res", response?.data?.value);
              setTotalPages(response?.data?.body?.totalPages);
              setEmailItems(response?.data?.value);
            }
            const nextLink = response.data["@odata.nextLink"];
            if (nextLink) {
              setPageEnd(false);
            } else {
              setPageEnd(true);
            }
          })
          .catch((error) => {
            if (axios.isCancel(error)) {
              console.log("Request canceled:", error.message);
            } else {
              console.error(error);
            }
          });
      })
      .catch((error) => {
        console.log("Token error:", error);
      });
  }


  const combinedArray = rows.concat(emailItems);

  console.log(combinedArray);

  useEffect(() => {
    getEmailList(1);
    return () => {};
  }, [searchAdvanced, resetResults]);

  const handleSaveNote = (note) => {
    toggleModal();

    axios
      .post(CALL_LOGS, {
        candidateId: Number(candidateId),
        reason: note.note,
        startTime: new Date().toISOString(),
        endTime: new Date().toISOString(),
        status: 6,
        candidateStatus: 0,
        incomingPhoneNo: 0,
        outgoingPhoneNo: 0,
        important: note.isImportant,
      })
      .then((res) => {
        if (res.data.status == "success") {
          reset();
          ToastCommon(true, "success", addToast);
        } else {
          ToastCommon(true, "error", addToast);
        }
      })
      .catch((err) => {
        ToastCommon(true, "error", addToast);
      });
  };

  const toggleStar = (row) => {
    const updatedObject = { ...row, important: !row.important };

    axios
      .put(CALL_LOGS, updatedObject)
      .then((res) => {
        if (res.data.status == "success") {
          reset();
          ToastCommon(true, "success", addToast);
        } else {
          ToastCommon(true, "error", addToast);
        }
      })
      .catch((err) => {
        ToastCommon(true, "error", addToast);
      });
  };

  const getDate = (item) => {
    const date = item.endTime
      ? new Date(item.endTime)
      : item.receivedDateTime
      ? new Date(item.receivedDateTime)
      : null;
    // console.log("Date for item:", item, "Date:", date);
    return date;
  };
  {
    searchStatus && combinedArray.sort((a, b) => getDate(a) - getDate(b));
  }
  {
    !searchStatus && combinedArray.sort((a, b) => getDate(b) - getDate(a));
  }

  const filteredArray = combinedArray.filter((item) => {
    if (importantStatus === "true") {
      return item.important === true;
    }
    return true;
  });

  filteredArray.sort((a, b) => {
    const dateA = getDate(a);
    const dateB = getDate(b);
    return searchStatus ? dateA - dateB : dateB - dateA;
  });

  const groupedItems = filteredArray.reduce((acc, item) => {
    const dateObj = getDate(item);
    if (dateObj) {
      const date = dateObj.toISOString().split("T")[0];
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(item);
    }
    return acc;
  }, {});

  Object.keys(groupedItems).forEach((date) => {
    console.log(`Items for ${date}:`);
    groupedItems[date].forEach((item) => {
      // console.log(item);
    });
  });

  return (
    <div>
      {incommingPage === "PROFILE" && (
        <div className="d-flex align-items-center">
          <FontAwesomeIcon icon={faFileAlt} className={"mx-3 mb-2"} />
          <Label>
            {combinedArray?.length} &nbsp;
            {combinedArray?.length === 1 ? "Call Log" : "Call Logs"}
          </Label>
          <div className="form-inline mx-2 mb-2 ">
            <Button
              onClick={toggleFilter}
              className="btn bg-dark-lt text-dark "
              id="advancedFilter"
            >
              <FunnelIcon />
              <span className="mx-1">Filter</span>
            </Button>

            <Button className="mx-2 " color="success" onClick={toggleModal}>
              Add A Note
            </Button>
          </div>
          <UncontrolledTooltip target="advancedFilter">
            Search call feeds by date
          </UncontrolledTooltip>

          <br></br>
        </div>
      )}

      <Collapse isOpen={isOpenFilter}>
        <Card>
          <CardBody>
            <Row>
              <Col>
                <Label for="exampleDate">Start Date</Label>
                <DateInput
                  id="startDate"
                  invalid={false}
                  register={() => {}}
                  value={getStartDate}
                  onChange={(e)=>{setGetStartDate(e)}}
                />
              </Col>
              <Col>
                <Label for="exampleDates">End Date</Label>
                <DateInput
                  id="endDate"
                  invalid={false}
                  register={() => {}}
                  value={getEndDate}
                  onChange={(e)=>{setGetEndDate(e)}}
                />
              </Col>
              <Col>
                <Label>Note Status</Label>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value="true"
                      checked={importantStatus === "true"}
                      onChange={(e) => setImportantStatus(e.target.value)}
                      style={{ cursor: "pointer" }}
                    />
                    <Badge color="warning">Important</Badge>
                  </Label>
                </FormGroup>
              </Col>
              <Col>
                <div className="d-flex flex-column align-items-end">
                  <Button
                    color="success m-1 btn-sm"
                    style={{ width: "70px" }}
                    onClick={() => (
                      setsearchAdvanced(!searchAdvanced), setSearchStatus(true)
                    )}
                  >
                    Search
                  </Button>
                  <Button
                    onClick={() => reset()}
                    color="danger m-1 btn-sm"
                    style={{ width: "70px" }}
                  >
                    Reset
                  </Button>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Collapse>
      <small className="ml-3 mb-3">
        Notes are showing from{" "}
        {getStartDate} To{" "}
        {getEndDate !== tommorow
          ? getEndDate
          : moment(currentDate).format("DD/MM/YYYY")}
      </small>
      {error && (
        <div>
          <NoConnection error={error}></NoConnection>
        </div>
      )}

      <div style={{ overflowY: "auto", maxHeight: "90vh" }}>
        {Object.keys(groupedItems).length === 0 && !loading && !error && (
          <NoSearchResults />
        )}

        {Object.keys(groupedItems).map((date) => (
          <div key={date}>
            <h6>{date}</h6>
            {groupedItems[date].map((row, index) => {
              if (groupedItems[date].length === index + 1) {
                return (
                  <div ref={lastBookElementRef} key={row.id}>
                    <FeedCard row={row} toggleStar={toggleStar} />
                  </div>
                );
              } else {
                return (
                  <div key={row.id}>
                    <FeedCard row={row} toggleStar={toggleStar} />
                  </div>
                );
              }
            })}
          </div>
        ))}
      </div>

      {loading && (
        <div className="d-flex justify-content-center align-items-center mt-5">
          <div>
            <img src={loadingAnimation} style={{ height: 100 }} alt="loading" />
          </div>
        </div>
      )}

      <AddNoteModal
        isOpen={isModalOpen}
        toggle={toggleModal}
        onSave={handleSaveNote}
      />
    </div>
  );
};

export default FeedCardWidget;
