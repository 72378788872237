import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  UncontrolledTooltip,
  Alert,
} from "reactstrap";
import moment from "moment";
import noInternet from "../../../src/images/internet.svg";
import {
  changeSideNavigation,
  changeDashboard,
} from "../../redux/actions/appSettingsAction";
import { changeTheme } from "../../redux/actions/changeTheme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faExpandAlt,
  faToggleOn,
  faToggleOff,
  faChevronLeft,
  faPhoneSlash,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { HOSTMexxar, HostMexxarURL } from "../../configs/api-config";
import sideNavigation from "../side-navigation/side-navigation";
import "./header.style.css";
import NotificationCard from "./notificationCard";
import useDidMountEffect from "../../components/Modal/ModalBody/intialRunUseEffect";
import ToastCommon from "../../components/toastCommon";
import { useToasts } from "react-toast-notifications";
import { last, forEach, filter, isNull } from "lodash";
import { ToastNotifications } from "../../components/toastCommon2";
import { useHistory } from "react-router-dom";
import { useNetwork } from "../../customHooks/useNetwork";
import notNull from "../../util/common/notNull";
import { signOutAction } from "../../redux/actions/authAction";

const Header = (props) => {
  const {
    settings,
    changeSideNavigation,
    changeDashboard,
    auth,
    changeTheme,
    chatPostLoad,
    newChatReceived,
    newTodoReceived,
    newUnconfirmedBookingReceived,
    newRescheduleReceived,
    newEventReceived,
    newTrainingRecived,
    newClientCreationReceived,
  } = props;

  const MexxarAPI = HOSTMexxar;
  const MexxarUrl = HostMexxarURL;

  const [theme, setTheme] = useState("bg-dark");
  const [fullscreen, setFullScreen] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [toggleSettings, setToggleSettings] = useState(false);
  const [currentPage, setCurrentPage] = useState(null);
  const [profilePicture, setProfilePicture] = useState();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpen1, setDropdownOpen1] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [noOfNewNotifications, setNoOfNewNotifications] = useState(0);

  const { addToast } = useToasts();

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const toggle1 = () => setDropdownOpen1((prevState) => !prevState);

  const toggleTheme = (bg) => {
    let htmlElement = document.getElementsByTagName("html")[0];
    htmlElement.classList.remove("bg-dark");
    htmlElement.classList.remove("bg-light");
    htmlElement.classList.add(bg);
    setTheme(bg);

    changeTheme(bg);
  };

  const [missedCalls, setMissedCalls] = useState([]);
  const [noOfNewNotifications1, setNoOfNewNotifications1] = useState(0);

  const getMissedcallNotifications = () => {
    const storedMissedCall = JSON.parse(
      localStorage.getItem("missedCallHistory")
    );
    if (Array.isArray(storedMissedCall)) {
      setMissedCalls(storedMissedCall);
    }

    let temp = filter(storedMissedCall, (notification1) => {
      return notification1.viewedAt == null;
    });

    setNoOfNewNotifications1(temp.length);
  };

  const notificationRead1 = () => {
    setNoOfNewNotifications1(0);
    const updatedMissedCalls = missedCalls.map((call) => {
      return {
        ...call,
        viewedAt: new Date(),
      };
    });
    setMissedCalls(updatedMissedCalls);
  };

  const loadEmployeeData = () => {
    if (auth) {
      if (notNull(auth.item.avatar)) {
        let url = String(
          MexxarAPI + String(auth.item.avatar).replace("pro_pic", "pro-pic")
        );
        setProfilePicture(url);
      } else {
        if (auth.item.gender === "MALE") {
          setProfilePicture(MexxarUrl + "images/maleEmployee.png");
        } else if (auth.item.gender === "FEMALE") {
          setProfilePicture(MexxarUrl + "images/femaleEmployee.png");
        } else {
          setProfilePicture(MexxarUrl + "images/default.jpg");
        }
      }
    } else {
      setProfilePicture(MexxarUrl + "images/default.jpg");
    }
  };

  const toggleFullScreen = () => {
    let htmlElement = document.getElementsByTagName("html")[0];
    try {
      if (fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          /* Firefox */
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          /* Chrome, Safari and Opera */
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          /* IE/Edge */
          document.msExitFullscreen();
        }
      } else {
        if (htmlElement.requestFullscreen) {
          htmlElement.requestFullscreen();
        } else if (htmlElement.mozRequestFullScreen) {
          /* Firefox */
          htmlElement.mozRequestFullScreen();
        } else if (htmlElement.webkitRequestFullscreen) {
          /* Chrome, Safari and Opera */
          htmlElement.webkitRequestFullscreen();
        } else if (htmlElement.msRequestFullscreen) {
          /* IE/Edge */
          htmlElement.msRequestFullscreen();
        }
      }
    } catch (e) {}
    setFullScreen(!fullscreen);
  };

  const getNotifications = () => {
    axios.get(`${HOSTMexxar}notifications/my-notifications`).then((res) => {
      setNotifications(res.data.body);

      let temp = filter(res.data.body, (notification) => {
        return notification.viewedAt == null;
      });

      setNoOfNewNotifications(temp.length);
    });
  };

  const notificationRead = () => {
    forEach(notifications, function (notification) {
      let Today = new Date();
      let temp = {
        id: notification.id,
        action: notification.action,
        body: notification.body,
        date: notification.date,
        tittle: notification.tittle,
        viewedAt: moment(Today).format("YYYY-MM-DD"),
      };

      if (notification.viewedAt == null) {
        axios
          .put(HOSTMexxar + "notifications", temp)
          .then((res) => {})
          .catch((err) => {
            //console.log(err)
            // toastCommon(true, "error");
          });
      }
    });

    getNotifications();
  };

  useDidMountEffect(() => {
    let newMessage;
    if (chatPostLoad != undefined && chatPostLoad.length > 0) {
      newMessage = last(chatPostLoad[0].messages, (y) => {
        return y;
      });
    }

    let pathArray = window.location.pathname.split("/");
    let currentPage = pathArray[2];

    if (currentPage != "chat" && newMessage != undefined) {
      ToastCommon(
        true,
        "chat",
        addToast,
        `${
          "New Message from : " +
          newMessage.sendersFirstName +
          " " +
          newMessage.sendersLastName +
          ":" +
          newMessage.message
        }`
      );
    }
  }, [newChatReceived]);

  useDidMountEffect(() => {
    ToastNotifications("Unconfirmed Booking", newUnconfirmedBookingReceived.id);
  }, [newUnconfirmedBookingReceived]);

  useDidMountEffect(() => {
    ToastNotifications("Todo", newTodoReceived.id);
  }, [newTodoReceived]);

  useDidMountEffect(() => {
    ToastNotifications("Reschedule", newRescheduleReceived.id);
  }, [newRescheduleReceived]);

  useDidMountEffect(() => {
    ToastNotifications("Event", newEventReceived.id);
  }, [newEventReceived]);

  useDidMountEffect(() => {
    ToastNotifications("Training", newTrainingRecived.id);
  }, [newTrainingRecived]);


  useDidMountEffect(() => {
    if (newClientCreationReceived) {
      const operationType = newClientCreationReceived?.id?.operationType;
      switch (operationType) {
        case "CREATE":
          ToastNotifications("New Client Registered", newClientCreationReceived.id);
          break
        case "UPDATE":
          ToastNotifications("Client Updated", newClientCreationReceived.id);
          break;
        case "DELETE":
          ToastNotifications("Client Deleted", newClientCreationReceived.id);
          break;
        
      }
  
    }
  }, [newClientCreationReceived]);
  

  // timeout for drop down in heading //
  useEffect(() => {
    const timer = setTimeout(() => {
      // console.log("This will run after 1 second!");
      setToggleSettings(false);
    }, 4000);
    return () => clearTimeout(timer);
  }, [toggleSettings]);

  useEffect(() => {
    loadEmployeeData();
    getNotifications();
    getMissedcallNotifications();

    if (isNull(currentPage)) {
      let pathArray = window.location.pathname.split("/");
      let currentPage = pathArray[2];
      setCurrentPage(currentPage);
    }

    return () => {};
  }, []);

  const history = useHistory();

  let [isOnline, connection] = useNetwork();
  // console.log("online staus", isOnline, connection);

  return (
    <>
      {!isOnline && (
        <Alert color="danger" className="text-center">
          Alert : It seems you are offline! Please check your internet
          connection. Reconnecting...
          <image src={noInternet} className="mx-1" />
        </Alert>
      )}
      <div
        className={`navbar navbar-expand-lg ${
          settings.isDashboardOpen ? "" : ""
        }`}
      >
        {currentPage != "docview" && (
          <div className="d-none d-md-flex align-items-center">
            <FontAwesomeIcon
              id="goBack"
              icon={faChevronLeft}
              style={{ boxShadow: "none" }}
              className="mx-3  btn-raised pointer"
              onClick={history.goBack}
            />
            Back
          </div>
        )}

        <ul className="nav navbar-menu order-1 order-lg-2">
          <li className="nav-item d-none d-sm-block mx-1">
            <Dropdown direction="left" isOpen={dropdownOpen} toggle={toggle}>
              {/* <div className={"mr-3d-flex align-items-center mr-3"}>
                <small>{notifications.length}</small>
              </div> */}

              {noOfNewNotifications != 0 && (
                <div
                  className={
                    "mr-3 d-flex align-items-center justify-content-center p-1 btn-raised btn-wave"
                  }
                  style={{
                    position: "absolute",
                    left: "20px",
                    top: "-7px",
                    height: "20px",
                    width: "20px",
                    borderRadius: "10px",
                    backgroundColor: "#dc3545",
                  }}
                >
                  {noOfNewNotifications > 9 ? (
                    <small>9+</small>
                  ) : (
                    <small
                      style={{
                        fontWeight: "bold",
                        color: "white",
                        textAlign: "center",
                      }}
                    >
                      {noOfNewNotifications}
                    </small>
                  )}
                </div>
              )}

              <DropdownToggle
                tag="span"
                data-toggle="dropdown"
                aria-expanded={dropdownOpen}
              >
                <div className="mx-2  ">
                  <FontAwesomeIcon
                    onClick={notificationRead}
                    icon={faBell}
                    color="#B8C1D2"
                    className="fa-lg mb-2 btn-raised btn-wave mt-2 "
                    id="notification"
                    style={{
                      boxShadow: "none",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </DropdownToggle>
              <DropdownMenu>
                <div
                  style={{
                    height:
                      notifications && notifications.length == 0
                        ? "100px"
                        : "500px",
                    overflowY: "auto",
                    width: "400px",
                    borderRadius: "10px",
                  }}
                >
                  <h5 className="m-3 ">Notifications</h5>
                  {notifications
                    .map((notification, id) => {
                      return (
                        <NotificationCard
                          key={id}
                          notification={notification}
                        ></NotificationCard>
                      );
                    })
                    .reverse()}

                  {notifications && notifications.length == 0 ? (
                    <div className="text-center">
                      You currently have no new notifications
                    </div>
                  ) : null}
                </div>
              </DropdownMenu>
            </Dropdown>

            <UncontrolledTooltip placement="right" target="notification">
              You have {noOfNewNotifications} new notifications
            </UncontrolledTooltip>
          </li>

          <li className="nav-item d-none d-sm-block mx-1">
            <Dropdown direction="left" isOpen={dropdownOpen1} toggle={toggle1}>
              {noOfNewNotifications1 != 0 && (
                <div
                  className={
                    "mr-3 d-flex align-items-center justify-content-center p-1 btn-raised btn-wave"
                  }
                  style={{
                    position: "absolute",
                    left: "20px",
                    top: "-7px",
                    height: "20px",
                    width: "20px",
                    borderRadius: "10px",
                    backgroundColor: "#dc3545",
                  }}
                >
                  {noOfNewNotifications1 > 9 ? (
                    <small>9+</small>
                  ) : (
                    <small
                      style={{
                        fontWeight: "bold",
                        color: "white",
                        textAlign: "center",
                      }}
                    >
                      {noOfNewNotifications1}
                    </small>
                  )}
                </div>
              )}

              <DropdownToggle
                tag="span"
                data-toggle="dropdown"
                aria-expanded={dropdownOpen1}
              >
                <div className="mx-2  ">
                  <FontAwesomeIcon
                    onClick={notificationRead1}
                    icon={faPhoneSlash}
                    color="#F80055"
                    className="fa-lg mb-2 btn-raised btn-wave mt-2 "
                    id="notification1"
                    style={{
                      boxShadow: "none",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </DropdownToggle>
              <DropdownMenu>
                <div
                  style={{
                    height:
                      missedCalls && missedCalls.length == 0
                        ? "100px"
                        : "500px",
                    overflowY: "auto",
                    width: "400px",
                    borderRadius: "10px",
                  }}
                >
                  <h5 className="m-3 ">Missed Call Alerts</h5>
                  {/* return ( */}
                  <div>
                    {missedCalls.map((call, index) => (
                      <div key={index} className="card-body mt-2 p-1">
                        <div className="d-flex flex-row justify-content-between">
                          <div className="d-flex" style={{ width: "450px" }}>
                            <div className="d-flex align-items-center mr-3">
                              <span className="mx-2">
                                <b className="badge badge-circle sm text-primary"></b>
                              </span>
                            </div>
                            <div>
                              {/* <small>ID: {call.Id}</small> */}
                              <div className="item-mail text-muted h-1x d-none d-sm-block">
                                <small>Call State: {call.CallState}</small>
                                {/* <small>Dial String: {call.CallerContact?.DialString}</small>
                                <small>Display Name : {call?.Name}</small>
                                <small>Date : {call?.StartTimeUtc}</small> */}
                              </div>
                              <div className="item-mail text-muted h-1x d-none d-sm-block">
                                <small>
                                  Dial String: {call.CallerContact?.DialString}
                                </small>
                              </div>
                              <div className="item-mail text-muted h-1x d-none d-sm-block">
                                <small>Display Name : {call?.Name}</small>
                              </div>
                              <div className="item-mail text-muted h-1x d-none d-sm-block">
                                <small>Date : {call?.StartTimeUtc}</small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  {missedCalls && missedCalls.length == 0 ? (
                    <div className="text-center">
                      You currently have no new missed call alerts
                    </div>
                  ) : null}
                </div>
              </DropdownMenu>
            </Dropdown>

            <UncontrolledTooltip placement="right" target="notification1">
              You have {noOfNewNotifications1} new missed call alerts
            </UncontrolledTooltip>
          </li>

          <FontAwesomeIcon
            icon={faExpandAlt}
            id="expand"
            style={{
              boxShadow: "none",
              cursor: "pointer",
            }}
            className="fa-md btn-raised btn-wave mx-2 "
            onClick={() => {
              {
                changeSideNavigation(!props.settings.isSideNavigationOpen);
                changeDashboard(!props.settings.isDashboardOpen);
              }
              // changeDashboard(false);
              //toggleFullScreen();
            }}
          ></FontAwesomeIcon>
          {props.settings.isSideNavigationOpen ? (
            <UncontrolledTooltip placement="right" target="expand">
              Expand window
            </UncontrolledTooltip>
          ) : (
            <UncontrolledTooltip placement="right" target="expand">
              Collapse window
            </UncontrolledTooltip>
          )}

          <li className="nav-item d-none d-sm-block" id="fullScreen">
            <a
              className="nav-link px-2 btn-raised btn-wave "
              onClick={toggleFullScreen}
              style={{
                boxShadow: "none",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-maximize"
              >
                <path d="M8 3H5a2 2 0 0 0-2 2v3m18 0V5a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M3 16v3a2 2 0 0 0 2 2h3"></path>
              </svg>
            </a>
          </li>

          {fullscreen ? (
            <UncontrolledTooltip placement="right" target="fullScreen">
              Switch to windowed view
            </UncontrolledTooltip>
          ) : (
            <UncontrolledTooltip placement="right" target="fullScreen">
              Switch to full screen view
            </UncontrolledTooltip>
          )}

          <div className="d-flex align-items-center justify-content-center">
            {props.theme == "bg-light" ? (
              <FontAwesomeIcon
                id="theme"
                icon={faToggleOn}
                onClick={() => toggleTheme("bg-dark")}
                className="mr-1  btn-raised  fa-solid pointer shadow-none"
                style={{ fontSize: "20px" }}
              />
            ) : (
              <FontAwesomeIcon
                id="theme"
                icon={faToggleOff}
                onClick={() => toggleTheme("bg-light")}
                style={{ fontSize: "20px" }}
                className=" mr-1  btn-raised  fa-solid pointer shadow-none"
              />
            )}
          </div>
          <UncontrolledTooltip placement="right" target="theme">
            Change theme
          </UncontrolledTooltip>

          <li className="nav-item dropdown" style={{ display: "none" }}>
            <a className="nav-link px-2 mr-lg-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-bell"
              >
                <path d="M22 17H2a3 3 0 0 0 3-3V9a7 7 0 0 1 14 0v5a3 3 0 0 0 3 3zm-8.27 4a2 2 0 0 1-3.46 0"></path>
              </svg>
              <span className="badge badge-pill badge-up bg-primary">7</span>
            </a>
          </li>
          <li className="nav-item dropdown">
            <a
              href="#"
              className="nav-link d-flex align-items-center px-2 text-color"
            >
              <span
                className="avatar w-24"
                style={{ margin: "-2px" }}
                onClick={() => props.signOutAction()}
              >
                <img
                  src={profilePicture}
                  alt=""
                  style={{
                    height: "2rem",
                    width: "2rem",
                    borderRadius: "50%",
                    objectFit: "cover",
                  }}
                ></img>
              </span>
            </a>
          </li>

          <li className="nav-item d-lg-none">
            <a
              className="nav-link px-1"
              onClick={() => {
                {
                  changeSideNavigation(!props.settings.isSideNavigationOpen);
                  changeDashboard(!props.settings.isSideNavigationOpen);
                }
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-menu"
              >
                <line x1="3" y1="12" x2="21" y2="12"></line>
                <line x1="3" y1="6" x2="21" y2="6"></line>
                <line x1="3" y1="18" x2="21" y2="18"></line>
              </svg>
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    settings: state.appSettings,
    theme: state.theme.theme,
    chatPostLoad: state.chatDbPostReducer.chatDbPostLoad,
    newChatReceived: state.chatNewNotificationReducer,
    newTodoReceived: state.notificationReducer.todo,
    newUnconfirmedBookingReceived: state.notificationReducer.unconfirmedBooking,
    newRescheduleReceived: state.notificationReducer.reschedule,
    newEventReceived: state.notificationReducer.event,
    newTrainingRecived: state.notificationReducer.training,
    newClientCreationReceived:state.notificationReducer.clientCreation,

  };
}

export default connect(mapStateToProps, {
  changeTheme,
  changeSideNavigation,
  changeDashboard,
  signOutAction,
})(Header);
