import React, { useEffect, useState } from 'react';
import { Input } from 'reactstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './dateInput.css'; 
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useToasts } from "react-toast-notifications";

const DateInput = ({ id, invalid, register, value, defaultValue, onChange, readOnly, isDOB = false }) => {
  const [dateValue, setDateValue] = useState(defaultValue || '');
  const [selectedDate, setSelectedDate] = useState(null);
  const [dateError, setDateError] = useState(false);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const handleDateChange = (e) => {
    setDateError(false);
    if (readOnly) return;
  
    const input = e.target;
    const rawValue = dateValue || ''; // Current value in the input
    const cursorPosition = input.selectionStart; // Current cursor position
    const inputValue = e.target.value; // Value typed by the user
  
    // Remove all non-numeric characters
    const cleanValue = inputValue.replace(/[^\d]/g, '');
  
    // Format incrementally into DD/MM/YYYY
    let formattedValue = '';
    if (cleanValue.length > 0) formattedValue = cleanValue.slice(0, 2);
    if (cleanValue.length > 2) formattedValue += '/' + cleanValue.slice(2, 4);
    if (cleanValue.length > 4) formattedValue += '/' + cleanValue.slice(4, 8);
  
    // Adjust the cursor position based on changes
    let adjustment = 0;
    const isAddingSlash =
      formattedValue.length > rawValue.length && 
      formattedValue[cursorPosition - 1] === '/';
  
    if (isAddingSlash) {
      adjustment = 1; // Skip over the `/` automatically added
    }
  
    setDateValue(formattedValue);
  
    // Set the cursor position after rendering
    const newCursorPosition = Math.max(0, Math.min(formattedValue.length, cursorPosition + adjustment));
    setTimeout(() => {
      input.setSelectionRange(newCursorPosition, newCursorPosition);
    }, 0);
  
    // Notify parent if the date is complete and valid
    const regex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;
    if (regex.test(formattedValue) && typeof onChange === 'function') {
      onChange(formattedValue);
    }
  };
  

  const handleDateBlur = () => {
    const regex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;
  
    if (dateValue && regex.test(dateValue)) {
      // Keep valid date
      setDateValue(dateValue);
      if (typeof onChange === 'function') onChange(dateValue);
    } else {
      // Notify user of invalid input
      setDateValue('');
      setDateError(true);
    }
  };
  
  

  const handleDateSelect = (date) => {
    setSelectedDate(date);
    if (date) {
      const formattedDate = `${String(date.getDate()).padStart(2, '0')}/${String(date.getMonth() + 1).padStart(2, '0')}/${date.getFullYear()}`;
      setDateValue(formattedDate);
      if (typeof onChange === 'function') onChange(formattedDate);
    }
    setIsDatePickerOpen(false);
  };

  const toggleDatePicker = () => {
    if (!readOnly) setIsDatePickerOpen(!isDatePickerOpen);
  };

  useEffect(() => {
    if (value) {
      const [day, month, year] = value.split('/');
      const dateObj = new Date(`${year}-${month}-${day}`);
      setSelectedDate(dateObj);
      setDateValue(value);
    }
  }, [value]);

  return (
    <div className="date-input-container">
      <div className="input-with-icon">
      <Input
  type="text"
  id={id}
  name={id}
  invalid={invalid}
  value={dateValue}
  onChange={handleDateChange}
  onBlur={handleDateBlur} // Validate on unfocus
  placeholder="DD/MM/YYYY"
  maxLength={10}
  innerRef={register}
  readOnly={readOnly}
  aria-label="Date input"
/>

        <FontAwesomeIcon
          icon={faCalendar}
          onClick={readOnly ? null : toggleDatePicker}
          className="calendar-icon"
          aria-label="Open calendar"
          role="button"
        />
      </div>
      {isDatePickerOpen && (
        <DatePicker
          selected={selectedDate}
          onChange={handleDateSelect}
          dateFormat="dd/MM/yyyy"
          maxDate={isDOB ? new Date() : null}
          inline
        />
      )}
       {dateError && <div className="text-danger"> Set date is invalid</div>}
    </div>
  );
};

export default DateInput;
