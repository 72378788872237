import React, { useEffect, useState } from "react";
import {
  Label,
  Button,
  ButtonGroup,
  Col,
  Row,
  Input,
  FormGroup,
  Badge,
} from "reactstrap";
import {
  EMPLOYEE_PERMISSIONS,
  Add_NEW_EMPLOYEE,
  HOSTMexxar,
  EMPLOYEE_TEAMS,
} from "../../configs/api-config";
import {
  forEach,
  uniqWith,
  isEqual,
  filter,
  split,
  isNull,
  groupBy,
  add,
  sumBy,
} from "lodash";

import axios from "axios";
import moment from "moment";
import Select from "react-select";
import { Bar } from "react-chartjs-2";
import useDidMountEffect from "../../components/Modal/ModalBody/intialRunUseEffect";
import * as XLSX from "xlsx";
import dateFormatter from "../../util/common/dateFormatter";
import DateInput from "../../util/common/DateInput";

const OohTrackerTable = (props) => {
  const MexxarApi = HOSTMexxar;
  const MexxarEmployee = Add_NEW_EMPLOYEE;
  const MexxarTeams = EMPLOYEE_TEAMS;

  const datatype = props;

  const [rows, setRows] = useState([]);
  const [dateTo, setDateTo] = useState();
  const [team, setTeam] = useState(null);
  const [range, setRange] = useState(false);
  const [dateFrom, setDateFrom] = useState();
  const [dataList, setDataList] = useState([]);
  const [consultant, setConsultant] = useState(null);
  const [rangeType, setRangeType] = useState("WEEKLY");
  const [selectedTeam, setSelectedTeam] = useState([]);
  const [endDateShowOnly, setEndDateShowOnly] = useState();
  const [startDateShowOnly, setStartDateShowOnly] = useState();
  const [teamOrIndividual, setTeamOrIndividual] = useState(false);
  const [selectedConsultant, setSelectedConsultant] = useState([]);

  const [checkIsProcessing, setCheckIsProcessing] = useState(false);
  const getAllRows = () => {
    //according to the userrole change the performance data in the chart
    //currently showing the looged in user's data

    // setStartAndEndDates();

    let startTempDate;
    let endTempDate;

    // if (rangeType == "DAILY") {
    //   startTempDate = moment(startDate).format("YYYY/MM/DD");
    // } else

    if (rangeType == "RANGE" && range == true) {
      startTempDate = moment(dateFrom).format("YYYY/MM/DD");

      endTempDate = moment(dateTo).format("YYYY/MM/DD");
    } else if (rangeType == "DAILY") {
      startTempDate = moment(dateFrom).format("YYYY/MM/DD");

      endTempDate = moment(dateFrom).format("YYYY/MM/DD");
    } else if (rangeType == "WEEKLY") {
      startTempDate = moment(moment().startOf("isoWeek").toDate()).format(
        "YYYY/MM/DD"
      );

      endTempDate = moment(moment().endOf("isoWeek").toDate()).format(
        "YYYY/MM/DD"
      );
    } else if (rangeType == "MONTHLY") {
      startTempDate = moment(moment().startOf("month").toDate()).format(
        "YYYY/MM/DD"
      );

      endTempDate = moment(new Date()).format("YYYY/MM/DD");
    } else if (rangeType == "YEARLY") {
      startTempDate = moment(moment().startOf("year").toDate()).format(
        "YYYY/MM/DD"
      );

      endTempDate = moment(new Date()).format("YYYY/MM/DD");
    }
    setStartDateShowOnly(startTempDate);
    setEndDateShowOnly(endTempDate);

    if (teamOrIndividual && selectedTeam != null) {
      axios
        .get(
          MexxarApi +
            "charts-and-reports/daily-tracker/ooh/team/" +
            selectedTeam.id +
            "?startDate=" +
            startTempDate +
            "&endDate=" +
            endTempDate
        )
        .then((res) => {
          if (res.data.status == "success") {
            setRows(res.data.body);
          } else {
            setRows([]);
          }
        })
        .catch((err) => {
          setRows([]);
        });
    } else {
      {
        selectedConsultant.id &&
          axios
            .get(
              MexxarApi +
                "charts-and-reports/daily-tracker/ooh?startDate=" +
                startTempDate +
                "&endDate=" +
                endTempDate +
                "&employeeId=" +
                selectedConsultant.id
            )
            .then((res) => {
              if (res.data.status == "success") {
                setRows(res.data.body);
              } else {
                setRows([]);
              }
            })
            .catch((err) => {
              setRows([]);
            });
      }
    }
  };

  const getTeams = () => {
    axios
      .get(MexxarTeams)
      .then((res) => {
        let teamArray = [];

        forEach(res.data.body, function (item) {
          teamArray.push({
            value: item.name,
            label: item.name,
            id: item.id,
          });
        });

        setTeam(teamArray);
      })
      .catch((err) => {});
  };

  const getConsultant = () => {
    axios
      .get(MexxarEmployee)
      .then((res) => {
        let consultantArray = [];

        forEach(res.data.body, function (item) {
          consultantArray.push({
            value: item.firstName + " " + item.lastName,
            label: item.firstName + " " + item.lastName,
            id: item.id,
          });
        });

        setConsultant(consultantArray);
      })
      .catch((err) => {});
  };

  useDidMountEffect(() => {
    getAllRows();
  }, [
    dateFrom,
    dateTo,
    selectedTeam,
    selectedConsultant,
    rangeType,
    teamOrIndividual,
  ]);

  useDidMountEffect(() => {
    setSelectedConsultant([]);
    setSelectedTeam([]);
    setRows([]);
  }, [teamOrIndividual]);

  useEffect(() => {
    getConsultant();
    getTeams();
    getAllRows();
  }, []);

  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();

    const includeDayColumn = rangeType === "WEEKLY" || rangeType === "DAILY";

    const header = [
      "Date",
      ...(includeDayColumn ? ["Day"] : []),
      "No of calls made/day",
      "Spoken/day",
      "No of clearances/day",
      "No of candidates sent docs/day",
      "No of files sent to file checks/day",
    ];

    let titleRow, titleValue, targetRow;

    if (teamOrIndividual) {
      titleRow = ["Name of the team"];
      titleValue =
        selectedTeam && selectedTeam.label ? selectedTeam.label : "None";

      targetRow = null;
    } else {
      titleRow = ["Name of the consultant"];
      titleValue =
        selectedConsultant && selectedConsultant.label
          ? selectedConsultant.label
          : "None";

      if (
        rows &&
        rows.callingTarget !== undefined &&
        rows.answeringTarget !== undefined &&
        rows.clearanceTarget !== undefined
      ) {
        targetRow = [
          "Target",
          ...(includeDayColumn ? [""] : []),
          rows.callingTarget,
          rows.answeringTarget,
          rows.clearanceTarget,
          "",
          "",
        ];
      } else {
        targetRow = null;
      }
    }

    const wsData = [titleRow, [titleValue], [], header];

    if (targetRow) {
      wsData.push(targetRow);
    }

    if (rows && rows.data) {
      rows.data.forEach((row) => {
        const rowData = [
          row.date,
          ...(includeDayColumn ? [moment(row.date).format("dddd")] : []),
          row.noOfCallMade,
          row.spoken,
          row.clearance,
          row.noFilesWithCompliance,
          row.noFilesSentToChecks,
        ];
        wsData.push(rowData);
      });
    } else {
      console.warn("rows.data is undefined or empty");
    }

    wsData.push([]);
    wsData.push(["Powered by Mexxar"]);

    const ws = XLSX.utils.aoa_to_sheet(wsData);

    const wscols = [
      { wch: 15 },
      ...(includeDayColumn ? [{ wch: 10 }] : []),
      { wch: 20 },
      { wch: 15 },
      { wch: 20 },
      { wch: 30 },
      { wch: 30 },
    ];
    ws["!cols"] = wscols;

    XLSX.utils.book_append_sheet(wb, ws, "OOH Daily Tracker");
    XLSX.writeFile(wb, "OOH_Daily_Tracker.xlsx");
  };

  const [showExportButton, setShowExportButton] = useState(false);

  useEffect(() => {
    if (selectedTeam.length === 0 && selectedConsultant.length === 0) {
      setShowExportButton(false);
    } else {
      setShowExportButton(true);
    }
  }, [selectedTeam, selectedConsultant]);

  return (
    <div className="card">
      <div className="card-body">
        <Row>
          <Col>
            <Label>OOH Daily Tracker</Label>
            {showExportButton && (
              <Button
                className="ml-3"
                size="sm"
                style={{ zIndex: 0, backgroundColor: "green" }}
                onClick={exportToExcel}
              >
                Export to Excel
              </Button>
            )}
            <ButtonGroup className="ml-3" size="sm" style={{ zIndex: 0 }}>
              {/* <Button
    active={rangeType === "DAILY"}
    onClick={() => {
      const today = moment(new Date()).format("YYYY-MM-DD");
      setRangeType("DAILY");
      setRange(false);
      setDateFrom(today);
      setDateTo("");
   
      document.getElementById("dateFrom").value = today;
      document.getElementById("dateTo").value = ""; 
    }}
  >
    Daily
  </Button> */}
              <Button
                active={rangeType === "DAILY"}
                onClick={() => {
                  const today = dateFormatter.formatDateToYYYYMMDD(new Date());
                  setRangeType("DAILY");
                  setRange(false);
                  setDateFrom(today);
                  setDateTo(""); // Clear dateTo for daily range
                }}
              >
                Daily
              </Button>

              <Button
                active={rangeType === "WEEKLY"}
                onClick={() => {
                  setRangeType("WEEKLY");
                  setRange(false);
                  setDateFrom("");
                  setDateTo("");
                }}
              >
                Weekly
              </Button>

              <Button
                active={rangeType === "MONTHLY"}
                onClick={() => {
                  setRangeType("MONTHLY");
                  setRange(false);
                  setDateFrom("");
                  setDateTo("");
                }}
              >
                Monthly
              </Button>

              <Button
                active={rangeType === "YEARLY"}
                onClick={() => {
                  setRangeType("YEARLY");
                  setRange(false);
                  setDateFrom("");
                  setDateTo("");
                }}
              >
                Yearly
              </Button>

              <Button
                active={rangeType === "RANGE"}
                onClick={() => {
                  const today = dateFormatter.formatDateToYYYYMMDD(new Date());
                  setRangeType("RANGE");
                  setRange(true);
                  setDateFrom(today);
                  setDateTo(today); // Set both dates for range
                }}
              >
                Range
              </Button>
              {/* <Button
  
    active={rangeType === "RANGE"}
    
    onClick={() => {
      const today = moment(new Date()).format("YYYY-MM-DD");
      setRangeType("RANGE");
      setRange(true);
      setDateFrom(today); 
      setDateTo(today); 

      
      document.getElementById("dateFrom").value = today;
      document.getElementById("dateTo").value = today;
    }}
  >
    Range
  </Button> */}
            </ButtonGroup>
            {rangeType != "DAILY" && range == false ? (
              <>
                <Label className="mx-3">From : {startDateShowOnly}</Label>
                <Label className="mx-3">To : {endDateShowOnly} </Label>
              </>
            ) : null}
          </Col>
        </Row>
        <FormGroup check>
          <Label check>
            <Input
              type="checkbox"
              checked={teamOrIndividual}
              onChange={() => setTeamOrIndividual(!teamOrIndividual)}
            />{" "}
            <Badge color="danger" className="mr-1">
              Filter By : {teamOrIndividual ? "Team" : "Consultant"}{" "}
            </Badge>
          </Label>
        </FormGroup>
        <Row>
          {rangeType == "DAILY" || range == true ? (
            <Col>
              {rangeType === "DAILY" ? (
                <Label for="exampleDate">Date</Label>
              ) : (
                <Label for="exampleDate">Start Date</Label>
              )}

              <DateInput
                id="dateFromOOH"
                value={dateFormatter.formatDateToDDMMYYYY(dateFrom)}
                onChange={(date) =>
                  setDateFrom(dateFormatter.formatDateToYYYYMMDD(date))
                }
              />
            </Col>
          ) : null}

          {range == true ? (
            <Col>
              <Label for="exampleDate">End Date</Label>

              <DateInput
                id="dateToOOH"
                value={dateFormatter.formatDateToDDMMYYYY(dateTo)}
                onChange={(date) =>
                  setDateTo(dateFormatter.formatDateToYYYYMMDD(date))
                }
              />
            </Col>
          ) : null}
        </Row>
        <table
          className="mt-2"
          style={{
            border: "0.5px solid #536478",
            margin: "auto",
            width: "100%",
          }}
        >
          <thead
            style={{
              backgroundColor: "#535c78",
              padding: "15px",
            }}
          >
            <td
              style={{ width: "250px", padding: "15px", color: "whitesmoke" }}
            >
              {teamOrIndividual ? (
                <>
                  {" "}
                  <Label>Registration Team</Label>
                  <Select
                    // isMulti
                    z-index="100"
                    name="candidateSpecialityId"
                    className="basic-multi-select text-dark"
                    classNamePrefix="select"
                    onChange={(data) => setSelectedTeam(data)}
                    options={team}
                  />{" "}
                </>
              ) : null}

              {!teamOrIndividual ? (
                <>
                  {" "}
                  <Label>Name of the consultant</Label>
                  <Select
                    // isMulti
                    z-index="999"
                    name="candidateSpecialityId"
                    className="basic-multi-select text-dark"
                    classNamePrefix="select"
                    onChange={(data) => setSelectedConsultant(data)}
                    options={consultant}
                  />
                </>
              ) : null}

              {selectedTeam.length == 0 && teamOrIndividual ? (
                <small className="mx-3">
                  * Please select a team to load the chart
                </small>
              ) : null}
            </td>
            <td style={{ width: "150px", color: "whitesmoke" }}> </td>
            <td
              style={{
                width: "150px",
                textAlign: "center",
                color: "whitesmoke",
              }}
            >
              No of calls made/day
            </td>
            <td
              style={{
                width: "150px",
                textAlign: "center",
                color: "whitesmoke",
              }}
            >
              Spoken/day
            </td>
            <td
              style={{
                width: "150px",
                textAlign: "center",
                color: "whitesmoke",
              }}
            >
              No of clearances/day
            </td>
            <td
              style={{
                width: "150px",
                textAlign: "center",
                color: "whitesmoke",
              }}
            >
              No of candidates sent docs/day
            </td>
            <td
              style={{
                width: "150px",
                textAlign: "center",
                color: "whitesmoke",
              }}
            >
              No of files sent to file checks /day
            </td>
          </thead>
          <thead>
            {rows.data != undefined && !teamOrIndividual ? (
              <tr
                style={{
                  padding: "15px",
                  backgroundColor: " #757c94",
                }}
              >
                <td
                  style={{
                    width: "250px",
                    padding: "15px",
                    color: "whitesmoke",
                  }}
                  className="ml-1"
                >
                  Targets
                </td>
                <td style={{ width: "150px", color: "whitesmoke" }}> </td>
                <td
                  style={{
                    width: "150px",
                    textAlign: "center",
                    color: "whitesmoke",
                  }}
                >
                  {rows.callingTarget}
                </td>
                <td
                  style={{
                    width: "150px",
                    textAlign: "center",
                    color: "whitesmoke",
                  }}
                >
                  {rows.answeringTarget}
                </td>
                <td
                  style={{
                    width: "150px",
                    textAlign: "center",
                    color: "whitesmoke",
                  }}
                >
                  {rows.clearanceTarget}
                </td>
                <td
                  style={{
                    width: "150px",
                    textAlign: "center",
                    color: "whitesmoke",
                  }}
                >
                  {rows.noFilesWithCompliance}
                </td>
                <td
                  style={{
                    width: "150px",
                    textAlign: "center",
                    color: "whitesmoke",
                  }}
                >
                  {rows.noFilesSentToChecks}
                </td>
              </tr>
            ) : null}

            {rows.data != undefined ? (
              rows.data.map((row, i) => (
                <tr
                  key={i}
                  style={{
                    padding: "15px",
                    borderBottom: "0.5px solid #b9c0d3",
                    height: "50px",
                  }}
                >
                  <td style={{ width: "250px", padding: "15px" }}>
                    {row.date}
                  </td>

                  <td style={{ width: "150px" }}>
                    {rangeType == "WEEKLY" || rangeType == "DAILY"
                      ? moment(row.date).format("dddd")
                      : ""}
                  </td>
                  <td style={{ width: "150px", textAlign: "center" }}>
                    {row.noOfCallMade}
                  </td>
                  <td style={{ width: "150px", textAlign: "center" }}>
                    {row.spoken}
                  </td>
                  <td style={{ width: "150px", textAlign: "center" }}>
                    {row.clearance}
                  </td>
                  <td style={{ width: "150px", textAlign: "center" }}>
                    {row.noFilesWithCompliance}
                  </td>
                  <td style={{ width: "150px", textAlign: "center" }}>
                    {row.noFilesSentToChecks}
                  </td>
                </tr>
              ))
            ) : rows.length == 0 ? (
              <>* Please select a team or consultant to load the chart</>
            ) : null}
          </thead>
        </table>
      </div>
    </div>
  );
};

export default OohTrackerTable;
