import React, { useState, useEffect } from "react";
import {
  Collapse,
  Card,
  CardBody,
  Label,
  Input,
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  Badge,
  FormGroup,
} from "reactstrap";

import {
  CLIENTS,
  WARDS,
  SHIFTS,
  CREATE_BOOKING,
  HOSTMexxar,
  ClientLable,
} from "../../../configs/api-config";

import axios from "axios";
import moment from "moment";
import { connect } from "react-redux";
import { forEach, isNull, filter } from "lodash";
import NoConnection from "../../errorPages/NoConnection";
import loadingAnimation from "../../../../src/images/puff.svg";
import NoSearchResults from "../../errorPages/NoSearchResults";
import { useIsMounted } from "../../../customHooks/useIsMounted";
import BookingSummeryWorkSpace from "../../../widgets/booking-summery-card-booking-workspace/booking-summery-card-booking-workspace";
import { FunnelIcon } from "../../../assets/icons/svg";
import ExternalShiftsCardWidget from "../../../widgets/working-card-widget/externalShiftsCardWidget";
import TransactionWidgetFilter from "./TransactionWidgetFilter";
import dateFormatter from "../../../util/common/dateFormatter";
import DateInput from "../../../util/common/DateInput";
// import { FormGroup } from "react-bootstrap";

const filterByName = (name, dataSet) => {
  let filtered = dataSet;
  if (name !== "") {
    filtered = dataSet.filter((item) => {
      let searchableString = `${item.candidateName} ${item.referenceNo} ${item.candidatesId}   ${item.clientName} ${item.ward.name}   `;
      let p = searchableString;
      return p.toLowerCase().includes(name.toLowerCase());
    });
  }
  let lengthofSearch = filtered.length;
  return { filtered, lengthofSearch };
};
const Transaction = (props) => {
  const { incomingPage, candidateId, auth, employeeID } = props;

  const isMounted = useIsMounted();
  const MexxarApiShifts = SHIFTS;
  const MexxarApi = CREATE_BOOKING;
  const MexxarApiClients = CLIENTS;
  const MexxarApiDepartments = WARDS;

  const currentDate = new Date();

  const yesterDay = dateFormatter.formatDateToYYYYMMDDSlash(
    moment(currentDate).subtract(1, "days")
  );
  const tommorow = dateFormatter.formatDateToYYYYMMDDSlash(
    moment(currentDate).add(10, "days")
  );

  const [rows, setRows] = useState([]);
  const [shifts, setShifts] = useState([]);
  const [error, setError] = useState(null);
  const [clients, setClients] = useState([]);
  const [search, setSearch] = useState(false);
  const [loading, setLoading] = useState(true);
  const [departments, setDepartments] = useState([]);
  const [initialRows, setInitialRows] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedDate, setSelectedDate] = useState([]);
  const [getEndDate, setGetEndDate] = useState(tommorow);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [getStartDate, setGetStartDate] = useState(yesterDay);
  const [serviceType, setServiceType] = useState("");

  const handleSearchOnChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const toggleFilter = () => setIsOpenFilter(!isOpenFilter);

  const getAllRows = () => {
    setLoading(true);
    setError(null);

    axios
      .get(HOSTMexxar + "transaction/getAll/filter", {
        params: {
          // status: status == "" ? null : status,
          startDate:
            getStartDate == undefined
              ? null
              : moment(getStartDate).format("YYYY/MM/DD"),
          endDate:
            getEndDate == undefined
              ? null
              : moment(getEndDate).format("YYYY/MM/DD"),

          creditServiceType: serviceType == "" ? null : serviceType,
        },
      })
      .then((res) => {
        if (isMounted.current) {
          setLoading(false);
          setRows(res?.data?.body?.content);
          setInitialRows(res?.data?.body?.content);
        }
      })
      .catch((err) => {
        console.log("error----->", err);
        setError(err);

        //console.log(err)
        //toastCommon(true, "error");
      });
  };

  const [resetState, setResetState] = useState(false);

  const reset = () => {
    setGetStartDate(yesterDay);
    setGetEndDate(tommorow);
    setServiceType("");
    setResetState(!resetState);

    // getFieldData();
  };

  useEffect(() => {
    getAllRows();
    // getFieldData();
  }, [search, resetState]);

  const sortFunctionAscending = (a, b) => {
    var dateA = new Date(a.shiftFrom).getTime();
    var dateB = new Date(b.shiftFrom).getTime();
    return dateA > dateB ? 1 : -1;
  };
  console.log("Start Date display", getStartDate);
  console.log("End Date display", getEndDate);
  return (
    <>
      <div className="form-inline">
        <Button onClick={toggleFilter} className="btn bg-dark-lt text-dark">
          <FunnelIcon />
          <span className="mx-1">Filter</span>
        </Button>
      </div>
      <br></br>
      <Collapse isOpen={isOpenFilter}>
        <Card>
          <CardBody>
            <Row>
              <Col>
                <Label for="exampleDate">Start Date</Label>

                <DateInput
                  id="startDate"
                  value={dateFormatter.formatDateToDDMMYYYY(getStartDate)}
                  onChange={(date) =>
                    setGetStartDate(dateFormatter.formatDateToYYYYMMDD(date))
                  }
                />
              </Col>
              <Col>
                <Label for="exampleDate">End Date</Label>

                <DateInput
                  id="endDate"
                  value={dateFormatter.formatDateToDDMMYYYY(getEndDate)}
                  onChange={(date) =>
                    setGetEndDate(dateFormatter.formatDateToYYYYMMDD(date))
                  }
                />
              </Col>

              <Col>
                <Label>Credit Service type</Label>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value=""
                      checked={serviceType === ""}
                      onChange={(e) => setServiceType(e.target.value)}
                      style={{ cursor: "pointer" }}
                    />
                    <Badge color="info">All</Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value="CLIENT_MAP"
                      checked={serviceType === "CLIENT_MAP"}
                      onChange={(e) => setServiceType(e.target.value)}
                      style={{ cursor: "pointer" }}
                    />
                    <Badge color="danger">Client Map</Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value="CHAT"
                      checked={serviceType === "CHAT"}
                      onChange={(e) => setServiceType(e.target.value)}
                      style={{ cursor: "pointer" }}
                    />
                    <Badge color="secondary">Chat</Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value="EMAIL"
                      checked={serviceType === "EMAIL"}
                      onChange={(e) => setServiceType(e.target.value)}
                      style={{ cursor: "pointer" }}
                    />
                    <Badge color="primary">Email</Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value="MESSAGE"
                      checked={serviceType === "MESSAGE"}
                      onChange={(e) => setServiceType(e.target.value)}
                      style={{ cursor: "pointer" }}
                    />
                    <Badge color="warning">Message</Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value="TIME_SHEET"
                      checked={serviceType === "TIME_SHEET"}
                      onChange={(e) => setServiceType(e.target.value)}
                      style={{ cursor: "pointer" }}
                    />
                    <Badge color="info">Time Sheet</Badge>
                  </Label>
                </FormGroup>
              </Col>

              <Col>
                <div className="d-flex flex-column align-items-end">
                  <Button
                    color="success m-1 btn-sm"
                    style={{ width: "70px" }}
                    // onClick={handleSearch}
                    onClick={() => {
                      setSearch(!search);
                    }}
                  >
                    Search
                  </Button>
                  <Button
                    color="danger m-1 btn-sm"
                    onClick={reset}
                    style={{ width: "70px" }}
                  >
                    Reset
                  </Button>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Collapse>
      {error && (
        <div>
          <NoConnection error={error}></NoConnection>
        </div>
      )}
      {rows && !loading ? (
        filterByName(searchQuery, rows).lengthofSearch > 0 ? (
          filterByName(
            searchQuery,
            rows.sort(sortFunctionAscending)
          ).filtered.map((row, i) => {
            return (
              <TransactionWidgetFilter
                key={i}
                userProfile={row}
                reset={() => setResetState(!resetState)}
              ></TransactionWidgetFilter>
            );
          })
        ) : (
          <NoSearchResults />
        )
      ) : null}
      {loading && !error && (
        <div className="d-flex justify-content-center align-items-center">
          <div>
            <img src={loadingAnimation} style={{ height: 100 }}></img>
          </div>
        </div>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {})(Transaction);
