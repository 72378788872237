import React, { useEffect, useState } from "react";
import {
  Button,
  Label,
  Input,
  Form,
  FormGroup,
  Col,
  ModalBody,
  ModalHeader,
  Modal,
  Spinner,
} from "reactstrap";

import axios from "axios";
import moment from "moment";
import Select from "react-select";
import { connect } from "react-redux";
import { filter, findIndex, forEach, uniqBy } from "lodash";
import { useForm } from "react-hook-form";
import { useToasts } from "react-toast-notifications";
import ToastCommon from "../../toastCommon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import {
  ClientLable,
  HOSTMexxar,
  REGISTER_CANDIDATE,
} from "../../../configs/api-config";
import {
  bandsGetAction,
  rolesGetAction,
  specialitiesGetAction,
} from "../../../redux/actions/initialDataGetAction";
import store from "../../../redux/store";
import useDidMountEffect from "./intialRunUseEffect";
import DateInput from "../../../util/common/DateInput";
import dateFormatter from "../../../util/common/dateFormatter";

function TimeSheetUpload(props) {
  const isDataEdited = true;
  const MexxarAPI = HOSTMexxar;
  const MexxarCandidate = REGISTER_CANDIDATE;

  const { register, handleSubmit, errors } = useForm();
  const { data, closeModal, getCandidates, auth, specialities, roles, bands } =
    props;
  const { addToast } = useToasts();

  const [openModal, setOpenModal] = useState(false);
  const [inPreferredClientList, setInPreferredClientList] = useState(true);
  const [incomingData, setIncomingData] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [candidateClients, setCandidateClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState({});
  const [selectedDepartment, setSelectedDepartment] = useState(0);
  const [selectedBand, setSelectedBand] = useState(0);
  const [wardsInLikeClients, setWardsInLikeClients] = useState([]);

  const [selectedDocument, setSelectedDocument] = useState();
  const [candidateLikeWards, setCandidateLikeWards] = useState();
  const [candidateSpecialitiesCheck, setCandidateSpecialitiesCheck] = useState(
    []
  );

  const [selectedSpeciality, setSelectedSpeciality] = useState(0);

  const [modalPreviewPicture, setModalPreviewPicture] = useState();
  const [candidateDocumentUrl, setCandidateDocumentUrl] = useState();
  const [loading, setLoading] = useState(false);

  const [uploadedImageDocument, setUploadedImageDocument] = useState();
  const [reArrangedBands, setReArrangedBands] = useState([]);
  const [reArrangedRoles, setReArrangedRoles] = useState([]);
  const [reArrangedSpecialities, setReArrangedSpecialities] = useState([]);

  const [locumId, setLocumId] = useState(props?.candidatesId);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const onSubmit = (data) => {
    let timesheetReviewStatus = 0;

    if (checkPermissions("PAYROLE").length > 0 && selectedDocument != null) {
      timesheetReviewStatus = 1;
    } else {
      timesheetReviewStatus = 0;
    }

    let timesheet = {
      locumName: data.locumName,
      locumId: data.locumId,
      speciality: data.candidateSpecialitiesCheck,
      band: data.band,
      hospitalName: selectedClient?.value,
      dateOfShift: data.dateOfShift,
      ward: data.candidateLikeWardsSelect,
      bookingRef: data.RefNo,
      startTime: data.StartTime,
      endTime: data.endTime,
      minsTakenForBreaks: data.breakTime,
      totalHours: data.totalHours,
      locumDate: data.locumDate,
      // authoriseName: data.authoriseName,
      // authorisePosition: data.authorisePosition,
      // authoriseDate: data.authoriseDate,
      bookingId: incomingData.id,
      paymentStatus: 0,
      reviewStatus: timesheetReviewStatus,
      hospitalReviewStatus: 0,
      // earnings: 100,
    };

    axios.post(MexxarAPI + "timesheets/", timesheet).then((res) => {
      if (res.data.status == "success" || res.data.message == "created") {
        ToastCommon(true, "update", addToast);

        if (selectedDocument != null || selectedDocument != undefined) {
          axios
            .put(
              MexxarAPI +
                "timesheets/" +
                res.data.body[0].id +
                "/upload-timesheetDocument",
              selectedDocument
            )
            .then((res) => {
              if (
                res.data.status == "success" ||
                res.data.message == "created"
              ) {
                ToastCommon(true, "update", addToast);
                getCandidates();
                closeModal();
              } else {
                ToastCommon(true, "duplicate", addToast);
              }
            })
            .catch((err) => {});
        } else {
          getCandidates();
        }
      } else {
        ToastCommon(true, "duplicate", addToast);
      }
    });
  };

  const onChangePicture = (e) => {
    if (e.target.files[0]) {
      let form = new FormData();
      form.append("file", e.target.files[0]);
      setSelectedDocument(form);
    }
  };

  const checkPermissions = (permission) => {
    let array = filter(auth.item.userRole.permissions, (item) => {
      return item.name == permission;
    });
    return array;
  };

  const getCandidate = (data) => {
    setLoading(true);
    axios
      .get(MexxarAPI + "candidates/" + data?.candidatesId)
      .then((candidate) => {
        let hospitals = candidate.data?.body[0]?.preferredClients;
        setSelectedBand(candidate.data?.body?.[0]?.candidateBand?.id);
        if (hospitals !== undefined) {
          let candidateHospitals = [];
          for (let i = 0; i < hospitals.length; i++) {
            candidateHospitals.push({
              value: hospitals[i].name,
              label: hospitals[i].name,
              id: hospitals[i].id,
              // candidate: candidate,
            });
          }

          setCandidateClients(candidateHospitals);

          let clientId = filter(candidateHospitals, (item) => {
            if (item.id === data?.clientId) {
              setInPreferredClientList(false);
            }
            return data?.clientId;
          });
          setSelectedClient(clientId[0]);
        }

        let likeWards = candidate.data.body[0].likeWards;
        if (likeWards !== undefined) {
          let candidateLikeWards = [];
          for (let i = 0; i < likeWards.length; i++) {
            if (likeWards[i] !== undefined) {
              candidateLikeWards.push({
                value: likeWards[i].wardName,
                label: likeWards[i].wardName,
                id: likeWards[i].id,
                candidate: candidate,
              });
            }
          }
          setCandidateLikeWards(candidateLikeWards);
        }

        let candidateSpecialities =
          candidate.data.body[0].candidateSpecialities;
        if (candidateSpecialities !== undefined) {
          let candidateSpecialitiesCheck = [];
          for (let i = 0; i < candidateSpecialities.length; i++) {
            candidateSpecialitiesCheck.push({
              value: candidateSpecialities[i].name,
              label: candidateSpecialities[i].name,
              id: candidateSpecialities[i].id,
            });
          }
          setCandidateSpecialitiesCheck(candidateSpecialitiesCheck);
        }

        let id = candidate.data.body[0].id;
        if (id !== undefined) {
          setLocumId(id);
          let candidateIdCheck = [];
          for (let i = 0; i < id.length; i++) {
            candidateIdCheck.push({
              value: id,
              label: id,
              id: id,
              candidate: candidate,
            });
          }
        }

        setCandidateDocumentUrl(candidate.data.body[0].documentUrl);
        setLoading(false);
      });
  };

  const loadModalPreviewPicture = (id) => {
    if (incomingData != undefined) {
      let url = String(
        MexxarAPI +
          "timesheet-doc/candidates/" +
          id +
          "/" +
          uploadedImageDocument
      );
      const config = { responseType: "blob" };
      axios.get(url, config).then((res) => {
        getBase64(new File([res.data], "sample"), (result) => {
          setModalPreviewPicture(result);
          setOpenModal(true);
        });
      });
    }
  };

  function getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {};
  }

  useDidMountEffect(() => {
    if (data && candidateSpecialitiesCheck) {
      let index = findIndex(candidateSpecialitiesCheck, (item) => {
        return item.label === data.speciality?.name;
      });

      if (index === -1) {
        setSelectedSpeciality(-1);
      } else {
        setSelectedSpeciality(index);
      }
    }
  }, [candidateSpecialitiesCheck]);

  useEffect(() => {
    if (data) {
      setIncomingData(data);
      getCandidate(data);
    }
    // loadModalPreviewPicture(data.candidatesId);
  }, []);

  useEffect(() => {
    store.dispatch(specialitiesGetAction());
    store.dispatch(rolesGetAction());
    store.dispatch(bandsGetAction());

    return () => {};
  }, []);

  const rearrangeSpecialities = () => {
    let specialitiesTemp = [];
    specialities.forEach((item) => {
      specialitiesTemp.push({
        value: item.name,
        label: item.name + `${item?.type == "DATABASE" ? "-DB" : ""}`,
        id: item.id,
      });
    });
    setReArrangedSpecialities(specialitiesTemp);
  };
  const rearrangeRoles = () => {
    let rolesTemp = [];
    roles.forEach((item) => {
      rolesTemp.push({
        value: item.name,
        label: item.name,
        id: item.id,
      });
    });
    setReArrangedRoles(rolesTemp);
  };
  const rearrangeBands = () => {
    let bandsTemp = [];
    bands.forEach((item) => {
      bandsTemp.push({
        value: item.name,
        label: item.name,
        id: item.id,
      });
    });
    setReArrangedBands(bandsTemp);
  };

  useEffect(() => {
    if (specialities) {
      rearrangeSpecialities();
    }
    return () => {};
  }, [specialities]);

  useEffect(() => {
    if (roles) {
      rearrangeRoles();
    }
    return () => {};
  }, [roles]);

  useEffect(() => {
    if (bands) {
      rearrangeBands();
    }
    return () => {};
  }, [bands]);

  const getClientWards = () => {
    let ClientsWard = [];
    axios
      .get(HOSTMexxar + "client-wards/clients/" + selectedClient?.id)
      .then((res) => {
        if (res.data.status == "success") {
          let response = res.data.body;
          forEach(response, function (item) {
            ClientsWard.push({
              value: item.wardName,
              label: item.wardName,
              id: item.id,
            });
          });
          setWardsInLikeClients(uniqBy(ClientsWard, "id"));

          let wardId = filter(response, (item) => {
            return item.wardId === data?.ward?.id;
          });
          setSelectedDepartment(wardId[0].id);
        } else {
          // ToastCommon(true, "duplicate", addToast);
        }
      })
      .catch((err) => {
        console.log(err);
        if (!(err instanceof TypeError)) {
          ToastCommon(true, "error", addToast);
        }
      });
  };
  useDidMountEffect(() => {
    getClientWards();
  }, [selectedClient]);

  return (
    <>
      {loading ? (
        <>
          {" "}
          Loading ...{" "}
          <Spinner
            className="mx-2  "
            style={{
              height: "20px",
              width: "20px",
              marginRight: "5px",
            }}
            loading={loading}
          />
        </>
      ) : (
        <>
          <Form onSubmit={handleSubmit(onSubmit)}>
            {}
            <FormGroup row>
              {}
              <Label sm={2} md={2}>
                Ref
              </Label>
              <Col sm={9} md={9}>
                <Input
                  type="text"
                  placeholder="Ref No"
                  name="RefNo"
                  id="RefNoId"
                  defaultValue={
                    incomingData == undefined || incomingData == null
                      ? ""
                      : String(incomingData.referenceNo)
                  }
                  innerRef={register({ required: true })}
                />
              </Col>
              <Col sm={1} md={1}>
                {isDataEdited ? (
                  <FontAwesomeIcon icon={faExclamationCircle} color="danger" />
                ) : null}
              </Col>
            </FormGroup>

            <FormGroup row>
              {}
              <Label sm={2} md={2}>
                Locum Name
              </Label>
              <Col sm={9} md={9}>
                <Input
                  type="text"
                  placeholder="Locum Name"
                  name="locumName"
                  id="locumNameId"
                  innerRef={register({ required: true })}
                  defaultValue={
                    incomingData == undefined || incomingData == null
                      ? ""
                      : String(incomingData.candidateName)
                  }
                />
              </Col>
              <Col sm={1} md={1}>
                {isDataEdited ? (
                  <FontAwesomeIcon icon={faExclamationCircle} color="danger" />
                ) : null}
              </Col>
            </FormGroup>

            <FormGroup row>
              {}
              <Label sm={2} md={2}>
                Locum ID
              </Label>
              <Col sm={9} md={9}>
                <Input
                  type="text"
                  placeholder="Locum Id"
                  name="locumId"
                  readOnly
                  id="locumId"
                  value={locumId}
                  innerRef={register({ required: true })}
                />
              </Col>
              <Col sm={1} md={1}>
                {isDataEdited ? (
                  <FontAwesomeIcon icon={faExclamationCircle} color="danger" />
                ) : null}
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label sm={2} md={2}>
                Speciality
              </Label>
              <Col sm={9} md={9}>
                <Input
                  type="select"
                  name="candidateSpecialitiesCheck"
                  innerRef={register({ required: true })}
                  value={selectedSpeciality}
                  onChange={(e) => setSelectedSpeciality(e.target.value)}
                >
                  <>
                    {candidateSpecialitiesCheck.map((item, index) => {
                      return (
                        <option
                          key={index}
                          value={index}
                          style={{ color: "#6A6767" }}
                        >
                          {item.label}
                        </option>
                      );
                    })}
                  </>
                </Input>
                {!candidateSpecialitiesCheck.some(
                  (item) => item.id === incomingData?.speciality?.id
                ) ? (
                  <ul
                    className="list-unstyled text-sm mt-1 text-muted filled"
                    id="parsley-id-119"
                  >
                    <li className="parsley-required text-danger">
                      This specialty has been removed or not assigned to the
                      candidate. Please assign it first.
                    </li>
                  </ul>
                ) : (
                  <></>
                )}
              </Col>

              <Col sm={1} md={1}>
                {isDataEdited ? (
                  <FontAwesomeIcon icon={faExclamationCircle} color="danger" />
                ) : null}
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label sm={2} md={2}>
                Band
              </Label>
              <Col sm={9} md={9}>
                <Input
                  type="select"
                  name="band"
                  value={selectedBand}
                  onChange={setSelectedBand}
                  innerRef={register({ required: true })}
                >
                  {bands === undefined || bands === null ? (
                    ""
                  ) : (
                    <>
                      {bands.map((band, id) => {
                        return (
                          <option value={band.id} key={band.id}>
                            {band.name}
                          </option>
                        );
                      })}
                    </>
                  )}
                </Input>
              </Col>
              <Col sm={1} md={1}>
                {isDataEdited ? (
                  <FontAwesomeIcon icon={faExclamationCircle} color="danger" />
                ) : null}
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label sm={2}>{ClientLable}</Label>
              <Col sm={9}>
                <Select
                  defaultValue={selectedClient}
                  // isMulti
                  isDisabled={true}
                  name="clientId"
                  className="basic-multi-select  "
                  classNamePrefix="select"
                  onChange={(data) => setSelectedClient(data)}
                  options={candidateClients}
                  innerRef={register({
                    required: {
                      value: true,
                      message: "This field is required!",
                    },
                  })}
                />
                {!inPreferredClientList ? (
                  <ul
                    className="list-unstyled text-sm mt-1 text-muted filled"
                    id="parsley-id-119"
                  >
                    <li className="parsley-required text-danger">
                      Warning! Client is not in the candidates preferred
                      hospitals list.
                    </li>
                  </ul>
                ) : (
                  <></>
                )}
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm={2} md={2}>
                Department
              </Label>
              <Col sm={9} md={9}>
                <Input
                  type="select"
                  name="candidateLikeWardsSelect"
                  value={selectedDepartment}
                  onChange={(data) => setSelectedDepartment(data.target.value)}
                  innerRef={register({ required: true })}
                >
                  <>
                    {wardsInLikeClients.map((item, id) => {
                      return (
                        <option value={item.id} key={id}>
                          {item.value}
                        </option>
                      );
                    })}
                  </>
                </Input>
              </Col>
              <Col sm={1} md={1}>
                {isDataEdited ? (
                  <FontAwesomeIcon icon={faExclamationCircle} color="danger" />
                ) : null}
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label sm={2} md={2}>
                Date Of Shift
              </Label>
              <Col sm={9} md={9}>
                {/* <Input
                  type="date"
                  readOnly
                  name="dateOfShift"
                  innerRef={register({ required: true })}
                  defaultValue={moment(incomingData.bookingDate).format(
                    "YYYY-MM-DD"
                  )}
                ></Input> */}
                   <DateInput
                id="date"
                invalid={false} 
                defaultValue={dateFormatter.formatDateToDDMMYYYY(incomingData.bookingDate)}
                // value={event.body.EventStartDate} 
                readOnly={true}
               value={dateFormatter.formatDateToDDMMYYYY(incomingData.bookingDate)}
          
              />
              </Col>
              <Col sm={1} md={1}>
                {isDataEdited ? (
                  <FontAwesomeIcon icon={faExclamationCircle} color="danger" />
                ) : null}
              </Col>
            </FormGroup>
            <FormGroup row hidden>
              <Label sm={2}>Locum Date</Label>
              <Col sm={9}>
                <Input
                  readOnly
                  type="date"
                  placeholder="Locum Date"
                  name="locumDate"
                  defaultValue={moment(incomingData.bookingDate).format(
                    "YYYY-MM-DD"
                  )}
                  innerRef={register()}
                />
              </Col>
              <Col sm={1} md={1} className="d-flex align-items-center">
                {isDataEdited ? (
                  <FontAwesomeIcon icon={faExclamationCircle} color="danger" />
                ) : null}
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label sm={2} md={2}>
                From <br />
                (24 HR Format)
              </Label>
              <Col sm={3}>
                <Input
                  type="time"
                  placeholder="Start Time"
                  name="StartTime"
                  innerRef={register({ required: true })}
                  defaultValue={
                    incomingData?.shiftFrom
                      ? moment(incomingData.shiftFrom).format("HH:mm")
                      : ""
                  }
                />
              </Col>
              {isDataEdited && (
                <Col sm={1} md={1} className="d-flex align-items-center">
                  <FontAwesomeIcon icon={faExclamationCircle} color="danger" />
                </Col>
              )}

              <Label sm={2} md={2}>
                To
              </Label>
              <Col sm={3}>
                <Input
                  type="time"
                  placeholder="End Time"
                  name="endTime"
                  innerRef={register({ required: true })}
                  defaultValue={
                    incomingData?.shiftTo
                      ? moment(incomingData.shiftTo).format("HH:mm")
                      : ""
                  }
                />
              </Col>
              {isDataEdited && (
                <Col sm={1} md={1} className="d-flex align-items-center">
                  <FontAwesomeIcon icon={faExclamationCircle} color="danger" />
                </Col>
              )}
            </FormGroup>

            <FormGroup row>
              <Label sm={2}>Break Mins</Label>
              <Col sm={9}>
                <Input
                  type="text"
                  placeholder="Break Time"
                  name="breakTime"
                  defaultValue={incomingData?.breakTime}
                  innerRef={register()}
                />
              </Col>
              <Col sm={1} md={1} className="d-flex align-items-center">
                {isDataEdited ? (
                  <FontAwesomeIcon icon={faExclamationCircle} color="danger" />
                ) : null}
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label sm={2}>Total Hours</Label>
              <Col sm={9}>
                <Input
                  type="text"
                  placeholder="Total Hours"
                  name="totalHours"
                  defaultValue={incomingData?.totalHours}
                  innerRef={register()}
                />
              </Col>
              <Col sm={1} md={1} className="d-flex align-items-center">
                {isDataEdited ? (
                  <FontAwesomeIcon icon={faExclamationCircle} color="danger" />
                ) : null}
              </Col>
            </FormGroup>

            {/* <FormGroup row>
          <Label sm={2}>Authorise Name</Label>
          <Col sm={9}>
            <Input
              type="text"
              placeholder="Authorise Name"
              name="authoriseName"
              innerRef={register()}
            />
          </Col>
          <Col sm={1} md={1} className="d-flex align-items-center">
            {isDataEdited ? (
              <FontAwesomeIcon icon={faExclamationCircle} color="danger" />
            ) : null}
          </Col>
        </FormGroup> */}
            {/* 
        <FormGroup row>
          <Label sm={2}>Authorise Position</Label>
          <Col sm={9}>
            <Input
              type="text"
              placeholder="Authorise Position"
              name="authorisePosition"
              innerRef={register()}
            />
          </Col>
          <Col sm={1} md={1} className="d-flex align-items-center">
            {isDataEdited ? (
              <FontAwesomeIcon icon={faExclamationCircle} color="danger" />
            ) : null}
          </Col>
        </FormGroup> */}

            {/* <FormGroup row>
          <Label sm={2}>Authorise Date</Label>
          <Col sm={9}>
            <Input
              type="date"
              placeholder="Authorise Date"
              name="authoriseDate"
              innerRef={register()}
            />
          </Col>
          <Col sm={1} md={1} className="d-flex align-items-center">
            {isDataEdited ? (
              <FontAwesomeIcon icon={faExclamationCircle} color="danger" />
            ) : null}
          </Col>
        </FormGroup> */}

            {/* <FormGroup row>
     <Label sm={3}>Time Sheet</Label>
     <Col sm={8}>
     <h5><Badge href="#" color="primary">Manual</Badge>{'  '}</h5> */}
            {/* <Badge href="#" color="primary">Review</Badge></h5> */}
            {/* </Col>
        </FormGroup> */}
            <FormGroup row>
              <Label sm={4}>Image</Label>
              <Col sm={8}>
                {/* <img src="https://mdbootstrap.com/img/Others/documentation/img%20(75)-mini.jpg" alt="thumbnail" className="img-thumbnail" /> */}

                {/* <Label sm={4}>{uploadedImageDocument}</Label> */}
                {uploadedImageDocument == null ||
                uploadedImageDocument == undefined ? (
                  <>
                    <Input
                      type="file"
                      id="fileId"
                      onChange={onChangePicture}
                      name="file"
                      innerRef={register({ required: "File is required" })}
                    />
                    {errors.file && (
                      <span className="text-danger">{errors.file.message}</span>
                    )}
                  </>
                ) : (
                  <>
                    <Button onClick={loadModalPreviewPicture}>
                      View Uploaded File
                    </Button>
                    <Label sm={12} for="file">
                      Or Reupload
                    </Label>
                    <Input
                      type="file"
                      id="fileId"
                      onChange={onChangePicture}
                      name="file"
                    />
                  </>
                )}
              </Col>
              <Col sm={8}>{/* <img src={modalPreviewPicture} sm={4}/> */}</Col>
            </FormGroup>
            <div className="  d-flex justify-content-end">
              <Button color="success" type="submit" className="mx-2">
                Upload
              </Button>

              <Button
                color="secondary"
                className="mr-3"
                onClick={() => closeModal()}
              >
                Close
              </Button>
            </div>
          </Form>
          <Modal
            isOpen={openModal}
            scrollable={false}
            className="modal-dialog modal-lg"
          >
            <Form>
              {"Text" && (
                <ModalHeader className="modal-header bg-primary">
                  <span style={{ color: "#ffffff" }}>{"Document Preview"}</span>
                </ModalHeader>
              )}
              <ModalBody className="p-4 ">
                <div className="d-flex justify-content-center align-items-center flex-column">
                  <div>
                    <label for="file-input">
                      <img
                        src={modalPreviewPicture}
                        width={"700px"}
                        height={"700px"}
                      ></img>
                    </label>
                  </div>
                  <Button
                    color="secondary"
                    onClick={() => {
                      setOpenModal(false);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </ModalBody>
            </Form>
          </Modal>
        </>
      )}
    </>
  );
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    specialities: state.initialDataGetReducer?.specialities,
    roles: state.initialDataGetReducer?.roles,
    bands: state.initialDataGetReducer?.bands,
  };
}

export default connect(mapStateToProps, {})(TimeSheetUpload);
