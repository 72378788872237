import { faPhone } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, Input, Label, Modal, Row } from "reactstrap";
import RoundIconButton from "../../RoundIconButton/round-icon-button";
import { UncontrolledTooltip } from "reactstrap/lib";
import { connect, useDispatch } from "react-redux";
import { HOSTMexxar } from "../../../configs/api-config";
import axios from "axios";
import { useForm } from "react-hook-form";
import ToastCommon from "../../toastCommon";


const OnboardingCallDelete = (props) => {
  // const { register, handleSubmit, errors } = useForm();
  const {
    onCreate,
    closeModal,
    mode,
    modalType,
    modalType1,
    modalType2,
    fieldData,
    rowNameData,
    rowNameDay,
    custom,
    rowID,
    lanndLine,
    candidatePhoneNo,
    secondary
  } = props;
  var HorizonApplicationId = custom?.customHeaders?.horizonApplicationID;
  //   const [speciality, setSpeciality] = useState([]);
  //   const [trainingProviders, setTrainingProviders] = useState([]);
  const [addKillLog, setaddKillLog] = useState(false);
  const [isCalling, setIsCalling] = useState(false);
  const [phoneNumberType, setPhoneNumberType] = useState("Primary");

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [datecount, setDateCount] = useState(false);


  const { register, handleSubmit, errors } = useForm();





  console.log("rowID", onCreate)
  const headers = new Headers();
  headers.append("ApplicationId", HorizonApplicationId);
  headers.append("Content-Type", "application/x-www-form-urlencoded");

  const handleOnClick = async () => {
    if (isCalling) {
      // ... (existing code)
      setIsCalling(false);
      // Uncomment your fetch request for Hangup if necessary
    } else {
      setIsCalling(true);

      console.log("phoneNumberType:", phoneNumberType);
      console.log("candidatePhoneNo:", candidatePhoneNo);
      console.log("secondary:", secondary);
      console.log("lanndLine:", lanndLine);
      const params = new URLSearchParams();
      if (phoneNumberType === "Primary") {
        params.append("destination", candidatePhoneNo);
      }
      if (phoneNumberType === "Secondary") {
        params.append("destination", secondary);
      }
      if (phoneNumberType === "Land") {
        params.append("destination", lanndLine);
      }

      const requestOptions = {
        method: "POST",
        headers: headers,
        body: params,
      };

      try {
        const response = await fetch(
          "https://localhost:10443/api/v1/MakeCall",
          requestOptions
        );
        const data = await response.json();

        console.log(data.UniqueId); // Ensure this prints the correct value

        // Update state within the scope of the async function
        // setCallId(data.UniqueId);
        localStorage.setItem("callId", data.UniqueId);
        // setCallBackShow(false);
      } catch (error) {
        setIsCalling(false);
        console.error("Error Call Hangup:", error);
      }
    }
  };


  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const displayPhoneNumber = (phoneNumber) => {
    if (!phoneNumber || phoneNumber.trim() === "") {
      return "N/A";
    }
    return phoneNumber;
  };
  const onSubmit = (data) => {
    console.log(data)
    setaddKillLog(true);

    // if (specialityShow === true) {
    //   let specilityIDArray = [];
    //   forEach(selectedSpecialities, function (item) {
    //     specilityIDArray.push(item.id);
    //   });

    let arrangedData = {
      rowName: data.rowName,
      rowDate: data.rowDate,
      rowDay: data.rowDay,
    };
    onCreate(arrangedData);

  };
  const handlePhoneNumberType = (type) => {
    setPhoneNumberType(type);
    console.log("mobile type", type);
    // Additional logic can be added here if needed
  };


  const DeleteOnboarding = () => {

    axios
      .delete(`${HOSTMexxar}onboarding/deleteByDateDifference?dates=${datecount}`, {
        // onboardingStatus: status,
        // receiverId: rowID,
        // callDate: moment(new Date()).toISOString(),
        // rescheduleDate: rescheduleDate,
      })
      .then((res) => {
        //  setCandidateId(res.data.body);

        // ToastCommon(true, "success", addToast);

        // setCalendarShow(!calendarShow);
      })
      .catch((err) => {
        //console.log("erorrrrrrrrrrrrrrrr", err);
        // ToastCommon(true, "error", addToast);
      });
  };




  return (
    <>
      <div className="p-3">
        <p className="font-weight-bold">Confirm Delete</p>
        <p>Are you sure you want to delete this item?</p>

      </div>
      <Form >
        <FormGroup row>
          <Label sm={4}>{modalType}</Label>
          <Col sm={8}>
            <Input
              type="text"
              name="rowName"
              className="capitalize"
              // defaultValue={fieldData}
              onChange={(e) => setDateCount(e.target.value)}
              invalid={errors.bName}
              innerRef={register({
                required: {
                  value: true,
                  message: "This field is required!",
                },
                maxLength: {
                  value: 150,
                  message: "This field only allowed only 150 characters",
                },
              })}
            ></Input>
          </Col>

        </FormGroup>

        {/* <FormGroup row>
              <Label sm={4}>{modalType1}</Label>
              <Col sm={8}>
                <Input
                  type="date"
                  name="rowDate"
                  className="capitalize"
                  defaultValue={rowNameData?.format("YYYY-MM-DD")}
                  invalid={errors.rName}
                  innerRef={register({
                    required: {
                      value: true,
                      message: "This field is required!",
                    },
                    maxLength: {
                      value: 150,
                      message: "This field only allowed only 150 characters",
                    },
                  })}
                ></Input>
              </Col>
            </FormGroup> */}



        <Row className="justify-content-end">
          {/* <div className="d-flex ">
            {" "}
            <Button
              className="mr-2"
              color="success"
              type="submit"
              disabled={addKillLog}
            >
              {mode}
            </Button>{" "}
            <Button color="secondary" onClick={() => closeModal()}>
              Close
            </Button>
          </div> */}


          <Alert color="danger">
            Caution:When you delete this document all the associated
            applications with this document will be permantly deleted
          </Alert>

          <div className="float-right">
            <Button color="success" className="mr-1" onClick={DeleteOnboarding}>
              Yes
            </Button>
            <Button color="secondary" onClick={() => closeModal()}>
              No
            </Button>
          </div>
        </Row>
      </Form>
    </>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    callState: state.websocketData?.Payload,
    reason1: state?.callSummaryReducer,
    custom: state.customHeaderReducer,
    // callState: state.websocketReducer.websocketData
    //   ? state.websocketReducer.websocketData.Payload.Calls[0].CallState
    //   : '',
  };
}

export default connect(mapStateToProps, {})(OnboardingCallDelete);
