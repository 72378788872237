import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Alert,
  Input,
} from "reactstrap";

import {
  faEdit,
  faCheck,
  faChevronCircleRight,
} from "@fortawesome/free-solid-svg-icons";

import axios from "axios";
import { forEach, filter } from "lodash";
import { useForm } from "react-hook-form";
import { useToasts } from "react-toast-notifications";
import ToastCommon from "../../../../../components/toastCommon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { REGISTER_CANDIDATE } from "../../../../../configs/api-config";
import useDidMountEffect from "../../../../../components/Modal/ModalBody/intialRunUseEffect";
import DateInput from "../../../../../util/common/DateInput";
import dateFormatter from "../../../../../util/common/dateFormatter";

const General = (props) => {
  const MexxarApi = REGISTER_CANDIDATE;

  const { addToast } = useToasts();

  const [addMore, setAddMore] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [editable, setEditable] = useState(false);
  const [candidateID, setCandidateID] = useState([]);
  const [yesChecked, setYesChecked] = useState(false);
  const [basicDetails, setBasicDetails] = useState([]);
  const { register, handleSubmit, errors } = useForm();

  const getCandidateID = () => {
    let pathArray = window.location.pathname.split("/");
    const CandidateId = Number(pathArray[3]);
    //**********CandidateId and candidateID have same value but variable names are different*******/
    setCandidateID(CandidateId);
  };
  const filterData = (data, identifier) => {
    let data_List = [];

    for (let i = 0; i < 10; i++) {
      if (!data[identifier + i]) {
        break;
      }

      let id = data[identifier + "ID" + i];
      let value = data[identifier + i];

      data_List.push({
        id: Number(id),
        identifier: identifier + i,
        value: value,
        detailCategory: 0,
      });
    }
    return data_List;
  };

  const onSubmit = (data) => {
    let identifier1 = "ADM_PAddress";
    let identifier2 = "ADM_PPostCode";
    let identifier3 = "ADM_RFrom";
    let identifier4 = "ADM_RTo";
    let identifier5 = "dateOfResidentUK";
    let identifier6 = "dateOfCurrentAddress";

    let A = filterData(data, identifier1);
    let B = filterData(data, identifier2);
    let C = filterData(data, identifier3);
    let D = filterData(data, identifier4);
    let E = filterData(data, identifier5);
    let F = filterData(data, identifier6);

    let updatingArray = A.concat(B, C, D, E, F);

    let AdvanceDetailsArray = [];

    //check if this is update form filling or new form filling
    if (Object.keys(basicDetails).length == 0) {
      forEach(data, function (value, key) {
        if (value === "") {
        } else {
          AdvanceDetailsArray.push({
            identifier: key,
            value: value,
            detailCategory: 0,
          });
        }
      });
    } else {
      AdvanceDetailsArray = updatingArray;
    }

    axios
      .post(
        MexxarApi + "/" + candidateID + "/advance-profile-details",
        AdvanceDetailsArray
      )
      .then((res) => {
        if (res.data.status === "success") {
          getData();
          // setShowAddRow(!showAddRow);
          ToastCommon(true, "success", addToast);
        } else {
          ToastCommon(true, "error", addToast);
        }
      })
      .catch((err) => {
        //console.log(err);
        ToastCommon(true, "error", addToast);
      });
  };

  const getData = () => {
    axios
      .get(
        MexxarApi +
          "/" +
          candidateID +
          "/advance-profile-details/category/GENERAL"
      )
      .then((res) => {
        let list = res.data.body;

        let A = filter(list, (item) => {
          return item.identifier.includes("ADM_PAddress");
        });
        //  console.log("A .........", A);
        let B = filter(list, (item) => {
          return item.identifier.includes("ADM_PPostCode");
        });
        //  console.log("B .........", B);
        let C = filter(list, (item) => {
          return item.identifier.includes("ADM_RFrom");
        });

        //  console.log("C .........", C);
        let D = filter(list, (item) => {
          return item.identifier.includes("ADM_RTo");
        });
        // console.log(" D.........", D[0].id);
        let addressList = [];

        for (let i = 0; i < A.length; i++) {
          // console.log("test list A", A[i].id);

          addressList.push({
            PAddress_ID: A[i].id,
            PPostcode_ID: B[i].id,
            RFrom_ID: C[i].id,
            RTo_ID: D[i].id,
            PAddress: A[i].value,
            PPostcode: B[i].value,
            RFrom: C[i].value,
            RTo: D[i].value,
          });
        }

        setAddMore(addressList);

        let dateOfResidentUK = filter(list, (item) => {
          return item.identifier === "dateOfResidentUK0";
        });

        let dateOfCurrentAddress = filter(list, (item) => {
          return item.identifier === "dateOfCurrentAddress0";
        });

        let basicData = [];
        basicData.push({
          dateOfResidentUKID: dateOfResidentUK[0].id,
          dateOfResidentUK:dateOfResidentUK[0].value,
          dateOfCurrentAddressID: dateOfCurrentAddress[0].id,
          dateOfCurrentAddress: dateOfCurrentAddress[0].value,
        });

        setBasicDetails(basicData[0]);
      })
      .catch((err) => {
        //   console.log(err);
        // toastCommon(true, "error");
      });
  };

  const handleEdit = () => {
    if (editable) {
      //edit call
    }
    setEditable(!editable);
  };
  const removeMTAddMore = () => {
    addMore.pop(addMore);

    setAddMore([...addMore]);
    //remove all addmore fields at once
  };
  let filtered = addMore.filter((item) => Object.keys(item).length === 0);

  useEffect(() => {
    getCandidateID();
    return () => {};
  }, []);

  useDidMountEffect(() => {
    getData();
    return () => {};
  }, [candidateID]);

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card">
          <div className="card-header">
            <div className="col-md-12 d-flex justify-content-between align-items-center">
              <h5>General Information</h5>
              <FormGroup row>
                {/* <button
                  className={`btn btn-wave btn-raised ${
                    editable ? "bg-warning " : "bg-danger "
                  }`}
                  type="button"
                  onClick={() => handleEdit()}
                >
                  {editable ? "Save" : "Edit"}
                  {editable ? (
                    <FontAwesomeIcon icon={faCheck} className="ml-3" />
                  ) : (
                    <FontAwesomeIcon icon={faEdit} className="ml-3" />
                  )}
                </button> */}
              </FormGroup>
            </div>
          </div>
          <div className="card-body">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <FormGroup row>
                <Col sm={12} md={6}>
                  <Label>Date of Residence in UK / UK Birth</Label>
                  <DateInput
                    id="dateOfResidentUK0"
                    defaultValue={
                      basicDetails.dateOfResidentUK
                    }
                    value={
                      basicDetails.dateOfResidentUK
                    }
                    register={register}
                    onChange={(formattedDate) =>
                      console.log(
                        "Selected Date of Residence in UK:",
                        formattedDate
                      )
                    }
                  />
                  <Input
                    type="hidden"
                    name="dateOfResidentUKID0"
                    defaultValue={basicDetails.dateOfResidentUKID}
                    innerRef={register}
                  />
                </Col>

                <Col sm={12} md={6}>
                  <Label>Resident at Current Address Since</Label>
                  <DateInput
                    id="dateOfCurrentAddress0"
                    defaultValue={
                      basicDetails.dateOfCurrentAddress
                    }
                    value={
                      basicDetails.dateOfCurrentAddress
                    }
                    register={register}
                    onChange={(formattedDate) =>
                      console.log(
                        "Selected Date of Current Address:",
                        formattedDate
                      )
                    }
                  />
                  <Input
                    type="hidden"
                    name="dateOfCurrentAddressID0"
                    defaultValue={basicDetails.dateOfCurrentAddressID}
                    innerRef={register}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col md={12} sm={12}>
                  <Label>Previous Addresses</Label>
                  <Alert color="info">
                    Please provide any previous addresses if you have been at
                    your current address for less than 5 years.
                  </Alert>
                </Col>
              </FormGroup>

              {addMore.map((data, id) => {
                return (
                  <Form onSubmit={handleSubmit(onSubmit)} key={id}>
                    <FormGroup row>
                      <Col sm={12} md={8}>
                        <Label>
                          Address (Previous){" "}
                          <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          innerRef={register({
                            required: {
                              value: true,
                              message: "This field is required!",
                            },
                          })}
                          name={"ADM_PAddress" + id}
                          defaultValue={data.PAddress}
                        />
                        <Input
                          type="hidden"
                          innerRef={register}
                          name={"ADM_PAddressID" + id}
                          defaultValue={data.PAddress_ID}
                        />
                      </Col>
                      <Col sm={12} md={4}>
                        <Label>Post Code</Label>
                        <Input
                          type="text"
                          innerRef={register({
                            required: {
                              value: true,
                              message: "This field is required!",
                            },
                          })}
                          name={"ADM_PPostCode" + id}
                          defaultValue={data.PPostcode}
                        />
                        <Input
                          type="hidden"
                          innerRef={register}
                          name={"ADM_PPostCodeID" + id}
                          defaultValue={data.PPostcode_ID}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Col sm={12} md={6}>
                        <Label>
                          Resident at this address from{" "}
                          <span className="text-danger">*</span>
                        </Label>
                        <DateInput
                          id={"ADM_RFrom" + id}
                          value={data.RFrom}
                          onChange={(formattedDate) =>
                            console.log("Selected Date From:", formattedDate)
                          }
                          register={register({
                            required: {
                              value: true,
                              message: "This field is required!",
                            },
                          })}
                        />
                        <Input
                          type="hidden"
                          innerRef={register}
                          name={"ADM_RFromID" + id}
                          defaultValue={data.RFrom_ID}
                        />
                      </Col>
                      <Col sm={12} md={6}>
                        <Label>
                          Resident at this address to{" "}
                          <span className="text-danger">*</span>
                        </Label>
                        <DateInput
                          id={"ADM_RTo" + id}
                          value={data.RTo} 
                          onChange={(formattedDate) =>
                            console.log("Selected Date To:", formattedDate)
                          }
                          register={register({
                            required: {
                              value: true,
                              message: "This field is required!",
                            },
                          })}
                        />
                        <Input
                          type="hidden"
                          innerRef={register}
                          name={"ADM_RToID" + id}
                          defaultValue={data.RTo_ID}
                        />
                      </Col>
                    </FormGroup>
                  </Form>
                );
              })}
              <Row>
                <Col>
                  <Button
                    color="success font-weight-bold mb-2"
                    style={{
                      borderRadius: "80px",
                      width: "40px",
                      height: "40px",
                    }}
                    onClick={() => setAddMore([...addMore, {}])}
                  >
                    +
                  </Button>
                  {filtered.length > 0 ? (
                    <Button
                      color="danger font-weight-bold mb-2 mx-2"
                      style={{
                        borderRadius: "80px",
                        width: "40px",
                        height: "40px",
                      }}
                      onClick={() => removeMTAddMore()}
                    >
                      -
                    </Button>
                  ) : null}
                </Col>
              </Row>
              <div className="d-flex justify-content-between">
                <div></div>
                <div>
                  <Button className="mr-2" color="success" type="submit">
                    Save
                  </Button>
                  {/* <Button color="success" onClick={() => {}}>
                    Next
                  </Button> */}
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default General;
