import React, { useState, useEffect } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import ToastCommon from "../../toastCommon";
import { useToasts } from "react-toast-notifications";
import {
  CLIENTS,
  ClientLable,
  FRAMEWORK,
  HOSTMexxar,
  TRUSTS,
} from "../../../configs/api-config";
import { Button, Label, Input, Form, FormGroup, Col, Row } from "reactstrap";
import useDidMountEffect from "./intialRunUseEffect";
import { connect } from "react-redux";
import { countiesGetAction } from "../../../redux/actions/initialDataGetAction";
import { forEach } from "lodash";
import store from "../../../redux/store";
import Select from "react-select";
import notNull from "../../../util/common/notNull";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronCircleDown,
  faChevronCircleUp,
  faChevronDown,
  faChevronUp,
  faEdit,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import Delete from "./delete";
import moment from "moment";
import UpdateHospitalNote from "./Admin/updateHospitalNote";
import ModalComponent from "../modalComponent";
import { UncontrolledTooltip } from "reactstrap/lib";

const HospitalNote = (props) => {
  const { closeModal, rowID, mode, success, counties, auth } = props;
  const MexxarApi = CLIENTS;
  const MexxarApiTrust = TRUSTS;
  const MexxarApiFramework = FRAMEWORK;

  const { addToast } = useToasts();

  const [trusts, setTrusts] = useState([]);
  const [county, setCounty] = useState([]);

  const [rowDatas, setRowDatas] = useState(null);

  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");

  const [frameworks, setFrameworks] = useState([]);
  const { register, handleSubmit, errors, reset } = useForm();
  const [contactPersons, setContactPersons] = useState([]);
  const [selectedTrustId, setSelectedTrustId] = useState(0);

  const [selectedCounty, setSelectedCounty] = useState({
    id: "",
    value: "",
    label: "",
  });

  const [selectedFrameworkId, setSelectedFrameworkId] = useState(0);

  const [selectedTrustIdError, setSelectedTrustIdError] = useState(false);
  const [selectedFrameworkError, setSelectedFrameworkError] = useState(false);
  const [selectedCountyError, setSelectedCountyError] = useState(false);
  const [errorPostCode, setErrorPostCode] = useState(false);
  const [showMoreAddressDetails, setShowMoreAddressDetails] = useState(false);
  const [isOpen, setIsOpen] = useState(false);



  const checkPostalCode = (postalCode) => {
    axios.get(HOSTMexxar + "postal-codes/code/" + postalCode).then((res) => {
      if (res.data.body[0]) {
        setErrorPostCode(false);
        setSelectedCounty(res.data.body[0]?.county);
        setLatitude(res.data.body[0]?.latitude);
        setLongitude(res.data.body[0]?.longitude);
      } else {
        setErrorPostCode(true);
        setSelectedCounty("");
        setLatitude("");
        setLongitude("");
      }
    });
  };

  const handleChange = (event) => {
    const { value } = event.target;

    const capitalizedNote = value.charAt(0).toUpperCase() + value.slice(1);
    setNewNote(capitalizedNote);
  };


  const onSubmit = (data, e) => {
    let phoneNumbers = [];

    for (let i = 0; i < 1000; i++) {
      if (!data["Cname" + i]) {
        break;
      }
      let name = data["Cname" + i];
      let phone = data["CPPhone" + i];

      phoneNumbers.push({
        name: name,
        phoneNumber: phone,
      });
    }

    let selectedTrustIdErrorTEMP = false;
    let selectedFrameworkErrorTEMP = false;
    let selectedCountyErrorTEMP = false;

    if (parseInt(data.trustId) == 0) {
      selectedTrustIdErrorTEMP = true;
      setSelectedTrustIdError(true);
    } else {
      selectedTrustIdErrorTEMP = false;
      setSelectedTrustIdError(false);
    }

    if (parseInt(data.frameworkId) == 0) {
      selectedFrameworkErrorTEMP = true;
      setSelectedFrameworkError(true);
    } else {
      selectedFrameworkErrorTEMP = false;
      setSelectedFrameworkError(false);
    }

    if (parseInt(data.county) == 0) {
      selectedCountyErrorTEMP = true;
      setSelectedCountyError(true);
    } else {
      selectedCountyErrorTEMP = false;
      setSelectedCountyError(false);
    }

    if (
      mode === "Add" &&
      !selectedTrustIdErrorTEMP &&
      !selectedFrameworkErrorTEMP &&
      !selectedCountyErrorTEMP
    ) {
      axios
        .post(MexxarApi, {
          name: data.name,
          email: data.email,
          primaryMobile: data.primaryMobile,
          secondaryMobile: data.secondaryMobile,
          houseNumber: data.houseNumber,
          streetName: data.streetName,
          city: data.city,
          state: data.county,
          postCode: data.postCode.toUpperCase(),
          frameworkId: parseInt(data.frameworkId),
          trustId: parseInt(data.trustId),
          latitude: data.latitude,
          longtitude: data.longitude,
          phoneNumbers: phoneNumbers,
        })
        .then((res) => {
          if (res.data.status == "success") {
            success();
            ToastCommon(true, "success", addToast);
            e.target.reset();
            setRowDatas(null);
          } else {
            ToastCommon(true, "error", addToast);
          }
        })
        .catch((err) => {
          //console.log(err)
          ToastCommon(true, "error", addToast);
        });
    } else if (
      mode === "Update" &&
      !selectedTrustIdErrorTEMP &&
      !selectedFrameworkErrorTEMP &&
      !selectedCountyErrorTEMP
    ) {
      axios
        .put(MexxarApi, {
          id: rowID,
          name: data.name,
          email: data.email,
          primaryMobile: data.primaryMobile,
          secondaryMobile: data.secondaryMobile,
          houseNumber: data.houseNumber,
          streetName: data.streetName,
          city: data.city,
          state: data.county,
          postCode: data.postCode.toUpperCase(),
          frameworkId: parseInt(data.frameworkId),
          trustId: parseInt(data.trustId),
          latitude: data.latitude,
          longtitude: data.longitude,
          phoneNumbers: phoneNumbers,
        })
        .then((res) => {
          if (res.data.status == "success") {
            success();
            ToastCommon(true, "success", addToast);
            e.target.reset();
            setRowDatas(null);
          } else {
            ToastCommon(true, "error", addToast);
          }
        })
        .catch((err) => {
          //console.log(err)
          ToastCommon(true, "error", addToast);
        });
    }
  };

  const geTRowData = () => {
    axios
      .get(MexxarApi + "/" + rowID)
      .then((res) => {
        setRowDatas(res.data.body[0]);
        setContactPersons(res.data.body[0].phoneNumbers);
      })
      .catch((err) => {
        //console.log(err)
      });
  };
  const getFrameworks = () => {
    axios
      .get(MexxarApiFramework)
      .then((res) => {
        setFrameworks(res.data.body);
      })
      .catch((err) => {
        //console.log(err)
      });
  };
  const getTrusts = () => {
    axios
      .get(MexxarApiTrust)
      .then((res) => {
        setTrusts(res.data.body);
      })
      .catch((err) => {
        //console.log(err)
      });
  };

  const removeMTAddMore = () => {
    contactPersons.pop(contactPersons);
    setContactPersons([...contactPersons]);
  };

  let filtered = contactPersons.filter(
    (item) => Object.keys(item).length === 0
  );

  const [notes, setNotes] = useState([]);
  const [newNote, setNewNote] = useState("");
  const [showDeleteRow, setShowDeleteRow] = useState(false);
  const [rowNoteID, setRowID] = useState("");
  const [showUpdateRow, setShowUpdateRow] = useState(false);
  const [rowvalue, setRowValue] = useState("");
  // const [reset, setReset] = useState(false);
  // const [rowID, setRowID] = useState(0);

  const [value, setValue] = useState("");
  const [status, setStatus] = useState("");
  const changeHandler = ({ target }) => {
    setValue(target.value);
  };
  const getNotes = () => {
    axios
      .get("https://permapi.mexxar.com/clientNote/client/" + rowID)
      .then((res) => {
        setNotes(res.data.body);
        console.log(res.data.body);
      })
      .catch((err) => {
        //console.log(err)
      });
  };
  const toggleList = () => {
    setIsOpen(!isOpen);
  };
  const deleteNote = () => {
    axios
      .delete("https://permapi.mexxar.com/clientNote/" + rowNoteID)
      .then((res) => {
        // getAllRows();
        getNotes();
        ToastCommon(true, "delete", addToast);
      })
      .catch((err) => {
        //console.log(err)
        //ToastCommon(true, "update", addToast);
      });
  };

  useEffect(() => {
    getNotes();
  }, []);

  const handleSubmit1 = () => {
    if (newNote.trim() === "") {
      ToastCommon(true, "error", addToast);
      return;
    }

    axios
      .post("https://permapi.mexxar.com/clientNote", {
        note: newNote,
        employee: auth.item?.id,
        clients: rowID,
      })
      .then((res) => {
        setStatus(res?.data?.status);
        if (res.data.status == "Success") {
          ToastCommon(true, "success", addToast);
          getNotes();
          setNewNote('');
        } else {
          ToastCommon(true, "error", addToast);
        }
      })
      .catch((err) => {
        ToastCommon(true, "error", addToast);
      });
  };

  useEffect(() => {
    getTrusts();
    getFrameworks();
    if (mode == "Update") {
      geTRowData();
    }
    return () => { };
  }, []);

  useDidMountEffect(() => {
    if (mode === "Update" && rowDatas) {
      setSelectedTrustId(rowDatas.trust.id);
      setSelectedFrameworkId(rowDatas.framework.id);
      setSelectedCounty(rowDatas.state);
      setLatitude(rowDatas?.postalCode?.latitude);
      setLongitude(rowDatas?.postalCode?.longitude);
    } else {
      setSelectedTrustId(0);
      setSelectedFrameworkId(0);
    }
    return () => { };
  }, [rowDatas]);

  const reArrangeToShowInSelectDropDown = (data) => {
    let reArranged = [];
    forEach(data, function (item) {
      reArranged.push({
        value: item,
        label: item,
        id: item,
      });
    });
    return reArranged;
  };

  useEffect(() => {
    store.dispatch(countiesGetAction());
    return () => { };
  }, []);

  useEffect(() => {
    if (counties) {
      setCounty(reArrangeToShowInSelectDropDown(counties));
    }
    return () => { };
  }, [counties]);
  return (
    <Form className="col-12" onSubmit={handleSubmit(onSubmit)}>

      {mode === "Update" && (
        <>

          <div className="row align-items-center justify-content-between">
            <div className="col-auto">
              <Label> Add note</Label>
            </div>
            <div className="col">
              <Input
                type="textarea"
                name="name"
                id="name"
                innerRef={register}
                value={newNote}
                onChange={handleChange}
              />
            </div>
            <div className="col-auto m-2">
              <Button
                color="success font-weight-bold mb-2"
                style={{ borderRadius: "50px", marginRight: "10px" }}
                onClick={handleSubmit1}
                disabled={!newNote.trim()}
              >
                Save
              </Button>

              {isOpen ? (
                <FontAwesomeIcon
                  icon={faChevronUp}
                  size={8}
                  onClick={toggleList}
                  className="pointer"
                  id="comments"
                />

              ) : (
                <FontAwesomeIcon
                  icon={faChevronDown}
                  size={8}
                  onClick={toggleList}
                  className="pointer"
                  id="comments"
                />
              )}

              <UncontrolledTooltip target="comments">
                Show {ClientLable} Notes
              </UncontrolledTooltip>
            </div>
          </div>

          <div>
            <div>
              {/* <Button onClick={toggleList}>
                
              </Button> */}
              {isOpen && (
                <div>
                  {notes.length > 0 ? (
                    notes
                      .sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate))
                      .map((note) => (
                        <div
                          key={note.id}
                          className="collection-item mx-3 mt-2"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div style={{ flex: 1 }}>
                            <span style={{ color: "black" }}>{note.note}</span>
                            <br />
                            <span style={{ fontSize: "small" }}>
                              {moment(note.createdDate).format("ddd MMM DD YYYY")}{" "}
                              {moment(note.createdDate).format("hh:mm a")}
                              <span style={{ backgroundColor: " #CCCCCC", padding: "2px 6px", borderRadius: "4px", marginLeft: "5px" }}>
                                {note?.employee?.usersName}
                              </span>
                            </span>
                          </div>

                          <div>
                            <FontAwesomeIcon
                              icon={faTrashAlt}
                              className="shadow-none fa-lg mb-2 mx-3 btn-raised btn-wave pointer"
                              size="1x"
                              onClick={() => {
                                setShowDeleteRow(true);
                                setRowID(note.id);
                              }}
                            />
                          </div>
                          <div>
                            <FontAwesomeIcon
                              icon={faEdit}
                              className="shadow-none fa-lg mb-2 mx-3 btn-raised btn-wave pointer"
                              onClick={() => {
                                setShowUpdateRow(true);
                                setRowID(note.id);
                                setRowValue(note.note);
                              }}
                              size="1x"
                            />
                          </div>
                        </div>
                      ))
                  ) : (
                    <div>No notes available.</div>
                  )}
                </div>
              )}

            </div>
          </div>
        </>
      )}


      <Row className="justify-content-end">
        <div className="d-flex ">
        
          <Button
            color="secondary"
            onClick={() => {
              closeModal();
              reset();
              setRowDatas(null);
            }}
          >
            Close
          </Button>
        </div>
      </Row>

      <Delete
        show={showDeleteRow}
        isConfirm={() => {
          deleteNote();
          setShowDeleteRow(!showDeleteRow);
        }}
        closeModal={() => setShowDeleteRow(!showDeleteRow)}
      />

      <ModalComponent
        show={showUpdateRow}
        header={`Update Note`}
        className={`modal-dialog modal-custom ${showUpdateRow ? "modal-dialog-centered" : ""
          }`}
        closeModal={() => setShowUpdateRow(false)}
      >
        <UpdateHospitalNote
          rowID={rowID}
          mode="Update"
          auth={auth?.item.id}
          rowvalue={rowvalue}
          rowNoteID={rowNoteID}
          action={getNotes}
      
          closeModal1={() => setShowUpdateRow(false)}
        />
      </ModalComponent>
    </Form>
  );
};

function mapStateToProps(state) {
  return {
    counties: state.initialDataGetReducer.counties,
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {})(HospitalNote);
