import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import Select from "react-select";
import { connect } from "react-redux";
import { uniq, findIndex } from "lodash";
import { useForm } from "react-hook-form";
import ToastCommon from "../../toastCommon";
import { useToasts } from "react-toast-notifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useCandidateList from "../../../customHooks/useCandidateList";
import { Button, Label, Input, Form, FormGroup, Col, Alert } from "reactstrap";
import {
  HOSTMexxar,
  SCHEDULE_TRAINING,
  TRAININGS,
  REGISTER_CANDIDATE,
} from "../../../configs/api-config";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { sendNotification } from "../../../util/common/sendNotification";
import dateFormatter from "../../../util/common/dateFormatter";
import DateInput from "../../../util/common/DateInput";

function Training(props) {
  const MexxarApi = HOSTMexxar;
  const MexxarApiTRAININGS = TRAININGS;
  const MexxarCandidateApi = REGISTER_CANDIDATE;
  const MexxarApiSCHEDULE_TRAINING = SCHEDULE_TRAINING;

  const { onCreate, closeModal, start, end, auth, candidateWhenClick,candidateId } = props;

  const { addToast } = useToasts();
  const { register, handleSubmit, errors } = useForm();

  let loggedInEmployee = {
    value: auth.item.firstName + " " + auth.item.lastName,
    label: auth.item.firstName + " " + auth.item.lastName,
    id: auth.item.userTypeId,
  };

  const isDateNotAvailable = false;

  const [filterCustom, setFilterCustom] = useState({
    value: "ACTIVE",
    bool: true,
  });

  let url = MexxarCandidateApi + "/status/" + filterCustom.value;

  const [query, setQuery] = useState("");
  const [date, setDate] = useState(false);
  const [endDate, setEndDate] = useState();
  const [startDate, setStartDate] = useState();
  const [trainings, setTrainings] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [candidates, setCandidates] = useState([]);
  const [employees, setEmployees] = useState(null);
  const [endDateFlag, setEndDateFlag] = useState(null);
  const [startDateFlag, setStartDateFlag] = useState(null);
  const [checkBoxError, setCheckBoxError] = useState(false);
  const [selectedTrainngs, setSelectedTrainngs] = useState([]);
  const [dataErrorStatus, setDataErrorStatus] = useState(false);
  const [ownCheckBoxClicked, setOwnCheckBoxClicked] = useState(true);
  const [endDataErrorStatus, setEndDataErrorStatus] = useState(false);
  const [indexOfDefaultEmployee, setIndexOfDefaultEmployee] = useState(null);
  const [errorTrainingFieldEmpty, setErrorTrainingFieldEmpty] = useState(false);
  const [employeeCheckBoxClicked, setEmployeeCheckBoxClicked] = useState(false);
  const [selectedEmployees, setSelectedEmployees] = useState([
    loggedInEmployee,
  ]);
  const [candidateCheckBoxClicked, setCandidateCheckBoxClicked] =
    useState(false);
  const [selectedCandidates, setSelectedCandidates] =
    useState(candidateWhenClick);

  const { loading, error, rows, hasMore } = useCandidateList(
    url,
    filterCustom,
    query,
    pageNumber
  );

  const getCandidates = () => {
    let listOfCandidates = [];
    for (let i = 0; i < rows.length; i++) {
      listOfCandidates.push({
        value: rows[i].id,
        label: `${rows[i].firstName} ${rows[i].lastName}`,
        id: rows[i].id,
        candidate: rows[i],
      });
    }

    if (query != "") {
      setCandidates(listOfCandidates);
    } else {
      setCandidates([]);
    }
  };

  const handleInputChange = (e) => {
    setQuery(e);
  };

  // const scheduleTraining = (data) => {
  //   let employeeUsers = [];
  //   let candidateUsers = [];



    
  //   if (selectedCandidates != null) {
  //     if (candidateWhenClick) {
  //       candidateUsers.push(selectedCandidates.id);
  //     } else {
  //       for (let i = 0; i < selectedCandidates.length; i++) {
  //         candidateUsers.push(selectedCandidates[i].id);
  //       }
  //     }
  //   }

  //   if (selectedEmployees != undefined || selectedEmployees != null) {
  //     if (employeeCheckBoxClicked || ownCheckBoxClicked) {
  //       for (let i = 0; i < selectedEmployees.length; i++) {
  //         employeeUsers.push(selectedEmployees[i].id);
  //       }
  //     } else {
  //       employeeUsers = [];
  //     }
  //   }

  //   if (ownCheckBoxClicked) {
  //     employeeUsers.push(loggedInEmployee.id);
  //   }

  //   let listOfSelectedTranings = [];
  //   if (
  //     selectedTrainngs != null ||
  //     selectedTrainngs != undefined ||
  //     selectedTrainngs.length > 0
  //   ) {
  //     for (let i = 0; i < selectedTrainngs.length; i++) {
  //       listOfSelectedTranings.push(selectedTrainngs[i].value);
  //     }
  //   }
  //   const formattedStartDateTime = dateFormatter.formatDateToYYYYMMDD(data.StartDate) + ' ' + data.StartTime;
  //   const formattedEndDateTime = dateFormatter.formatDateToYYYYMMDD(data.EndDate) + ' ' + data.EndTime;
  //   let array = {
  //     trainings: listOfSelectedTranings,
  //     // startDate: moment(data.StartDate).add(data.StartTime).toDate(),
  //     // endDate: moment(data.EndDate).add(data.EndTime).toDate(),
  //     startDateTime: moment(formattedStartDateTime, "YYYY-MM-DD HH:mm").toDate(),
  //     endDateTime: moment(formattedEndDateTime, "YYYY-MM-DD HH:mm").toDate(),
  //     employees: employeeUsers == null ? [] : uniq(employeeUsers),
  //     candidates: candidateUsers == null ? [] : candidateUsers,
  //   };

  //   axios
  //     .post(MexxarApiSCHEDULE_TRAINING, array)
  //     .then((res) => {
  //       if (res.data.status == "success") {
  //         closeModal();
  //         ToastCommon(true, "success", addToast);
  //         onCreate(data);
  //       } else {
  //         ToastCommon(true, "duplicate", addToast);
  //       }
  //     })
  //     .catch((err) => {
  //       //console.log(err)
  //       ToastCommon(true, "error", addToast);
  //     });
  // };

  const scheduleTraining = (data) => {
    let employeeUsers = [];
    let candidateUsers = [];
  
    if (selectedCandidates != null) {
      if (candidateWhenClick) {
        candidateUsers.push(selectedCandidates.id);
      } else {
        for (let i = 0; i < selectedCandidates.length; i++) {
          candidateUsers.push(selectedCandidates[i].id);
        }
      }
    }
  
    if (selectedEmployees != undefined || selectedEmployees != null) {
      if (employeeCheckBoxClicked || ownCheckBoxClicked) {
        for (let i = 0; i < selectedEmployees.length; i++) {
          employeeUsers.push(selectedEmployees[i].id);
        }
      } else {
        employeeUsers = [];
      }
    }
  
    if (ownCheckBoxClicked) {
      employeeUsers.push(loggedInEmployee.id);
    }
  
    let listOfSelectedTrainings = [];
    if (
      selectedTrainngs != null &&
      selectedTrainngs != undefined &&
      selectedTrainngs.length > 0
    ) {
      for (let i = 0; i < selectedTrainngs.length; i++) {
        listOfSelectedTrainings.push(selectedTrainngs[i].value);
      }
    }
  

    const formattedStartDateTime = dateFormatter.formatDateToYYYYMMDD(data.StartDate) + ' ' + data.StartTime;
    const formattedEndDateTime = dateFormatter.formatDateToYYYYMMDD(data.EndDate) + ' ' + data.EndTime;
  
    let array = {
      trainings: listOfSelectedTrainings,
      startDate: moment(formattedStartDateTime, "YYYY-MM-DD HH:mm").toISOString(),
      endDate: moment(formattedEndDateTime, "YYYY-MM-DD HH:mm").toISOString(),
      employees: employeeUsers == null ? [] : uniq(employeeUsers),
      candidates: candidateUsers == null ? [] : candidateUsers,
    };
  
    axios
      .post(MexxarApiSCHEDULE_TRAINING, array)
      .then((res) => {
        if (res.data.status == "success") {
          closeModal();
          ToastCommon(true, "success", addToast);
          onCreate(data);
        } else {
          ToastCommon(true, "duplicate", addToast);
        }
      })
      .catch((err) => {
        ToastCommon(true, "error", addToast);
      });
  };
  
  const onSubmit = (data) => {
    if (
      ownCheckBoxClicked ||
      employeeCheckBoxClicked ||
      candidateCheckBoxClicked
    ) {
      if (selectedTrainngs.length > 0) {
        if (!dataErrorStatus && !endDataErrorStatus && !date) {
          scheduleTraining(data);
  
          const selectedTrainingNames = selectedTrainngs.map(training => training.label);
  

          const startDateFormatted = dateFormatter.formatDateToYYYYMMDD(data.StartDate) + ' ' + data.StartTime;
          const endDateFormatted = dateFormatter.formatDateToYYYYMMDD(data.EndDate) + ' ' + data.EndTime;
  
          const formattedStart = moment(startDateFormatted, "YYYY-MM-DD HH:mm").format("dddd, MMMM D, YYYY h:mm A");
          const formattedEnd = moment(endDateFormatted, "YYYY-MM-DD HH:mm").format("dddd, MMMM D, YYYY h:mm A");
  
          if (candidateCheckBoxClicked) {
            sendNotification({
              notificationType: "TRAINING",
              title: `New Training Created for ${selectedTrainingNames.join(', ')}`,
              content: `${selectedTrainingNames.join(', ')}: From ${formattedStart} to ${formattedEnd}`,
              startDate: new Date(),
              candidateId: candidateId,
              addToast: addToast
            });
          }
        }
      } else {
        setErrorTrainingFieldEmpty(true);
      }
    } else {
      setCheckBoxError(true);
    }
  };
  
 
  
// const onSubmit = (data) => {
//   if (
//     ownCheckBoxClicked ||
//     employeeCheckBoxClicked ||
//     candidateCheckBoxClicked
//   ) {
//     if (selectedTrainngs.length > 0) {
//       if (!dataErrorStatus && !endDataErrorStatus && !date) {
//         scheduleTraining(data);
       
        
//         const selectedTrainingNames = selectedTrainngs.map(training => training.label);
//         const startDateFormatted = moment(data.StartDate)
//         .add(data.StartTime)
//         .format("dddd, MMMM D, YYYY h:mm A"); 
//       const endDateFormatted = moment(data.EndDate)
//         .add(data.EndTime)
//         .format("dddd, MMMM D, YYYY h:mm A");

//         if (candidateCheckBoxClicked) {
//           sendNotification({
//             notificationType: "TRAINING",
//             title: `New Training Created for ${selectedTrainingNames.join(', ')}`, 
//             content: `${selectedTrainingNames.join(', ')}: From ${startDateFormatted} to ${endDateFormatted}`, 
//            startDate:new Date(),
//             candidateId: candidateId, 
//             addToast: addToast
//           });
//         }
//       }
//     } else {
//       setErrorTrainingFieldEmpty(true);
//     }
//   } else {
//     setCheckBoxError(true);
//   }
// };



  const getTrainings = () => {
    axios
      .get(MexxarApiTRAININGS)
      .then((res) => {
        let listOfTrainings = [];
        for (let i = 0; i < res.data.body.length; i++) {
          listOfTrainings.push({
            value: res.data.body[i].id,
            label: res.data.body[i].name,
            id: res.data.body[i].id,
            training: res.data.body[i],
          });
        }
       
        setTrainings(listOfTrainings);
  
  
      })
      .catch((err) => {
        ToastCommon(true, "error", addToast);
      });
  };
  
  const [nameState, setNameState] = useState([]);
  useEffect(() => {
    checkDate();
    return () => {};
  }, [startDate, endDate, startDateFlag, endDateFlag]);

  const TrainingNotifications = () => {
   
    axios
      .get(MexxarApiTRAININGS)
      .then((res) => {
   
        const trainingNames = res.data.body.map(training => training.name);
  
        
        const title =  trainingNames.join(", ");
  
   
        axios
          .post(
            "https://permapi.mexxar.com/mobile/notifications/fcm/create",
            {
              notificationType: "TRAINING",
              title: title,
              content: "Training Notification",
              candidateId: candidateId,
              eventDate: startDate
            },
            {
              params: {
                topic: "mexxar-candidate-" + candidateId
              }
            }
          )
          .then((res) => {
            if (res.data.status == "success") {
              // Handle success if needed
            } else {
              ToastCommon(true, "error", addToast);
            }
          })
          .catch((err) => {
            ToastCommon(true, "error", addToast);
          });
      })
      .catch((err) => {
        ToastCommon(true, "error", addToast);
      });
  };
  







  const getEmployees = () => {
    axios.get(MexxarApi + "employees").then((res) => {
      let listOfEmployees = [];
      for (let i = 0; i < res.data.body.length; i++) {
        listOfEmployees.push({
          value: res.data.body[i].id,
          label: `${res.data.body[i].firstName} ${res.data.body[i].lastName}`,
          id: res.data.body[i].id,
          employee: res.data.body[i],
        });
      }

      /* {find index of default employee from emplyee list} */
      let index = findIndex(listOfEmployees, (item) => {
        return item.id == loggedInEmployee.id;
      });
      setIndexOfDefaultEmployee(index);
      setEmployees(listOfEmployees);
    });
  };

  const checkDate = () => {
    let startDateTemp = "";
    if (startDateFlag != null && startDateFlag != undefined) {
      startDateTemp = startDateFlag;
    } else {
      startDateTemp = startDate;
    }

    let endDateTemp = "";
    if (endDateFlag != null && endDateFlag != undefined) {
      endDateTemp = endDateFlag;
    } else {
      endDateTemp = endDate;
    }

    setDate(moment(endDateTemp).isBefore(startDateTemp));
    
  };

  const onCheckHandler = (e) => {
    switch (e.target.value) {
      case "OWN":
        setOwnCheckBoxClicked(!ownCheckBoxClicked);
        setEmployeeCheckBoxClicked(false);
        setCandidateCheckBoxClicked(false);
        break;
      case "EMPLOYEE":
        setEmployeeCheckBoxClicked(!employeeCheckBoxClicked);
        // if (e.target.checked == false) setSelectedEmployees(null);
        if (e.target.checked == false) {
          setSelectedEmployees(null);
        } else {
          setSelectedEmployees([loggedInEmployee]);
        }
        break;
      case "CANDIDATE":
        setCandidateCheckBoxClicked(!candidateCheckBoxClicked);
        if (e.target.checked == false) setSelectedCandidates(null);
        else {
          setSelectedCandidates(candidateWhenClick);
        }
        break;

      default:
        break;
    }

    /*setting the error alert off*/
    if (e.target.checked) {
      setCheckBoxError(false);
    }
  };

  useEffect(() => {
    setStartDate(moment(start).format("YYYY-MM-DD"));
    setEndDate(moment(end).format("YYYY-MM-DD"));
    getTrainings();
    getEmployees();
    return () => {};
  }, []);

  useEffect(() => {
    getCandidates();
    return () => {};
  }, [rows]);

  useEffect(() => {
    checkDate();
   
    return () => {};
  }, [startDateFlag, endDateFlag, startDate, endDate]);

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      border: "1px solid #f54394",
      boxShadow: "0 0 0 0.2rem rgba(245, 67, 148, 0.25)",
    }),
  };




 

  

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup row>
          <Label sm={3}>Training</Label>
          <Col sm={8}>
            <Select
              styles={errorTrainingFieldEmpty ? colourStyles : ""}
              type="select"
              isMulti
              id="trainngId"
              name="trainngId"
              defaultValue={0}
              onChange={(event) => {
                setErrorTrainingFieldEmpty(false);
                setSelectedTrainngs(event);
              }}
              invalid={errorTrainingFieldEmpty}
              options={
                trainings != undefined && trainings != null ? trainings : null
              }
              innerRef={register({
                required: {
                  value: true,
                  message: "This field is required!",
                },
              })}
            ></Select>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>Start</Label>
          <Col sm={5}>
            {/* <Input
              type="date"
              name="StartDate"
              invalid={errors.postCode || dataErrorStatus || date}
              innerRef={register}
              defaultValue={moment(start).format("YYYY-MM-DD")}
              onChange={(e) => {
                setDataErrorStatus(
                  moment(e.target.value).format("l") == moment().format("l")
                    ? false
                    : moment(e.target.value).isBefore()
                );
                setStartDateFlag(e.target.value);
              }}
            /> */}
               <DateInput
            id="StartDate"
            register={register}
            value={startDate != null ? dateFormatter.formatDateToDDMMYYYY(startDate) : ""}
            onChange={(e) => {
              setDataErrorStatus(
                moment(dateFormatter.formatDateToYYYYMMDD(e)).format("l") == moment().format("l")
                  ? false
                  : moment().isBefore()
              );
              setStartDateFlag(dateFormatter.formatDateToYYYYMMDD(e));
            }}
            ></DateInput>
            {dataErrorStatus && (
              <ul
                className="list-unstyled text-sm mt-1 text-muted filled"
                id="parsley-id-119"
              >
                <li className="parsley-required text-danger">Invalid Date</li>
              </ul>
            )}
          </Col>
          <Col sm={4}>
            <Input
              type="time"
              name="StartTime"
              innerRef={register}
              defaultValue={moment(start).format("hh:mm")}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>End</Label>
          <Col sm={5}>
            {/* <Input
              type="date"
              name="EndDate"
              innerRef={register}
              invalid={errors.postCode || endDataErrorStatus || date}
              defaultValue={moment(end).format("YYYY-MM-DD")}
              onChange={(e) => {
                setEndDataErrorStatus(
                  moment(e.target.value).format("l") == moment().format("l")
                    ? false
                    : moment(e.target.value).isBefore()
                );
                setEndDateFlag(e.target.value);
              }}
            /> */}
            
<DateInput
           id="EndDate"
           register={register} 
           invalid={endDataErrorStatus || date}
            value={endDate != null ? dateFormatter.formatDateToDDMMYYYY(endDate) : ""}
            onChange={(e) => {
              setDataErrorStatus(
                moment(dateFormatter.formatDateToYYYYMMDD(e)).format("l") == moment().format("l")
                  ? false
                  : moment().isBefore()
              );
              setEndDateFlag(dateFormatter.formatDateToYYYYMMDD(e));
            }}
            ></DateInput>
            {endDataErrorStatus ||
              (date && (
                <ul
                  className="list-unstyled text-sm mt-1 text-muted filled"
                  id="parsley-id-119"
                >
                  <li className="parsley-required text-danger">Invalid Date</li>
                </ul>
              ))}
          </Col>
          <Col sm={4}>
            <Input
              type="time"
              name="EndTime"
              innerRef={register}
              defaultValue={moment(end).format("hh:mm")}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>Tag</Label>
          <Col sm={3} className="mt-2">
            <Label>
              <Input
                type="checkbox"
                name="type-radio"
                value="OWN"
                checked={ownCheckBoxClicked}
                innerRef={register}
                onChange={(e) => onCheckHandler(e)}
              />
              Own
            </Label>
          </Col>
          
          <Col sm={3} className="mt-2">
            <Label>
              <Input
                type="checkbox"
                name="type-radio"
                value="EMPLOYEE"
                disabled={ownCheckBoxClicked}
                checked={employeeCheckBoxClicked}
                innerRef={register}
                onChange={(e) => onCheckHandler(e)}
              />
              Consultant
            </Label>
          </Col>
          <Col sm={3} className="mt-2">
            <Label>
              <Input
                type="checkbox"
                name="type-radio"
                value="CANDIDATE"
                disabled={ownCheckBoxClicked}
                checked={candidateCheckBoxClicked}
                innerRef={register}
                onChange={(e) => onCheckHandler(e)}
              />
              Candidate
            </Label>
          </Col>
        </FormGroup>

           {ownCheckBoxClicked && (
        <Alert >
        <div>
        Caution: Untick to select Consultant/Candidate
                            </div>
        </Alert>
        )}

        {employeeCheckBoxClicked && !ownCheckBoxClicked ? (
          <FormGroup row>
            <Col sm={3}>
              <Label>Tag Employess</Label>
            </Col>
            <Col sm={9}>
              <Select
                defaultValue={employees && employees[indexOfDefaultEmployee]}
                isMulti
                name="employeeId"
                className="basic-multi-select"
                placeholder="Search consultants ..."
                classNamePrefix="select"
                options={employees ? employees:[]}
                onChange={(data) => setSelectedEmployees(data)}
                isLoading={loading}
                noOptionsMessage={() => "No results found"}
                innerRef={register({
                  required: {
                    value: true,
                    message: "This field is required!",
                  },
                })}
              />
            </Col>
          </FormGroup>
        ) : null}
        {candidateCheckBoxClicked && !ownCheckBoxClicked ? (
          <FormGroup row>
            <Col sm={3}>
              <Label>Tag Candidates</Label>
            </Col>
            <Col sm={9}>
              <Select
                isMulti
                name="candidateId"
                defaultValue={candidateWhenClick ? candidateWhenClick : ""}
                className="basic-multi-select"
                placeholder="Search candidates ..."
                classNamePrefix="select"
                onChange={(data) => setSelectedCandidates(data)}
                isLoading={loading}
                options={candidates}
                onInputChange={handleInputChange}
                noOptionsMessage={() => "No results found"}
                innerRef={register({
                  required: {
                    value: true,
                    message: "This field is required!",
                  },
                })}
              />
            </Col>
          </FormGroup>
        ) : null}

        {checkBoxError && (
          <Alert color="danger">
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              className="mx-2"
            ></FontAwesomeIcon>
            Please Tag a candidate or consultant
          </Alert>
        )}
        {isDateNotAvailable ? (
          <Alert color="danger">
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              className="mx-2"
            ></FontAwesomeIcon>
            Date cannot be allocate. Please select a different date
          </Alert>
        ) : null}



        <div className="d-flex justify-content-end">
          {" "}
          <Button className="mr-2" color="success" type="submit">
            Create
          </Button>{" "}
         
          <Button color="secondary" onClick={() => closeModal()}>
            Cancel
          </Button>
        </div>
      </Form>
    </>
  );
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {})(Training);
