import React, { useEffect, useState } from "react";
import {
  EMPLOYEE_PERMISSIONS,
  EMPLOYEE_TEAMS,
  HOSTMexxar,
  HostMexxarURL,
  TRUSTS,
  WARDS,
} from "../../configs/api-config";
import axios from "axios";
import moment from "moment";
import Select from "react-select";
import { Bar } from "react-chartjs-2";
import { Label, Button, ButtonGroup, Col, Row, Input } from "reactstrap";
import { forEach, uniqWith, isEqual, filter, isNull, sumBy } from "lodash";
import useDidMountEffect from "../../components/Modal/ModalBody/intialRunUseEffect";
import {
  clientsGetAction,
  departmentsGetAction,
  employeesGetAction,
} from "../../redux/actions/initialDataGetAction";
import store from "../../redux/store";
import { connect } from "react-redux";
import DateInput from "../../util/common/DateInput";
import dateFormatter from "../../util/common/dateFormatter";

const MissingBookings2 = (props) => {
  let demo = false;
  const MexxarApi = HOSTMexxar;
  const MexxarTeams = EMPLOYEE_TEAMS;
  const MexxarTeamMembers = EMPLOYEE_PERMISSIONS;
  const MexxarApiTrust = TRUSTS;
  const mexxarWards = WARDS;
  const { reduxClients, reduxEmployees, reduxWards } = props;

  const defaultsTypes = [
    // {
    //   id: 1,
    //   label: "By Trust",
    //   value: "trust",
    // },
    {
      id: 1,
      label: "By Client",
      value: "client",
    },
    // {
    //   id: 1,
    //   label: "By Team",
    //   value: "team",
    // },
    // {
    //   id: 1,
    //   label: "By Employee",
    //   value: "employee",
    // },
    {
      id: 2,
      label: "By Wards",
      value: "ward",
    },
  ];
  const [team, setTeam] = useState(null);
  const [trust, setTrust] = useState(null);
  const [secondSelectOption, setSecondSelectOption] = useState(null);
  const [type, setType] = useState(defaultsTypes);
  // const [dateTo, setDateTo] = useState();
  const [dateTo, setDateTo] = useState(
    dateFormatter.formatDateToYYYYMMDD(new Date())
  );
  const [range, setRange] = useState(false);
  // const [dateFrom, setDateFrom] = useState(moment(new Date ()).subtract(1, 'months'));
  const [dataList, setDataList] = useState([]);
  const [rangeType, setRangeType] = useState("YEARLY");
  const [noOfbookings, setNoOfBookings] = useState([]);
  const [noOfMissingBookings, setNoOfMissingBookings] = useState([]);
  const [employees, setEmployees] = useState();
  const [clients, setClients] = useState();
  const [clientss, setClientss] = useState();
  const [wards, setWards] = useState();
  const [wardss, setWardss] = useState();
  const [selectedSecondSelectOption, setSelectedSecondSelectOption] =
    useState(null);
  const [selectedSecondSelectOption1, setSelectedSecondSelectOption1] =
    useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [dummpyDataBooking, setDummpyDataBooking] = useState();
  const [dummpyDataMissingBookings, setDummpyDataMissingBookings] = useState();
  const [dateFrom, setDateFrom] = useState(
    dateFormatter.formatDateToYYYYMMDD(moment().subtract(1, "months"))
  );
  // const [dateTo, setDateTo] = useState(dateFormatter.formatDateToYYYYMMDD(new Date()));

  const [itemCountMissedShiftsByHospital, setMissedShiftsByHospital] =
    useState();
  const [
    itemCountMissedShiftsByHospitalAndWard,
    setMissedShiftsByHospitalAndWard,
  ] = useState();

  const [items, setItems] = useState();

  const [itemCountMissedShiftsByDate, setItemCountMissedShiftsByDate] =
    useState();

  const labels = [];
  const dataCounts = [];

  const getAllRows2 = () => {
    axios({
      method: "get",
      url: HOSTMexxar + "reports/filteredMissedShiftsByDate" + "?",
      params: {
        startDate: moment(dateFrom).format("YYYY/MM/DD"),

        endDate: moment(dateTo).format("YYYY/MM/DD"),
      },
    })
      .then((res) => {
        const responseData = res.data.body.items;

        responseData.forEach((item) => {
          console.log("Date:", item.date, "Count:", item.count);
        });

        setItemCountMissedShiftsByDate(
          responseData.map((item) => ({
            label: item.date,
            value: item.count,
          }))
        );
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
      });
  };

  const data = {
    labels: itemCountMissedShiftsByDate
      ? itemCountMissedShiftsByDate.map((item) => item.label)
      : [],
    datasets: [
      {
        label: "Missed Shifts By Date",
        data: itemCountMissedShiftsByDate
          ? itemCountMissedShiftsByDate.map((item) => item.value)
          : [],

        backgroundColor: "rgba(204, 153, 255, 0.2)",
        borderColor: "rgba(204, 153, 355, 100)",

        borderWidth: 1,
        barPercentage: 0.2,
      },
    ],
  };

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  var currentYear = moment().year();

  const getTeams = () => {
    axios
      .get(MexxarTeams)
      .then((res) => {
        let teamArray = [];

        forEach(res.data.body, function (item) {
          teamArray.push({
            value: item.name,
            label: item.name,
            id: item.id,
          });
        });

        setTeam(teamArray);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (reduxWards && clientss) {
      getWards(clientss.id);
    }
  }, [reduxWards, clientss]);

  useEffect(() => {
    if (selectedType) {
      if (selectedType.label === "By Client") {
        if (selectedSecondSelectOption.id === 1) {
          setDataList(clients.splice(0, 3));
          if (rangeType == "YEARLY") {
            setDummpyDataBooking([13450, 15420, 13450, 13652]);
            setDummpyDataMissingBookings([900, 825, 650]);
          } else if (rangeType == "MONTHLY") {
            setDummpyDataBooking([600, 562, 450, 560]);
            setDummpyDataMissingBookings([32, 35, 26]);
          } else if (rangeType == "WEEKLY") {
            setDummpyDataBooking([120, 90, 85]);
            setDummpyDataMissingBookings([15, 25, 20]);
          } else if (rangeType == "DAILY") {
            setDummpyDataBooking([10, 26, 14]);
            setDummpyDataMissingBookings([7, 8, 5]);
          } else if (rangeType == "DEFAULT") {
            setDummpyDataBooking([12, 19, 20]);
            setDummpyDataMissingBookings([2, 6, 5]);
          }
        } else if (selectedSecondSelectOption.id === 2) {
          setDataList(clients.splice(4, 5));
          if (rangeType == "YEARLY") {
            setDummpyDataBooking([13450, 13652, 15420, 13450, 13650]);
            setDummpyDataMissingBookings([825, 650, 650, 456, 780]);
          } else if (rangeType == "MONTHLY") {
            setDummpyDataBooking([562, 560, 480, 460, 466]);
            setDummpyDataMissingBookings([35, 26, 42, 35, 32]);
          } else if (rangeType == "WEEKLY") {
            setDummpyDataBooking([90, 85, 78, 65, 69]);
            setDummpyDataMissingBookings([15, 20, 30, 28, 25]);
          } else if (rangeType == "DAILY") {
            setDummpyDataBooking([10, 14, 12, 16, 17]);
            setDummpyDataMissingBookings([7, 5, 3, 5, 1]);
          } else if (rangeType == "DEFAULT") {
            setDummpyDataBooking([12, 19, 17, 16, 14]);
            setDummpyDataMissingBookings([2, 5, 1, 5, 6]);
          }
        }
      } else if (selectedType.label === "By Ward") {
        if (selectedSecondSelectOption.id === 1) {
          setDataList(wards.splice(0, 2));
          if (rangeType == "YEARLY") {
            setDummpyDataBooking([13450, 15420, 13450, 13652]);
            setDummpyDataMissingBookings([900, 825, 650]);
          } else if (rangeType == "MONTHLY") {
            setDummpyDataBooking([600, 562, 450, 560]);
            setDummpyDataMissingBookings([32, 35, 26]);
          } else if (rangeType == "WEEKLY") {
            setDummpyDataBooking([120, 90, 85]);
            setDummpyDataMissingBookings([15, 25, 20]);
          } else if (rangeType == "DAILY") {
            setDummpyDataBooking([10, 26, 14]);
            setDummpyDataMissingBookings([7, 8, 5]);
          } else if (rangeType == "DEFAULT") {
            setDummpyDataBooking([12, 19, 20]);
            setDummpyDataMissingBookings([2, 6, 5]);
          }
        } else if (selectedSecondSelectOption.id === 2) {
          setDataList(wards.splice(4, 5));
          if (rangeType == "YEARLY") {
            setDummpyDataBooking([13450, 13652, 15420, 13450, 13650]);
            setDummpyDataMissingBookings([825, 650, 650, 456, 780]);
          } else if (rangeType == "MONTHLY") {
            setDummpyDataBooking([562, 560, 480, 460, 466]);
            setDummpyDataMissingBookings([35, 26, 42, 35, 32]);
          } else if (rangeType == "WEEKLY") {
            setDummpyDataBooking([90, 85, 78, 65, 69]);
            setDummpyDataMissingBookings([15, 20, 30, 28, 25]);
          } else if (rangeType == "DAILY") {
            setDummpyDataBooking([10, 14, 12, 16, 17]);
            setDummpyDataMissingBookings([7, 5, 3, 5, 1]);
          } else if (rangeType == "DEFAULT") {
            setDummpyDataBooking([12, 19, 17, 16, 14]);
            setDummpyDataMissingBookings([2, 5, 1, 5, 6]);
          }
        }
      }
    }
  }, [selectedSecondSelectOption, rangeType]);

  const getIndividualResults = (response) => {
    let missingBookings = [];
    let successfulBookings = [];
    let xAxis = [];

    forEach(response, function (record) {
      missingBookings.push(record.missingBookings);
      successfulBookings.push(record.successfulBookings);

      xAxis.push(record.name);
    });

    setNoOfBookings(successfulBookings);
    setNoOfMissingBookings(missingBookings);
    setDataList(xAxis);
  };

  const getTrusts = () => {
    axios
      .get(MexxarApiTrust)
      .then((res) => {
        let array = [];
        forEach(res.data.body, function (item) {
          array.push({
            value: item.name,
            label: item.name,
            id: item.id,
          });
        });
        setTrust(array);
      })
      .catch((err) => {});
  };

  const getEmployees = () => {
    let names = [];
    reduxEmployees.forEach((item) => {
      names.push({
        id: item.id,
        label: item.firstName + " " + item.lastName,
        value: item.firstName + " " + item.lastName,
      });
    });
    setEmployees(names);
  };

  const getClients = () => {
    let names = [];
    reduxClients.forEach((item) => {
      names.push({
        id: item.id,
        label: item.name,
        value: item.name,
      });
    });
    setClients(names);
  };
  const getWards = () => {
    let names = [];
    reduxWards.forEach((item) => {
      names.push({
        id: item.id,
        label: item.name,
        value: item.name,
      });
    });
    setWards(names);
  };

  useEffect(() => {
    if (!reduxEmployees) {
      store.dispatch(employeesGetAction());
    }
    if (!reduxClients) {
      store.dispatch(clientsGetAction());
    }
    if (!reduxWards) {
      store.dispatch(departmentsGetAction());
    }

    getTrusts();
    getTeams();
  }, []);

  useEffect(() => {
    getIndividualResults();
  }, [clients, wards]);

  useEffect(() => {
    if (reduxEmployees) {
      getEmployees();
    }
  }, [reduxEmployees]);

  useEffect(() => {
    if (reduxClients) {
      getClients();
    }
  }, [reduxClients]);

  useEffect(() => {
    if (reduxWards) {
      getWards();
    }
  }, [reduxWards]);

  const dropdownWidth = 50;

  const colourStyles = {
    option: (provided, state) => ({
      ...provided,
      color: "#6A6767",
      // width: dropdownWidth
    }),
  };

  return (
    <div className="card">
      <div className="card-header">
        <h4>Missing Shifts Reports Count By Date</h4>
      </div>
      <div className="card-body">
        <div className="header">
          <Row>
            <Col sm={12} md={rangeType == "DEFAULT" ? 4 : 2}>
              <Label for="exampleDate">Start Date</Label>
              {/* )} */}
              <DateInput
                id="dateFromMS"
                value={dateFormatter.formatDateToDDMMYYYY(dateFrom)}
                onChange={(date) =>
                  setDateFrom(dateFormatter.formatDateToYYYYMMDD(date))
                }
              />
            </Col>

            <Col sm={12} md={2} lg={2} xl={2}>
              <Label for="exampleDate">End Date</Label>

              <DateInput
                id="dateToMS"
                value={dateFormatter.formatDateToDDMMYYYY(dateTo)}
                onChange={(date) =>
                  setDateTo(dateFormatter.formatDateToYYYYMMDD(date))
                }
              />
            </Col>
            <Col sm={12} md={2} lg={2} xl={2}>
              <Button
                color="success"
                onClick={getAllRows2}
                style={{
                  border: "1px solid green",
                  margin: "28px ",
                  height: "35px",
                  fontSize: "14px",
                }}
              >
                Search
              </Button>
            </Col>
          </Row>

          <div className="links"></div>
        </div>

        <Bar data={data} options={options} />
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    reduxClients: state.initialDataGetReducer.clients,
    reduxEmployees: state.initialDataGetReducer.employees,
    reduxWards: state.initialDataGetReducer.departments,
  };
}

export default connect(mapStateToProps, {})(MissingBookings2);
