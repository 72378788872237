import { faFileDownload, faFileUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Spinner } from "reactstrap";

export default function DocumentLoader({
  onCancel,
  showCancel = true,
  isDownloading = true,
}) {
  return (
    <div className="d-flex justify-content-center align-items-center flex-column">
      <FontAwesomeIcon icon={isDownloading?faFileDownload:faFileUpload} size="4x" />
      <div className="d-flex justify-content-center align-items-center mt-4">
        <Spinner
          className="mr-3"
          animation="border"
          style={{
            height: "20px",
            width: "20px",
            marginRight: "5px",
          }}
        />
        {isDownloading ? (
          <span>Please wait while we download your document(s)</span>
        ) : (
          <span>Please wait while we upload your document(s)</span>
        )}
      </div>
      {showCancel && (
        <Button className="btn btn-danger mt-3" onClick={onCancel}>
          Cancel
        </Button>
      )}
    </div>
  );
}
