import React, { Component } from "react";
import "./calendar.style.css";
import "react-big-calendar/lib/css/react-big-calendar.css";

import _ from "lodash";
import axios from "axios";
import moment from "moment";
import { Label } from "reactstrap";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalComponent from "../../../components/Modal/modalComponent";
import BookTrainNav from "../../../components/Modal/ModalBody/bookTrainNav";
import EventPreviewModal from "../../../components/Modal/EventPreviewModal";
import {
  CREATE_BOOKING,
  HOSTMexxar,
  REGISTER_CANDIDATE,
} from "../../../configs/api-config";
import BookingSummery from "../../../components/Modal/ModalBody/bookingSummery";
import dateFormatter from "../../../util/common/dateFormatter";

const localizer = momentLocalizer(moment);
const MexxarApi = HOSTMexxar;
const MexxarCandidate = REGISTER_CANDIDATE;

class CalendarCustom extends Component {
  constructor(...args) {
    super(...args);
    this.selectedEvent = [];
    this.eventPreview = false;
    this.showBookingModal = false;
    this.state = {
      candidateName: "",
      availibilityDates: [],
      events: [],
      show: false,
      eventDates: {
        start: "",
        end: "",
      },
      viewStartDate: null,
      viewEndDate: null,
    };
  }

  componentDidMount() {
    let date = new Date();
    const startOfMonth = moment(date)
      .startOf("month")
      .startOf("week")
      .format("YYYY/MM/DD");
    const endOfMonth = moment(date)
      .endOf("month")
      .endOf("week")
      .format("YYYY/MM/DD");

    this.setState({
      viewStartDate: startOfMonth,
      viewEndDate: endOfMonth,
    });
    this.getAvailibility(startOfMonth, endOfMonth);
    this.getEvents(startOfMonth, endOfMonth);
  }

  getEvents = (startOfMonth, endOfMonth) => {
    let eventsArray = [];
    axios
      .get(
        MexxarApi +
          "events/range?userId=" +
          this.props.candidateId +
          "&start=" +
          moment(startOfMonth).subtract(1, "d").format("YYYY/MM/DD") +
          "&end=" +
          moment(endOfMonth).add(1, "d").format("YYYY/MM/DD") +
          "&type=" +
          "CANDIDATE"
      )
      // .get(`${MexxarApi}events/candidates/${this.props.candidateId}`)
      .then((res) => {
        for (let i = 0; i < res.data.body.length; i++) {
          let startDate = moment(res.data.body[i].startDateTime).format(
            "YYYY-MM-DD"
          );
          let startTime = moment(res.data.body[i].startDateTime).format(
            "HH:mm:ss"
          );
          let endDate = moment(res.data.body[i].endDateTime).format(
            "YYYY-MM-DD"
          );
          let endTime = moment(res.data.body[i].endDateTime).format("HH:mm:ss");

          eventsArray.push({
            start: moment(startDate).add(startTime).toDate(),
            end: moment(endDate).add(endTime).toDate(),
            title: "Event: " + res.data.body[i].name,
            event: res.data.body[i],
            body: {
              EventName: res.data.body[i].name,
              EventStartDate: startDate,
              EventStartTime: startTime,
              EventEndDate: endDate,
              EventEndTime: endTime,
              recordedEmployeeName: res.data.body[i].recordedEmployeeName,
            },
          }
        
        
        );
        }
        // this.setState({events:[...eventsArray, ...this.state.events]});

        axios
          .get(
            MexxarApi +
              "training-booking/range?userId=" +
              this.props.candidateId +
              "&start=" +
              moment(startOfMonth).subtract(1, "d").format("YYYY/MM/DD") +
              "&end=" +
              moment(endOfMonth).add(1, "d").format("YYYY/MM/DD") +
              "&type=" +
              "CANDIDATE"
          )
          // .get(
          //   `${MexxarApi}training-booking/candidates/${this.props.candidateId}`
          // )
          .then((res) => {
            for (let i = 0; i < res.data.body.length; i++) {
              let startDate = moment(res.data.body[i].startDate).format(
                "YYYY-MM-DD"
              );
              let startTime = moment(res.data.body[i].startDate).format(
                "HH:mm:ss"
              );
              let endDate = moment(res.data.body[i].endDate).format(
                "YYYY-MM-DD"
              );
              let endTime = moment(res.data.body[i].endDate).format("HH:mm:ss");

              let title = "";
              if (
                res.data.body[i].trainings[0] == undefined ||
                res.data.body[i].trainings[0] == null
              ) {
                title = "";
              } else {
                if (res.data.body[i].trainings.length > 1) {
                  title = "Multiple Trainings";
                } else {
                  title = res.data.body[i].trainings[0].name;
                }
              }

              let trainingList = [];
              for (let x = 0; x < res.data.body[i].trainings.length; x++) {
                trainingList.push(res.data.body[i].trainings[x].name);
              }
              // eventsArray = [];
              eventsArray.push({
                start: moment(startDate).add(startTime).toDate(),
                end: moment(endDate).add(endTime).toDate(),
                title: "Training: " + title,
                trainings: trainingList,
                event: res.data.body[i],
                body: {
                  EventStartDate: startDate,
                  EventStartTime: startTime,
                  EventEndDate: endDate,
                  EventEndTime: endTime,
                  recordedEmployeeName: res.data.body[i].recordedEmployeeName,
                },
              });
            }

            axios
              .get(
                CREATE_BOOKING +
                  "/range?userId=" +
                  this.props.candidateId +
                  "&start=" +
                  moment(startOfMonth).subtract(1, "d").format("YYYY/MM/DD") +
                  "&end=" +
                  moment(endOfMonth).add(1, "d").format("YYYY/MM/DD") +
                  "&type=CANDIDATE"
              )
              // .get(`${MexxarApi}bookings/candidates/${this.props.candidateId}`)
              .then((res) => {
                for (let i = 0; i < res.data.body.length; i++) {
                  // let bookingDate = moment(res.data.body[i].shiftTo).format('YYYY-MM-DD');
                  let startDate = moment(res.data.body[i].bookingDate).format(
                    "YYYY-MM-DD"
                  );
                  let startTime = moment(res.data.body[i].shiftFrom).format(
                    "HH:mm:ss"
                  );
                  // let endDate = moment(res.data.body[i].shiftTo).format('YYYY-MM-DD');
                  let endTime = moment(res.data.body[i].shiftTo).format(
                    "HH:mm:ss"
                  );

                  eventsArray.push({
                    start: moment(startDate).add(startTime).toDate(),
                    end: moment(startDate).add(endTime).toDate(),
                    // title: "Booking: " + res.data.body[i].referenceNo,
                    title: "Booking",
                    event: res.data.body[i],
                    body: {
                      EventStartDate: startDate,
                      EventStartTime: startTime,
                      EventEndDate: startDate,
                      EventEndTime: endTime,
                      recordedEmployeeName: res.data.body[i].employeeName,
                    },
                  });
                }
                // this.setState({ events: [...eventsArray, ...this.state.events] });
                this.setState({ events: [...eventsArray] });
              });
          });
      });
  };

  getAvailibility = (startOfMonth, endOfMonth) => {
    let candidateId = this.props.candidateId;
    let availibilityDatesArray = [];
    axios

      .get(
        MexxarApi +
          "candidate-booking-logs/candidates/" +
          candidateId +
          "?start=" +
          startOfMonth +
          "&end=" +
          endOfMonth
      )
      .then((res) => {
        //this.setState({ candidateName: res.data.body[0].candidatesName });

        for (let i = 0; i < res.data.body.length; i++) {
          availibilityDatesArray.push({
            date: moment(res.data.body[i].date).format("L"),
            availibility: res.data.body[i].availabilityBookingLogStatus,
            shift: res.data.body[i].shift.name,
            bookingCallStatus: res.data.body[i].bookingCallStatus,
            shiftObject: res.data.body[i].shift,
            id: res.data.body[i].id,
          });
        }
        if (res.data.body.length > 0) {
          this.setState({
            availibilityDates: availibilityDatesArray,
            candidateName:
              res.data.body[0] != undefined
                ? res.data.body[0].candidatesName
                : "",
          });
        } else
          axios.get(MexxarCandidate + "/" + candidateId).then((res) => {
            let candidateName =
              res.data.body[0].firstName + " " + res.data.body[0].lastName;
            this.setState({
              // availibilityDates: availibilityDatesArray,
              candidateName: candidateName,
            });
          });
      });
  };

  customDayPropGetter = (date) => {
    let datesArray = this.state.availibilityDates;
    let dateDate = moment(date).format("MM/DD/YYYY");

    if (datesArray != undefined) {
      for (let i = 0; i < datesArray.length; i++) {
        if (datesArray[i].date === dateDate) {
          var styleCustom;
          //    console.log("datesArray[i", datesArray[i].shift);

          switch (datesArray[i].availibility) {
            case "NOT_AVAILABLE":
              styleCustom = {
                opacity: 1,
                borderTop: "2px",
                // backgroundColor: "#FF255C",
                borderTop: "18px solid #FF255C",
                display: "block",
              };
              break;
            case "AVAILABLE":
              switch (datesArray[i].bookingCallStatus) {
                case "DO_NOT_CALL_ME_BEFORE_BOOKING":
                  styleCustom = {
                    borderTop: "18px solid green",
                    opacity: 1,
                    borderTop: "2px",
                    display: "block",
                  };
                  switch (datesArray[i].shiftObject.name) {
                    case "E":
                      styleCustom = {
                        //background: "rgba(4, 255, 17, 0.308)",
                        borderBottom: "3px solid #cddc39",
                      };
                      break;
                    case "LD":
                      styleCustom = {
                        // background: "rgba(4, 255, 17, 0.308)",
                        borderTop: "18px solid green",
                        borderBottom: "6px solid #8bc34a",
                      };
                      break;
                    case "L":
                      styleCustom = {
                        //background: "rgba(4, 255, 17, 0.308)",
                        borderBottom: " 3px solid #ffc107",
                      };
                      break;
                    case "TW":
                      styleCustom = {
                        ///background: "rgba(4, 255, 17, 0.308)",
                        borderBottom: "3px solid #ff9800",
                      };
                      break;
                    case "N":
                      styleCustom = {
                        // background: "rgba(4, 255, 17, 0.308)",
                        borderBottom: " 6px solid #ff5722",
                      };
                      break;
                    default:
                      break;
                  }
                  break;
                case "CALL_ME_BEFORE_BOOKING":
                  switch (datesArray[i].shift) {
                    case "E":
                      styleCustom = {
                        borderTop: "18px solid #32a2a8",
                        // background: "rgba(255,255,0,0.308)",
                        borderBottom: "3px solid #cddc39",
                      };
                      break;
                    case "LD":
                      styleCustom = {
                        borderTop: "18px solid #32a2a8",
                        //background: "rgba(255,255,0,0.308)",
                        borderBottom: "6px solid #8bc34a",
                      };
                      break;
                    case "L":
                      styleCustom = {
                        borderTop: "18px solid #32a2a8",
                        //background: "rgba(255,255,0,0.308)",
                        borderBottom: " 3px solid #ffc107",
                      };
                      break;
                    case "TW":
                      styleCustom = {
                        borderTop: "18px solid #32a2a8",
                        //background: "rgba(255,255,0,0.308)",
                        borderBottom: "3px solid #ff9800",
                      };
                      break;
                    case "N":
                      styleCustom = {
                        bborderTop: "18px solid #32a2a8",
                        //background: "rgba(255,255,0,0.308)",
                        borderBottom: " 6px solid #ff5722",
                      };
                      break;
                    default:
                      break;
                  }
                  break;

                default:
                  break;
              }

              break;

            default:
              break;
          }

          return {
            className: "special-day",
            style: styleCustom,
          };
        }
      }
    }
    return {};
  };

  Event = ({ event }) => {
    return (
      <span className="d-flex flex-column ">
        <div style={{ fontSize: "10.2px" }} className="text-muted">
          {event.body.EventStartTime == "12:00:00" &&
          event.body.EventEndTime == "12:00:00" ? (
            "All Day"
          ) : (
            <>
              {moment(event.start).format("hh:mm a")}-
              {moment(event.end).format("hh:mm a")}
            </>
          )}
        </div>
        <div className="d-flex justify-content-between">
          <Label className="text-color font-weight-bold text-capitalize">
            {event.title}{" "}
          </Label>
          <small className="text-muted">{event.body.Shift}</small>
        </div>
        <div className="d-flex justify-content-between">
          <small className="text-muted">
            {event.body.Hospital}
            {/* {event.body.EventName} */}
          </small>
          <small className="text-muted">{event.body.Ward}</small>
        </div>
        <div className="d-flex justify-content-between">
          <small className="text-muted">
            {event.body.Ref}
            {dateFormatter.formatDateToDDMMYYYY(event.body.EventStartDate)}
          </small>
        </div>
        {/* <div className="d-flex justify-content-between">
          <small className="text-muted">
            {event.body.StartTime}
            {event.body.Training}
            {event.body.EventEndDate}
          </small>
          <small className="text-muted">
            {event.body.EndTime}
            {event.body.Training_Time}
            {event.body.EventEndTime}
          </small>
        </div> */}

        {event.desc && ":  " + event.desc}
      </span>
    );
  };

  handleSelect = ({ start, end, slots }) => {
    // const title = window.prompt("New Event Heading");
    // const body = window.prompt("New Event Body");
    const title = " test";
    //const body = "bodytest";
    //only allow dates higer than the current day to book or add an events
    let today = new Date();
    let diffDays = Math.floor((start - today) / 86400e3);
    // console.log("diffDays", diffDays);
    if (diffDays < -1) {
      this.setState({ show: false });
    } else {
      this.setState({ show: true });
    }

    this.setState({ eventDates: { start, end } });

    // console.log(this.state.eventDates.start);

    // const body=

    // if (title)
    // this.setState({
    //   events: [
    //     ...this.state.events,
    //     {
    //       start,
    //       end,
    //       title,
    //       body
    //     },
    //   ],
    // });
  };

  toggleModalShow = () => {
    this.setState({
      show: false,
      eventDates: {
        start: "",
        end: "",
      },
    });
  };

  onDataSaved = (data) => {
    let event_array = _.filter(this.state.events, {
      start: moment(data.body.StartDate).add(data.body.StartTime).toDate(),
    });

    //add to candidate's calendar
    //event_array.length==!1 ? console.log("no overlapping"):console.log("overlapping")

    {
      (() => {
        switch (data.tab) {
          case "Booking":
            return this.setState({
              events: [
                ...this.state.events,
                {
                  start: this.state.eventDates.start,
                  end: this.state.eventDates.end,
                  title: data.tab,
                  body: {
                    Ref: data.body.Ref_No,
                    StartTime: data.body.Start_Time,
                    EndTime: data.body.End_Time,
                    Hospital: data.body.hospital,
                    Shift: data.body.shift,
                    Ward: data.body.ward,
                  },
                },
              ],
            });

          case "Training":
            return this.setState({
              events: [
                ...this.state.events,
                {
                  start: this.state.eventDates.start,
                  end: this.state.eventDates.end,
                  title: data.tab,
                  body: {
                    Training: data.body.Training,
                    Training_Time: data.body.Training_Time,
                  },
                },
              ],
            });

          case "Event":
            return this.setState({
              events: [
                ...this.state.events,
                {
                  start: moment(data.body.StartDate)
                    .add(data.body.StartTime)
                    .toDate(),
                  end: moment(data.body.EndDate)
                    .add(data.body.EndTime)
                    .toDate(),
                  title: data.tab,
                  body: {
                    EventName: data.body.EventName,
                    EventStartDate: data.body.StartDate,
                    EventStartTime: data.body.StartTime,
                    EventEndDate: data.body.EndDate,
                    EventEndTime: data.body.EndTime,
                  },
                },
                // console.log(moment(data.body.EndDate ).format("ddd MMM DD YYYY").toDate())

                //console.log(moment(moment(data.body.EndDate ).add(moment.duration(data.body.EndTime))).toDate())
              ],
            });

          default:
            return null;
            break;
        }
      })();
    }

    this.getEvents(this.state.viewStartDate, this.state.viewEndDate);
  };
  customEventpropGetter = (event, start, end, isSelected) => {
    var backgroundColor = "#" + event.hexColor;
    // var style = {
    //   opacity: 0.8,
    //   borderTop: "2px",
    //   borderTopColor: "red",
    //   borderStyle: "solid",
    //   display: "block",
    // };
    // return {
    //   style: style,
    // };
    var styleCustom;

    switch (event.title.split(":")[0]) {
      case "Booking":
        styleCustom = {
          opacity: 1,
          borderTop: "2px",
          borderTopColor: "#14bae4",
          borderStyle: "solid",
          display: "block",
        };
        break;
      case "Availibility":
        switch (event.body.ShiftShortName) {
          case "E":
            break;
          case "L":
            styleCustom = {
              background: "rgb(255, 230, 0)",
            };
            break;
          case "LD":
            styleCustom = {
              background: "rgb(0, 204, 255, 0.1)",
            };
            break;
          case "N":
            styleCustom = {
              background: "rgba(255, 230, 0, 0.1)",
            };
            break;

          case "TW":
            break;
          case "N":
            break;
          default:
            break;
        }

        break;

      case "Training":
        styleCustom = {
          opacity: 1,
          borderTop: "2px",
          borderTopColor: "#f4c414",
          borderStyle: "solid",
          display: "block",
        };
        break;

      case "Event":
        styleCustom = {
          opacity: 1,
          borderTop: "2px",
          borderTopColor: "#f54394",
          borderStyle: "solid",
          display: "block",
        };
        break;

      default:
        break;
    }
    // console.log(styleCustom);

    return {
      style: styleCustom,
    };
  };

  eventModalFunction = (event) => {
    if (event.title === "Booking") {
      let tempData = {
        candidateName: event.event.candidateName,
        totalHours: event.event.totalHours,
        breakMinutes: event.event.breakMinutes,
        availabilityStatus: "BOOKED",
        bonus: event.event.bonus,
        bookingAttendedStatus: event.event.bookingAttendedStatus,
        bookingCallStatus: "",
        bookingDate: event.event.bookingDate,
        date: event.event.bookingDate,
        hospitalId: event.event.clientId,
        hospitalName: event.event.clientName,
        id: event.event.id,
        referenceNo: event.event.referenceNo,
        shift: event.event.shift,
        shiftFrom: event.event.shiftFrom,
        shiftTo: event.event.shiftTo,
        uplift: event.event.upLift,
        wardId: event.event.ward.id,
        wardName: event.event.ward.name,
      };
      this.setState({ selectedEvent: tempData });
      this.setState({ showBookingModal: true });
    } else {
      this.setState({ selectedEvent: event });
      this.setState({ eventPreview: true });
    }
  };

  render() {
    // console.log("events list", this.state.eventDates);
    return (
      <div>
        <div className="card p-1 m-1">
          {/* <div className="d-flex align-items-start flex-row"> */}
          <div className="card-body flex-row">
            <h6>Legend :</h6>
            <div className="row">
              <div className="d-flex mt-2">
                <div className="w-16 circle  green mx-1"></div>
                Available |
              </div>
              <div className="d-flex mt-2">
                <div className="w-16 circle red mx-1"></div>
                Unavailable |
              </div>
              <div className="d-flex mt-2">
                <div className="w-16 circle lime mx-1"></div>
                Available-Early (E) |
              </div>
              <div className="d-flex mt-2">
                <div className="w-16 circle light-green mx-1"></div>
                Available-Day (LD) |
              </div>
              <div className="d-flex mt-2">
                <div className="w-16 circle orange mx-1"></div>
                Available-Twilight (TW) |
              </div>
              <div className="d-flex mt-2">
                <div className="w-16 circle amber mx-1"></div>
                Available-Late (L) |
              </div>
              <div className="d-flex mt-2">
                <div className="w-16 circle deep-orange mx-1"></div>
                Available-Night (N) |
              </div>
              <div className="d-flex pl-3 pr-2 mt-2">
                <div className="w-16 circle cyan mx-1"></div>
                Call Before Booking |
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>

        {this.state.eventDates.start != "" ? (
          <ModalComponent
            show={this.state.show}
            header="Add to calendar "
            closeModal={() => {
              this.toggleModalShow();
              // this.getAvailibility(this.props.candidateId);
              // this.getEvents();
            }}
          >
            <BookTrainNav
              candidate={this.props?.candidate}
              candidateId={this.props.candidateId}
              candidateName={this.state.candidateName}
              //successStatus={() => this.getAvailibility(this.props.candidateId)}
              closeModal={() => {
                this.toggleModalShow();
                // this.getAvailibility(this.props.candidateId);
                // this.getEvents();
              }}
              onCreate={this.onDataSaved}
              start={this.state.eventDates.start}
              end={this.state.eventDates.end}
              dateAvailablities={this.state.availibilityDates}
            ></BookTrainNav>
          </ModalComponent>
        ) : null}

        <div className="card">
          <Calendar
            localizer={localizer}
            popup
            defaultDate={new Date()}
            defaultView="month"
            events={this.state.events}
            dayPropGetter={this.customDayPropGetter}
            eventPropGetter={this.customEventpropGetter}
            style={{ height: "100vh" }}
            // onSelectEvent={(event) => alert(event.title)}
            onSelectEvent={(event) => {
              // console.log("====================================");
              // console.log("Event : ", event);
              // console.log("====================================");
              this.eventModalFunction(event);
            }}
            onSelectSlot={this.handleSelect}
            selectable
            timeslots={1}
            // date={this.state.selectedDay}

            onRangeChange={(date) => {
              this.getEvents(date.start, date.end);
              this.setState({
                viewStartDate: moment(date.start).format("YYYY/MM/DD"),
                viewEndDate: moment(date.end).format("YYYY/MM/DD"),
              });
            }}
            components={{
              event: this.Event,
            }}
          />
        </div>

        {this.state.eventPreview && (
          <EventPreviewModal
            event={this.state.selectedEvent}
            id={this.state.id}
            show={this.state.eventPreview}
            closeModal={() => {
              this.setState({ eventPreview: false });
            }}
            refresh={() =>
              this.getEvents(this.state.viewStartDate, this.state.viewEndDate)
            }
       
          />
        )}

        <ModalComponent
          show={this.state.showBookingModal}
          header="Booking Summary"
          closeModal={() => {
            this.setState({ showBookingModal: false });
          }}
        >
          <BookingSummery
            // currentResetState={resetSheetData}
            //reset={(e) => setResetSheetData(e)}
            candidate={this.props.candidate}
            candidateId={this.props.candidateId}
            dayData={this.state.selectedEvent}
            heading="Booking summary"
            closeModal={() => {
              this.setState({ showBookingModal: false });
            }}
            reset={() =>
              this.getEvents(this.state.viewStartDate, this.state.viewEndDate)
            }
            currentResetState={() =>
              this.getEvents(this.state.viewStartDate, this.state.viewEndDate)
            }
            incomingPage="CANDIDATE_CALENDAR"
          /> 
        </ModalComponent>
      </div>
    );
  }
}

export default CalendarCustom;
