import React, { useState, useEffect } from "react";
import axios from "axios";
import { forEach, filter } from "lodash";
import { useForm } from "react-hook-form";
import { useToasts } from "react-toast-notifications";
import ToastCommon from "../../../../../components/toastCommon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faCheck } from "@fortawesome/free-solid-svg-icons";
import { REGISTER_CANDIDATE } from "../../../../../configs/api-config";
import { Col, Row, Button, Form, FormGroup, Label, Input } from "reactstrap";
import DateInput from "../../../../../util/common/DateInput";
import dateFormatter from "../../../../../util/common/dateFormatter";

const EmploymentHistory = () => {
  const MexxarApi = REGISTER_CANDIDATE;

  const { register, handleSubmit, errors } = useForm();
  const { addToast } = useToasts();

  const [addMore, setAddMore] = useState([{}]);
  const [editable, setEditable] = useState(false);
  const [candidateID, setCandidateID] = useState([]);
  const [dataExcisting, setDataExcisting] = useState([]);

  const handleEdit = () => {
    if (editable) {
      //edit call
    }
    setEditable(!editable);
  };

  const [yesChecked, setYesChecked] = useState(false);
  let filtered = addMore.filter((item) => Object.keys(item).length === 0);

  //Remove added fields
  const removeMTAddMore = () => {
    addMore.pop(addMore);

    setAddMore([...addMore]);
    //remove all addmore fields at once
  };

  //common filter to extract data wth key and values //
  const filterData = (data, identifier) => {
    let data_List = [];

    for (let i = 0; i < 100; i++) {
      if (!data[identifier + i]) {
        break;
      }
      let id = data[identifier + "ID" + i];
      let value = data[identifier + i];

      data_List.push({
        id: Number(id),
        identifier: identifier + i,
        value: value,
        detailCategory: 6,
      });
    }

    return data_List;
  };

  const onSubmit = (data) => {
    let A = filterData(data, "employerName");
    let B = filterData(data, "WardDept");
    let C = filterData(data, "dateOfEmployement");
    let D = filterData(data, "dateOfDermination");
    let E = filterData(data, "contactName");
    let F = filterData(data, "telephoneNumber");
    let G = filterData(data, "email");
    let H = filterData(data, "address");
    let I = filterData(data, "jobTitle");
    let J = filterData(data, "band");

    let updatingArray = A.concat(B, C, D, E, F, G, H, I, J);

    let AdvanceDetailsArray = [];

    //check if this is update-form-filling or new-form-filling
    if (Object.keys(dataExcisting).length == 0) {
      forEach(data, function (value, key) {
        if (value === "") {
        } else {
          AdvanceDetailsArray.push({
            identifier: key,
            value: value,
            detailCategory: 6,
          });
        }
      });
    } else {
      AdvanceDetailsArray = updatingArray;
    }

    axios
      .post(
        MexxarApi + "/" + candidateID + "/advance-profile-details",
        AdvanceDetailsArray
      )
      .then((res) => {
        if (res.data.status === "success") {
          // getAllRows();
          // setShowAddRow(!showAddRow);
          ToastCommon(true, "success", addToast);
        } else {
          ToastCommon(true, "error", addToast);
        }
      })
      .catch((err) => {
        //console.log(err);
        ToastCommon(true, "error", addToast);
      });
  };

  const getData = () => {
    let pathArray = window.location.pathname.split("/");
    const CandidateId = Number(pathArray[3]);
    //**********CandidateId and candidateID have same value but variable names are different*******/
    setCandidateID(CandidateId);
    axios
      .get(
        MexxarApi +
          "/" +
          CandidateId +
          "/advance-profile-details/category/EMPLOYMENT_HISTORY"
      )
      .then((res) => {
        let list = res.data.body;

        let A = filter(list, (item) => {
          return item.identifier.includes("employerName");
        });
        //  console.log("A .........", A);
        let B = filter(list, (item) => {
          return item.identifier.includes("WardDept");
        });
        //  console.log("B .........", B);
        let C = filter(list, (item) => {
          return item.identifier.includes("dateOfEmployement");
        });
        console.log("Filtered employment dates: ", C);
        //  console.log("C .........", C);
        let D = filter(list, (item) => {
          return item.identifier.includes("dateOfDermination");
        });
        // console.log(" D.........", D[0].id);
        let E = filter(list, (item) => {
          return item.identifier.includes("contactName");
        });
        // console.log(" E.........", E[0].id);
        let F = filter(list, (item) => {
          return item.identifier.includes("telephoneNumber");
        });
        // console.log(" F.........", F[0].id);
        let G = filter(list, (item) => {
          return item.identifier.includes("email");
        });
        // console.log(" G.........", G[0].id);
        let H = filter(list, (item) => {
          return item.identifier.includes("address");
        });
        // console.log(" H.........", H[0].id);
        let I = filter(list, (item) => {
          return item.identifier.includes("jobTitle");
        });
        // console.log(" I.........", I[0].id);
        let J = filter(list, (item) => {
          return item.identifier.includes("band");
        });
        // console.log(" J.........", J[0].id);
        let employerList = [];

        for (let i = 0; i < A.length; i++) {
          // console.log("test list A", A[i].id);

          employerList.push({
            //mapping for defaultValue - IDs of each incoming identifier
            employerNameID: A[i].id,
            WardDeptID: B[i].id,
            dateOfEmployementID: C[i].id,
            dateOfDerminationID: D[i].id,
            contactNameID: E[i].id,
            telephoneNumberID: F[i].id,
            emailID: G[i].id,
            addressID: H[i].id,
            jobTitleID: I[i].id,
            bandID: J[i].id,

            //mapping for defaultValue - data for incoming identifier

            employerName: A[i].value,
            WardDept: B[i].value,
            dateOfEmployement: C[i].value,
            dateOfDermination: D[i].value,
            contactName: E[i].value,
            telephoneNumber: F[i].value,
            email: G[i].value,
            address: H[i].value,
            jobTitle: I[i].value,
            band: J[i].value,
          });
        }

        setAddMore(employerList);
        setDataExcisting(list);
      })
      .catch((err) => {
        //   console.log(err);
        // toastCommon(true, "error");
      });
  };

  useEffect(() => {
    getData();
    return () => {};
  }, []);

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card">
          <div className="card-header">
            <div className="col-md-12 d-flex justify-content-between align-items-center">
              <h5>Employment History</h5>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <Form onSubmit={handleSubmit(onSubmit)}>
              {addMore.map((data, id) => {
                return (
                  <div key={id}>
                    <div className="card-header align-items-center">
                      <h5>Employer {id + 1}</h5>
                    </div>
                    <div className="">
                      <FormGroup row>
                        <Col sm={12} md={6}>
                          <Label>
                            Name of Employer / Organisation{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="name"
                            placeholder="Employer Name"
                            name={"employerName" + id}
                            defaultValue={data.employerName}
                            innerRef={register({
                              required: {
                                value: true,
                                message: "This field is required!",
                              },
                            })}
                          />
                          <Input
                            type="hidden"
                            innerRef={register}
                            name={"employerNameID" + id}
                            defaultValue={data.employerNameID}
                          />
                        </Col>
                        <Col sm={12} md={6}>
                          <Label>
                            Ward/Dept <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="name"
                            placeholder="Ward/Dept"
                            name={"WardDept" + id}
                            defaultValue={data.WardDept}
                            innerRef={register({
                              required: {
                                value: true,
                                message: "This field is required!",
                              },
                            })}
                          />
                          <Input
                            type="hidden"
                            innerRef={register}
                            name={"WardDeptID" + id}
                            defaultValue={data.WardDeptID}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Col sm={12} md={6}>
                          <Label>
                            Date of employment{" "}
                            <span className="text-danger">*</span>
                          </Label>

                          <DateInput
                            id={"dateOfEmployement" + id}
                            value={
                              data.dateOfEmployement
                            }
                            onChange={(formattedDate) =>
                              console.log("Selected Date From:", formattedDate)
                            }
                            register={register({
                              required: {
                                value: true,
                                message: "This field is required!",
                              },
                            })}
                          />
                          <Input
                            type="hidden"
                            innerRef={register}
                            name={"dateOfEmployementID" + id}
                            defaultValue={data.dateOfEmployementID}
                          />
                        </Col>
                        <Col sm={12} md={6}>
                          <Label>
                            Date of Termination{" "}
                            <span className="text-danger">*</span>
                          </Label>

                          <DateInput
                            id={"dateOfDermination" + id}
                            value={
                              data.dateOfDermination
                            } // Ensure this is in YYYY-MM-DD format
                            onChange={(formattedDate) =>
                              console.log("Selected Date From:", formattedDate)
                            }
                            register={register({
                              required: {
                                value: true,
                                message: "This field is required!",
                              },
                            })}
                          />

                          <Input
                            type="hidden"
                            innerRef={register}
                            name={"dateOfDerminationID" + id}
                            defaultValue={data.dateOfDerminationID}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Col sm={12} md={4}>
                          <Label>
                            Contact Name <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="name"
                            placeholder=""
                            name={"contactName" + id}
                            defaultValue={data.contactName}
                            innerRef={register({
                              required: {
                                value: true,
                                message: "This field is required!",
                              },
                            })}
                          />
                          <Input
                            type="hidden"
                            innerRef={register}
                            name={"contactNameID" + id}
                            defaultValue={data.contactNameID}
                          />
                        </Col>
                        <Col sm={12} md={4}>
                          <Label>
                            Telephone Number{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            placeholder=""
                            name={"telephoneNumber" + id}
                            defaultValue={data.telephoneNumber}
                            innerRef={register({
                              required: {
                                value: true,
                                message: "This field is required!",
                              },
                              pattern: {
                                value:
                                  /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/,
                                message: "Please enter a valid phone number",
                              },
                            })}
                          />{" "}
                          <Input
                            type="hidden"
                            innerRef={register}
                            name={"telephoneNumberID" + id}
                            defaultValue={data.telephoneNumberID}
                          />
                        </Col>
                        <Col sm={12} md={4}>
                          <Label>
                            Email <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="email"
                            placeholder=""
                            name={"email" + id}
                            defaultValue={data.email}
                            innerRef={register({
                              required: {
                                value: true,
                                message: "This field is required!",
                              },
                              pattern: {
                                value:
                                  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                message: "Please enter a valid email address!",
                              },
                            })}
                          />
                          <Input
                            type="hidden"
                            innerRef={register}
                            name={"emailID" + id}
                            defaultValue={data.emailID}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Col sm={12} md={12}>
                          <Label>
                            Address <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            placeholder=""
                            name={"address" + id}
                            defaultValue={data.address}
                            innerRef={register({
                              required: {
                                value: true,
                                message: "This field is required!",
                              },
                            })}
                          />
                          <Input
                            type="hidden"
                            innerRef={register}
                            name={"addressID" + id}
                            defaultValue={data.addressID}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Col sm={12} md={6}>
                          <Label>
                            Job Title <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            placeholder=""
                            name={"jobTitle" + id}
                            defaultValue={data.jobTitle}
                            innerRef={register({
                              required: {
                                value: true,
                                message: "This field is required!",
                              },
                            })}
                          />
                          <Input
                            type="hidden"
                            innerRef={register}
                            name={"jobTitleID" + id}
                            defaultValue={data.jobTitleID}
                          />
                        </Col>
                        <Col sm={12} md={6}>
                          <Label>
                            Job Grade/Band{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            placeholder=""
                            name={"band" + id}
                            defaultValue={data.band}
                            innerRef={register({
                              required: {
                                value: true,
                                message: "This field is required!",
                              },
                            })}
                          />
                          <Input
                            type="hidden"
                            innerRef={register}
                            name={"bandID" + id}
                            defaultValue={data.bandID}
                          />
                        </Col>
                      </FormGroup>
                    </div>
                  </div>
                );
              })}
              <Row>
                <Col>
                  Add Employment History
                  <Button
                    color="success font-weight-bold mb-2 ml-2"
                    style={{
                      borderRadius: "80px",
                      width: "40px",
                      height: "40px",
                    }}
                    onClick={() => setAddMore([...addMore, {}])}
                  >
                    +
                  </Button>
                  {filtered.length > 0 ? (
                    <Button
                      color="danger font-weight-bold mb-2 mx-2"
                      style={{
                        borderRadius: "80px",
                        width: "40px",
                        height: "40px",
                      }}
                      onClick={() => removeMTAddMore()}
                    >
                      -
                    </Button>
                  ) : null}
                </Col>
              </Row>
              <div className="d-flex justify-content-between">
                <div></div>
                <div>
                  <Button className="mr-2" color="success" type="submit">
                    Save
                  </Button>
                  {/* <Button color="success" onClick={() => {}}>
                    Next
                  </Button> */}
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmploymentHistory;
