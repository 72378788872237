import React, { useState, useEffect } from "react";
import {
  Collapse,
  Card,
  CardBody,
  Label,
  Input,
  Button,
  Col,
  Row,
  UncontrolledTooltip,
} from "reactstrap";

import {
  CLIENTS,
  WARDS,
  SHIFTS,
  CREATE_BOOKING,
  HOSTMexxar,
  ClientLable,
} from "../../../configs/api-config";

import axios from "axios";
import moment from "moment";
import { connect } from "react-redux";
import { forEach, isNull, filter } from "lodash";
import NoConnection from "../../errorPages/NoConnection";
import loadingAnimation from "../../../../src/images/puff.svg";
import NoSearchResults from "../../errorPages/NoSearchResults";
import { useIsMounted } from "../../../customHooks/useIsMounted";
import BookingSummeryWorkSpace from "../../../widgets/booking-summery-card-booking-workspace/booking-summery-card-booking-workspace";
import { FunnelIcon } from "../../../assets/icons/svg";
import DateInput from "../../../util/common/DateInput";
import dateFormatter from "../../../util/common/dateFormatter";

const filterByName = (name, dataSet) => {
  let filtered = dataSet;
  if (name !== "") {
    filtered = dataSet.filter((item) => {
      let searchableString = `${item.candidateName} ${item.referenceNo} ${item.candidatesId}   ${item.clientName} ${item.ward.name}   `;
      let p = searchableString;
      return p.toLowerCase().includes(name.toLowerCase());
    });
  }
  let lengthofSearch = filtered.length;
  return { filtered, lengthofSearch };
};
const BookingsShifts = (props) => {
  const { incomingPage, candidateId, auth, employeeID } = props;

  const isMounted = useIsMounted();
  const MexxarApiShifts = SHIFTS;
  const MexxarApi = CREATE_BOOKING;
  const MexxarApiClients = CLIENTS;
  const MexxarApiDepartments = WARDS;

  const currentDate = new Date();

  // const yesterDay = moment(moment(currentDate).subtract(1, "days")).format(
  //   "YYYY-MM-DD"
  // );
  // const tommorow = moment(moment(currentDate).add(10, "days")).format(
  //   "YYYY-MM-DD"
  // );
  const yesterDay = dateFormatter.formatDateToYYYYMMDDSlash(moment(currentDate).subtract(1, "days"));
  const tommorow = dateFormatter.formatDateToYYYYMMDDSlash(moment(currentDate).add(10, "days"));
  const [rows, setRows] = useState([]);
  const [shifts, setShifts] = useState([]);
  const [error, setError] = useState(null);
  const [clients, setClients] = useState([]);
  const [search, setSearch] = useState(false);
  const [loading, setLoading] = useState(true);
  const [departments, setDepartments] = useState([]);
  const [initialRows, setInitialRows] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedDate, setSelectedDate] = useState([]);
  const [getEndDate, setGetEndDate] = useState(tommorow);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [getStartDate, setGetStartDate] = useState(yesterDay);

  const handleSearchOnChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const toggleFilter = () => setIsOpenFilter(!isOpenFilter);

  const getFieldData = () => {
    axios
      .all([
        axios.get(MexxarApiClients),
        axios.get(MexxarApiDepartments),
        axios.get(MexxarApiShifts),
      ])
      .then(
        axios.spread((res1, res2, res3) => {
          //    console.log("Clients : ", res1.data.body);
          //    console.log("Departments : ", res2.data.body);
          //    console.log("Shifts : ", res3.data.body);

          let tempClientsArray = [];
          forEach(res1.data.body, function (item) {
            tempClientsArray.push({
              value: item.name,
              label: item.name,
              id: item.id,
            });
          });

          let tempDepartmentArray = [];
          forEach(res2.data.body, function (item) {
            tempDepartmentArray.push({
              value: item.name,
              label: item.name,
              id: item.id,
            });
          });

          let tempShiftsArray = [];
          forEach(res3.data.body, function (item) {
            tempShiftsArray.push({
              value: item.name,
              label: item.name,
              id: item.id,
            });
          });

          if (isMounted.current) {
            setShifts(tempShiftsArray);
            setClients(tempClientsArray);
            setDepartments(tempDepartmentArray);
          }
        })
      )
      .catch((err) => {
        //console.log(err)
        //toastCommon(true, "error");
      });
  };

  const getAllRows = () => {
    setLoading(true);
    setError(null);

    if (incomingPage == "bookingWorkspace") {
      axios
        .get(
          MexxarApi +
            "/range?userId=" +
            employeeID +
            "&start=" +
            moment(getStartDate).format("YYYY/MM/DD") +
            "&end=" +
            moment(getEndDate).format("YYYY/MM/DD") +
            "&type=EMPLOYEE"
        )
        .then((res) => {
          if (isMounted.current) {
            setLoading(false);
            setRows(res.data.body);
            setInitialRows(res.data.body);
          }
        })
        .catch((err) => {
          setError(err);

          //console.log(err)
          //toastCommon(true, "error");
        });
    } else if (incomingPage == "candidateProfile") {
      axios
        .get(
          MexxarApi +
            "/range?userId=" +
            candidateId +
            "&start=" +
            moment(getStartDate).format("YYYY/MM/DD") +
            "&end=" +
            moment(getEndDate).format("YYYY/MM/DD") +
            "&type=CANDIDATE"
        )
        .then((res) => {
          if (isMounted.current) {
            setLoading(false);
            setRows(res.data.body);
            setInitialRows(res.data.body);
          }
        })
        .catch((err) => {
          setError(err);

          //console.log(err)
          //toastCommon(true, "error");
        });
    } else if (incomingPage == "unconfirmedBookings") {
      axios
        .get(
          MexxarApi +
            "/range?userId=" +
            employeeID +
            "&start=" +
            moment(getStartDate).format("YYYY/MM/DD") +
            "&end=" +
            moment(getEndDate).format("YYYY/MM/DD") +
            "&status=" +
            "UNCONFIRMED" +
            "&type=EMPLOYEE"
        )
        .then((res) => {
          if (isMounted.current) {
            setLoading(false);
            setRows(res.data.body);
            setInitialRows(res.data.body);
          }
        })
        .catch((err) => {
          console.log("error----->", err);
          setError(err);

          //console.log(err)
          //toastCommon(true, "error");
        });
    } else if (incomingPage == "otherShifts") {
      axios
        .get(HOSTMexxar + "otherShift")
        .then((res) => {
          if (isMounted.current) {
            setLoading(false);
            console.log("angi other shifts----->", res.data);
            // setRows(res.data.body);
            // setInitialRows(res.data.body);
          }
        })
        .catch((err) => {
          console.log("error----->", err);
          setError(err);

          //console.log(err)
          //toastCommon(true, "error");
        });
    }
  };

  const handleSearch = () => {
    let w = isNull(selectedDate) ? 0 : selectedDate.length;

    //...filtered candidates according to the date filter......//

    let dateTemp = [];

    let A = filter(initialRows, (row) => {
      return selectedDate === moment(row.bookingDate).format("YYYY-MM-DD");
    });

    if (A.length !== 0) {
      dateTemp.push(...A);
    }

    if (w > 0) {
      setRows(dateTemp);
    } else {
      setRows(initialRows);
    }
  };

  const [resetState, setResetState] = useState(false);

  const reset = () => {
    setGetStartDate(yesterDay);
    setGetEndDate(tommorow);
    setResetState(!resetState);

    // getFieldData();
  };

  useEffect(() => {
    getAllRows();
    // getFieldData();
    return () => {};
  }, [search, resetState]);

  const sortFunctionAscending = (a, b) => {
    var dateA = new Date(a.shiftFrom).getTime();
    var dateB = new Date(b.shiftFrom).getTime();
    return dateA > dateB ? 1 : -1;
  };

  return (
    <>
      <p className="card p-3">
        Confirmed bookings for date range from {getStartDate} to {getEndDate}{" "}
      </p>
      <div className="form-inline">
        <Button onClick={toggleFilter} className="btn bg-dark-lt text-dark">
          <FunnelIcon />
          <span className="mx-1">Filter</span>
        </Button>
        <Input
          id="searchBar"
          onChange={(e) => handleSearchOnChange(e)}
          type="text"
          className="form-control no-border no-shadow no-bg typeahead tt-input"
          placeholder="Search Bookings..."
          autoComplete="off"
          spellCheck="false"
          dir="auto"
          style={{
            position: "relative",
            verticalAlign: "top",
            backgroundColor: "transparent",
            // textTransform: "lowercase",
          }}
        />
        <UncontrolledTooltip target="searchBar">
          Search by Candidate name/Candidate id/Reference No./{ClientLable}/Department
        </UncontrolledTooltip>
      </div>
      <br></br>
      <Collapse isOpen={isOpenFilter}>
        <Card>
          <CardBody>
            <Row>
              <Col>
                <Label for="exampleDate">Start Date</Label>
              
 <DateInput
  id="startDate"
  // value={moment(getStartDate, "YYYY-MM-DD").format("DD/MM/YYYY")}
  // onChange={(date) => setGetStartDate(moment(date, "DD/MM/YYYY").format("YYYY-MM-DD"))}
  value={dateFormatter.formatDateToDDMMYYYY(getStartDate)}
  onChange={(date) => setGetStartDate(dateFormatter.formatDateToYYYYMMDD(date))}
/>
              </Col>
              <Col>
                <Label for="exampleDate">End Date</Label>
              
                   <DateInput
  id="endDate"
  // value={moment(getStartDate, "YYYY-MM-DD").format("DD/MM/YYYY")}
  // onChange={(date) => setGetStartDate(moment(date, "DD/MM/YYYY").format("YYYY-MM-DD"))}
  value={dateFormatter.formatDateToDDMMYYYY(getEndDate)}
  onChange={(date) => setGetEndDate(dateFormatter.formatDateToYYYYMMDD(date))}
/>
              </Col>

              {/* <Col>
                <Label>Hospital</Label>
                <Select
                  // value={candidate.specialities}
                  isMulti
                  name="hospitals"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  options={clients}
                />
              </Col> */}
              {/* <Col>
                <Label>Shift</Label>
                <Select
                  // value={candidate.specialities}
                  isMulti
                  name="DocStatus"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  options={shifts}
                />
              </Col> */}
              {/* <Col>
                <Label>Ward</Label>
                <Select
                  // value={candidate.specialities}
                  isMulti
                  name="ShifTtype"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  options={departments}
                />
              </Col> */}
              <Col></Col>
              {/* <Col>
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" />
                    <Badge href="#" color="primary">
                      All
                    </Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" />{" "}
                    <Badge href="#" color="danger">
                      unconfirm
                    </Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" />{" "}
                    <Badge href="#" color="success">
                      confirm
                    </Badge>
                  </Label>
                </FormGroup>
              </Col> */}
              <Col>
                <div className="d-flex flex-column align-items-end">
                  <Button
                    color="success m-1 btn-sm"
                    style={{ width: "70px" }}
                    // onClick={handleSearch}
                    onClick={() => {
                      setSearch(!search);
                    }}
                  >
                    Search
                  </Button>
                  <Button
                    color="danger m-1 btn-sm"
                    onClick={reset}
                    style={{ width: "70px" }}
                  >
                    Reset
                  </Button>
                </div>
              </Col>
            </Row>
            <Row></Row>
          </CardBody>
        </Card>
      </Collapse>
      {error && (
        <div>
          <NoConnection error={error}></NoConnection>
        </div>
      )}
      {rows && !loading ? (
        filterByName(searchQuery, rows).lengthofSearch > 0 ? (
          filterByName(
            searchQuery,
            rows.sort(sortFunctionAscending)
          ).filtered.map((row, i) => {
            return (
              <BookingSummeryWorkSpace
                key={i}
                userProfile={row}
                reset={() => setResetState(!resetState)}
              ></BookingSummeryWorkSpace>
            );
          })
        ) : (
          <NoSearchResults />
        )
      ) : null}
      {loading && !error && (
        <div className="d-flex justify-content-center align-items-center">
          <div>
            <img src={loadingAnimation} style={{ height: 100 }}></img>
          </div>
        </div>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {})(BookingsShifts);
