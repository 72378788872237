
import { useEffect, useState } from "react";
import axios from "axios";
import useDidMountEffect from "../components/Modal/ModalBody/intialRunUseEffect";
import moment from "moment";

export default function useBookingCancelFetch(
  url,
  query,
  pageNumber,
  searchAdvanced,
  reset,
  status,
  getStartDate,
  getEndDate,
  shiftType
) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [rows, setRows] = useState([]);
  const [totalElements, setTotalElements] = useState(0);
  const [hasMore, setHasMore] = useState(false);

  
  useEffect(() => {
    setRows([]);
  }, [query]);

 
  useDidMountEffect(() => {
    setRows([]);
  }, [searchAdvanced, reset]);

  useEffect(() => {
    const formattedStartDate = getStartDate
      ? moment(getStartDate).format("YYYY/MM/DD")
      : null;
    const formattedEndDate = getEndDate
      ? moment(getEndDate).format("YYYY/MM/DD")
      : null;

    const params = {
      ...(status && { status }),
      ...(formattedStartDate && { startDate: formattedStartDate }),
      ...(formattedEndDate && { endDate: formattedEndDate }),
      ...(shiftType && { shiftType }),
      size: 20,
      page: pageNumber,
      search: query,
    };

    setLoading(true);
    setError(false);

    let cancel;

    axios({
      method: "GET",
      url: url,
      params: params,
      cancelToken: new axios.CancelToken((c) => (cancel = c)),
    })
      .then((res) => {
        if (res.data.status === "success") {
          let data = res.data.body.content;

          // Apply frontend filtering for bookingDate if necessary
          if (formattedStartDate || formattedEndDate) {
            data = data.filter((item) => {
              const bookingDate = new Date(item.bookingDate).getTime();
              const startDate = formattedStartDate
                ? new Date(formattedStartDate).getTime()
                : null;
              const endDate = formattedEndDate
                ? new Date(formattedEndDate).getTime()
                : null;

              return (
                (!startDate || bookingDate >= startDate) &&
                (!endDate || bookingDate <= endDate)
              );
            });
          }

          
          const sortedData = data.sort((a, b) => {
            const dateA = new Date(a.shiftFrom).getTime();
            const dateB = new Date(b.shiftFrom).getTime();
            return dateA - dateB;
          });

          setTotalElements(res.data.body.totalElements);
          setHasMore(data.length > 0);
          setRows((prevBooks) => [...new Set([...prevBooks, ...sortedData])]);
        } else {
          setError(true);
        }
        setLoading(false);
      })
      .catch((e) => {
        if (axios.isCancel(e)) return;
        setLoading(false);
        setError(true);
      });

    return () => cancel();
  }, [url, query, pageNumber, searchAdvanced, reset, status, getStartDate, getEndDate, shiftType]);

  return { loading, error, rows, hasMore, totalElements };
}
