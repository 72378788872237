import React from "react";

import {
  ModalBody,
  ModalFooter,
  ModalHeader,
  Modal,
  Input,
  Button,
  Label,
  Col,
  Row,
} from "reactstrap";
import axios from "axios";
import { Badge } from "reactstrap";
import ToastCommon from "../toastCommon";
import FormGroup from "reactstrap/lib/FormGroup";
import { HOSTMexxar } from "../../configs/api-config";
import { useToasts } from "react-toast-notifications";
import { sendNotification } from "../../util/common/sendNotification";
import DateInput from "../../util/common/DateInput";
import dateFormatter from "../../util/common/dateFormatter";

const EventPreviewModal = (props) => {
  const { addToast } = useToasts();
  const { refresh, show = false, event, closeModal, candidateId } = props;

  const deleteEvent = () => {
    let url = "";
    let notificationType = null;
    let notificationContent = null;

    const eventType = event.title.split(":")[0].trim();

    switch (eventType) {
      case "Event":
        url = `${HOSTMexxar}events/${event.event.id}`;
        notificationType = "EVENT";
        notificationContent = "Event Cancelled";
        break;
      case "Training":
        url = `${HOSTMexxar}training-booking/${event.event.id}`;
        notificationType = "TRAINING";
        notificationContent = "Training Cancelled";
        break;
      case "Booking":
        url = `${HOSTMexxar}bookings/${event.event.id}`;
        notificationType = "BOOKING";
        notificationContent = "Booking Cancelled";
        break;
      default:
        console.error("Invalid event type detected:", eventType);
        return;
    }

    let title = null;
    if (eventType === "Event") {
      const {
        EventName,
        EventStartDate,
        EventEndDate,
        EventStartTime,
        EventEndTime,
      } = event.body;
      title = `Event Cancelled:${EventName} `;
      notificationContent =
        `Event Details:\n` +
        `Name: ${EventName}\n` +
        `Start Date: ${EventStartDate} at ${EventStartTime}\n` +
        `End Date: ${EventEndDate} at ${EventEndTime}`;
    } else if (eventType === "Training") {
      console.log("Event Body:", event.body);

      const trainingName =
        event.event.trainings.length > 0 && event.event.trainings[0].name
          ? event.event.trainings[0].name
          : "Unknown Training";

      const { EventStartDate, EventEndDate, EventStartTime, EventEndTime } =
        event.body;

      title = `Training Cancelled: ${trainingName}`;
      notificationContent =
        `Training Details:\n` +
        `Name: ${trainingName}\n` +
        `Start Date: ${EventStartDate} at ${EventStartTime}\n` +
        `End Date: ${EventEndDate} at ${EventEndTime}`;
    }

    const candidateId =
      event.event.candidates && event.event.candidates.length > 0
        ? event.event.candidates[0].id
        : null;

    const notificationData = {
      notificationType: notificationType,
      title: title,
      content: notificationContent,
      candidateId: candidateId,
      startDate: event.startDate,
      addToast: addToast,
    };

    if (candidateId) {
      sendNotification(notificationData);
    }

    axios
      .delete(url)
      .then((res) => {
        if (res.data.status === "success" || res.data.message === "created") {
          ToastCommon(true, "eventDeleted", addToast);
          refresh();
          closeModal(!show);
        } else {
          console.error("Failed to delete event:", res.data.status);
        }
      })
      .catch((error) => {
        console.error("Error deleting event:", error);
      });
  };

  if (
    event &&
    event.event &&
    event.event.candidates &&
    event.event.candidates.length > 0
  ) {
    const candidateId = event.event.candidates[0].id;
  } else {
    console.log("No candidate details found in the event");
  }

  return (
    <>
      <Modal isOpen={show} toggle={() => closeModal(false)}>
        <ModalHeader
          className="modal-header bg-primary"
          close={
            <button className="close" onClick={() => closeModal(false)}>
              ×
            </button>
          }
        >
          {event === undefined ? "" : event.title}{" "}
        </ModalHeader>

        <ModalBody className="p-4">
          <FormGroup row>
            <Label sm={4}>Start Date </Label>
            <Col sm={8}>
              {event === undefined ? (
                ""
              ) : (
                <DateInput
                  id="date"
                  invalid={false}
                  defaultValue={dateFormatter.formatDateToDDMMYYYY(
                    event.body.EventStartDate
                  )}
                  readOnly={true}
                  value={dateFormatter.formatDateToDDMMYYYY(
                    event.body.EventStartDate
                  )}
                />
              )}
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={4}>Start Time </Label>
            <Col sm={8}>
              {event === undefined ? (
                ""
              ) : (
                <Input
                  type="time"
                  name="rowName"
                  className="capitalize"
                  value={event.body.EventStartTime}
                  disabled={true}
                ></Input>
              )}
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={4}>End Date</Label>
            <Col sm={8}>
              {event === undefined ? (
                ""
              ) : (
                <DateInput
                  id="date"
                  invalid={false}
                  defaultValue={dateFormatter.formatDateToDDMMYYYY(
                    event.body.EventEndDate
                  )}
                  readOnly={true}
                  value={dateFormatter.formatDateToDDMMYYYY(
                    event.body.EventEndDate
                  )}
                />
              )}
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={4}>End Time </Label>
            <Col sm={8}>
              {event === undefined ? (
                ""
              ) : (
                <Input
                  type="time"
                  name="rowName"
                  className="capitalize"
                  value={event.body.EventEndTime}
                  disabled={true}
                ></Input>
              )}
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={4}>Recorded Employee</Label>
            <Col sm={8}>
              {event === undefined ? (
                ""
              ) : (
                <Input
                  ttype="date"
                  name="rowName"
                  className="capitalize"
                  value={event.body.recordedEmployeeName}
                  disabled={true}
                  // style={{ padding: "5px", width: "27rem" }}
                ></Input>
              )}
            </Col>
          </FormGroup>

          {event !== undefined && event !== null ? (
            event.trainings !== undefined && event.trainings !== null ? (
              <Row sm={12}>
                <Col sm={12}>
                  <div className="pt-2">
                    <span>
                      <div style={{ padding: "5px" }}>Trainings </div>
                      {event.trainings.map((training, id) => {
                        return (
                          <Badge color="secondary" key={id}>
                            {training}
                          </Badge>
                        );
                      })}
                    </span>
                  </div>
                </Col>
              </Row>
            ) : null
          ) : null}
          <Row sm={12}>
            <Col sm={12}>
              <div className="pt-2">Assigned Candidates: </div>
              {event !== undefined && event !== null ? (
                event.event.candidates !== undefined &&
                event.event.candidates !== null ? (
                  event.event.candidates.map((candidate, id) => {
                    return (
                      <Badge
                        color="success"
                        className="mr-1"
                        key={id}
                      >{`${candidate.firstName} ${candidate.lastName} - id : ${candidate.id}`}</Badge>
                    );
                  })
                ) : (
                  <Badge
                    color="success"
                    className="mr-1"
                  >{`${event.event.candidateName} - id : ${event.event.candidatesId}`}</Badge>
                )
              ) : null}
            </Col>
          </Row>

          <Row sm={12}>
            <Col sm={12}>
              <div className="pt-2">Assigned Employees: </div>

              {event !== undefined && event !== null ? (
                event.event.employees !== undefined &&
                event.event.employees !== null ? (
                  event.event.employees.map((employee, id) => {
                    return (
                      <Badge
                        color="secondary"
                        className="mx-1"
                        key={id}
                      >{`${employee?.firstName} ${employee?.lastName} - id : ${employee?.id}`}</Badge>
                    );
                  })
                ) : (
                  <></>
                  // <Badge color="info">{`${event.event.candidateName} id : ${event.event.candidatesId}`}</Badge>
                )
              ) : null}
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="px-4">
          <Button
            color="danger"
            onClick={() => {
              deleteEvent();
            }}
          >
            Delete Event
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              closeModal(false);
            }}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default EventPreviewModal;
