import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Button,
  Col,
  Modal,
  Label,
  Input,
  UncontrolledTooltip,
  Spinner,
  Row,
  CardBody,
  Card,
  Collapse,
} from "reactstrap";
import axios from "axios";
import moment from "moment";
import ModalComponent from "../../components/Modal/modalComponent";
import TimeSheet from "../../components/Modal/ModalBody/timeSheet";
import SmallCalendar from "../../components/Modal/ModalBody/smallCalendar";
import { ClientLable, HOSTMexxar } from "../../configs/api-config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import loadingAnimation from "../../../src/images/puff.svg";
import NoSearchResults from "../../pages/errorPages/NoSearchResults";
import NoConnection from "../../pages/errorPages/NoConnection";
import SortFunctionAscending from "../../components/sortFunction/sortByDateAscending";
import { useSpring, animated } from "react-spring";
import { filter } from "lodash";
import useDidMountEffect from "../../components/Modal/ModalBody/intialRunUseEffect";
import ShowReport from "../../components/Modal/ModalBody/showReport";
import usePayroll from "../../customHooks/usePayroll";
import { FunnelIcon } from "../../assets/icons/svg";
import ToastCommon from "../../components/toastCommon";
import { useToasts } from "react-toast-notifications";
import TimeSheetShow from "../../components/Modal/ModalBody/timeSheetShow";
import dateFormatter from "../../util/common/dateFormatter";
import DateInput from "../../util/common/DateInput";
const EmployeePayrollWidgetVerified = () => {
  const MexxarAPI = HOSTMexxar;

  const currentDate = new Date();
 
  const defaultStartDay = dateFormatter.formatDateToYYYYMMDDSlash(
    moment(currentDate).subtract(30, "days")
  );
  const tommorow = dateFormatter.formatDateToYYYYMMDDSlash(
    moment(currentDate).add(1, "days")
  );
  const [data, setData] = useState();
  const [docURL, setDocURL] = useState();
  // const [rows, setRows] = useState([]);
  // const [error, setError] = useState(false);
  const [showpdf, setShowpdf] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [query, setQuery] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [calendarShow, setCalendarShow] = useState(false);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [showTimeModal, setShowTimeModal] = useState(false);

  const [searchAdvanced, setSearchAdvanced] = useState(false);

  const [pageNumber, setPageNumber] = useState(1);
  const [resetResults, setResetResults] = useState(false);

  const [getEndDate, setGetEndDate] = useState(tommorow);
  const [getStartDate, setGetStartDate] = useState(defaultStartDay);

  const { addToast } = useToasts();

  const handleCalendarShow = (calendarShow) => setCalendarShow(calendarShow);

  const toggleShowTimeModal = (data) => {
    setData(data);
    setShowTimeModal(!showTimeModal);
  };

  let url =
    MexxarAPI + "workspace/payrollfilter?ps=PENDING&hrs=PENDING&rs=VERIFIED";

  const { loading, error, rows, hasMore, totalElements } = usePayroll(
    url,
    query,
    pageNumber,
    searchAdvanced,
    moment(getStartDate).format("YYYY/MM/DD"),
    moment(getEndDate).format("YYYY/MM/DD"),
    resetResults
  );

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  console.log("totalElements.....", totalElements);
  const reset = () => {
    setPageNumber(1);
    setResetResults(!resetResults);
    setGetStartDate(defaultStartDay);
    setGetEndDate(tommorow);
  };

  const handleSearchOnChange = (e) => {
    setPageNumber(1);
    setQuery(e.target.value);
  };

  useEffect(() => {
    // getCandidates();
    return () => {};
  }, []);

  const isSelected = (id) => {
    let slectedRows = filter(selectedItems, (item) => {
      return item.bookingReference == id;
    });

    if (slectedRows.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  function prepEndDate(data) {
    let sTime = data.startTime.split(":")[0];
    let eTime = data.endTime.split(":")[0];

    let endDate;

    if (sTime < eTime) {
      endDate = moment(data.dateOfShift).format("DD/MM/YYYY");
    } else {
      endDate = moment(data.dateOfShift).add(1, "days").format("DD/MM/YYYY");
    }
    return endDate;
  }

  const checkAll = () => {
    let ids = [];
    if (selectAll) {
      setSelectAll(false);
      setSelectedItems([]);
    } else {
      rows.forEach((item) => {
        ids.push({
          bookingReference: item.bookingRef,
          //use to test    bookingReference: "5412540024219654",
          startTime: item.startTime + ":00",
          endTime: item.endTime + ":00",
          startDate: moment(item.dateOfShift).format("DD/MM/YYYY"),
          endDate: prepEndDate(item),
        });
      });
      setSelectedItems(ids);
      setSelectAll(true);
    }
  };

  const onItemSelect = (item) => {
    let data = {
      bookingReference: item.bookingRef,
      //use to test    // bookingReference: "5412540024219654",
      startTime: item.startTime + ":00",
      endTime: item.endTime + ":00",
      startDate: moment(item.dateOfShift).format("DD/MM/YYYY"),
      endDate: prepEndDate(item),
    };

    let slectedRows = filter(selectedItems, (item) => {
      return item.bookingReference == data.bookingReference;
    });

    if (slectedRows.length > 0) {
      let removeItems = filter(selectedItems, (item) => {
        return item.bookingReference != data.bookingReference;
      });
      setSelectedItems([...removeItems]);
      setSelectAll(false);
      return false;
    } else {
      setSelectedItems([...selectedItems, data]);
      return true;
    }
  };

  const [pdfPending, setPdfPending] = useState(false);
  const [pdfError, setPdfError] = useState(false);
  const [pdf, setPdf] = useState();

  const processTimesheet = () => {
    if (selectedItems.length > 0) {
      setPdfPending(true);

      console.log("angi payrole pdf ----->", selectedItems);
      axios
        .post(MexxarAPI + "workspace/payrollPDF", selectedItems)
        .then((res) => {
          setPdfPending(false);

          getProcessedPayroll();
        })
        .catch((err) => {
          setPdfError(true);
        });
    }
  };

  const getProcessedPayroll = () => {
    setPdfPending(true);

    axios({
      url: MexxarAPI + "workspace/payrollPDF", //your url
      method: "GET",
      responseType: "blob", // important
    })
      .then((res) => {
        setPdfPending(false);

        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `payrollReport(${new Date()}).pdf`); //or any other extension
        document.body.appendChild(link);
        link.click();

        setPdf(res.data);
        setShowpdf(true);
      })
      .catch((err) => {
        setPdfError(true);
      });
  };

  const animationProps = useSpring({
    opacity: 1,
    transform: "translate(0px, 0px)",
    from: { opacity: 0, transform: "translate(-100px, 0px)" },
  });

  const RejectTimesheet = () => {
    axios
      .patch
      // `${HOSTMexxar}timesheets/rejectTimesheet/${booking.id}?status=DECLINE&rejectReason=Your Time Sheet Rejected`
      ()

      .then((res) => {
        if (res.data.status === "Success") {
          ToastCommon(true, "success", addToast);
          reset();
        }
      })
      .catch((err) => {
        ToastCommon(true, "error", addToast);
      });
  };

  return (
    <div>
      <div className="form-inline mb-2">
        <>
          <label className="ui-check ml-2">
            <Input
              type="checkbox"
              name="id"
              checked={selectAll}
              disabled={rows.length === 0}
              onChange={() => checkAll()}
            />
            <i></i>
          </label>
          <Label className="text-color text-sm mr-2 mt-1" id="selectAll">
            Select All
          </Label>
          <Button onClick={processTimesheet} color="success">
            {pdfPending && (
              <Spinner
                animation="border"
                style={{
                  height: "20px",
                  width: "20px",
                  marginRight: "5px",
                }}
              />
            )}
            Process
          </Button>
        </>

        <FontAwesomeIcon icon={faFileAlt} className={"ml-2 mr-3  "} />
        <Label className={"mr-3"}>
          {totalElements} {totalElements == 1 ? "Timesheet" : "Timesheets"}
        </Label>
        <Button
          onClick={() => setIsOpenFilter(!isOpenFilter)}
          className="btn bg-dark-lt text-dark"
        >
          <FunnelIcon />
          <span className="mx-1">Filter</span>
        </Button>

        <Input
          onChange={(e) => handleSearchOnChange(e)}
          type="text"
          id="searchBar"
          className="form-control no-border no-shadow no-bg typeahead tt-input"
          placeholder="Search Payroll..."
          autoComplete="off"
          spellCheck="false"
          dir="auto"
          style={{
            position: "relative",
            verticalAlign: "top",
            backgroundColor: "transparent",
            // textTransform: "lowercase",
          }}
        />
      </div>
      <UncontrolledTooltip target="searchBar">
        Search candidates by Name/Id and {ClientLable}s by Name/Id
      </UncontrolledTooltip>

      <Collapse isOpen={isOpenFilter}>
        <Card>
          <CardBody>
            <Row>
              <Col>
                <Label for="exampleDate">Start Date</Label>

                <DateInput
                  id="startDate"
                  value={dateFormatter.formatDateToDDMMYYYY(getStartDate)}
                  onChange={(date) =>
                    setGetStartDate(dateFormatter.formatDateToYYYYMMDD(date))
                  }
                />
              </Col>
              <Col>
                <Label for="exampleDate">End Date</Label>

                <DateInput
                  id="endDate"
                  value={dateFormatter.formatDateToDDMMYYYY(getEndDate)}
                  onChange={(date) =>
                    setGetEndDate(dateFormatter.formatDateToYYYYMMDD(date))
                  }
                />
              </Col>

              <Col>
                <div className="d-flex flex-column align-items-end">
                  <Button
                    color="success m-1 btn-sm"
                    style={{ width: "70px" }}
                    // onClick={handleSearch}
                    onClick={() => {
                      setPageNumber(1);
                      setSearchAdvanced(!searchAdvanced);
                    }}
                  >
                    Search
                  </Button>
                  <Button
                    color="danger m-1 btn-sm"
                    onClick={reset}
                    style={{ width: "70px" }}
                  >
                    Reset
                  </Button>
                </div>
              </Col>
            </Row>
            <Row></Row>
          </CardBody>
        </Card>
      </Collapse>

      {error && (
        <div>
          <NoConnection error={error}></NoConnection>
        </div>
      )}

      {rows &&
        rows.map((row, id) => {
          return (
            <animated.div
              style={animationProps}
              ref={lastBookElementRef}
              key={id}
            >
              <div className="card mb-2 " key={id}>
                <div className="card-body">
                  <div className="flex flex-row d-flex justify-content-between ">
                    <div className="d-flex  " style={{ width: "250px" }}>
                      <div className="d-flex align-items-center mr-3 ">
                        <span className="mx-2">
                          <label className="ui-check m-0">
                            <input
                              type="checkbox"
                              name="id"
                              checked={isSelected(row.bookingRef)}
                              onChange={() => onItemSelect(row)}
                            />{" "}
                            <i></i>
                          </label>
                        </span>
                      </div>
                      <div>
                        <div>{row.booking.candidateName}</div>

                        <div className="item-mail text-muted h-1x d-none d-sm-block">
                          <small>
                            Booking Date :{" "}
                            {moment(row.booking.bookingDate).format("llll")}
                          </small>
                        </div>
                      </div>
                    </div>
                    <div
                      className="d-flex align-items-center"
                      style={{ width: "60px" }}
                    ></div>

                    <div className="textCenter-Custom">
                      <div>{ClientLable} Name</div>
                      <div className="text-muted">{row.hospitalName}</div>
                    </div>
                    <div className="textCenter-Custom">
                      <div>Submitted Date</div>
                      <div className="text-muted">
                        {moment(row.submitedDate).format("llll")}
                      </div>
                    </div>

                    <div>
                      <Button
                        color="success"
                        className="mr-2"
                        onClick={() => {
                          toggleShowTimeModal(row);
                          setDocURL(row?.documentUrl);
                        }}
                      >
                        Show
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </animated.div>
          );
        })}

      {rows.length === 0 && !loading && <NoSearchResults />}

      {loading && !error && (
        <div className="d-flex justify-content-center align-items-center">
          <div>
            <img src={loadingAnimation} style={{ height: 100 }}></img>
          </div>
        </div>
      )}

      <ModalComponent
        show={calendarShow}
        header="Log new call back"
        closeModal={() => handleCalendarShow(false)}
      >
        <SmallCalendar />
        <div className="mt-3">
          <Button color="success">Send</Button>{" "}
          <Button color="secondary" onClick={() => handleCalendarShow(false)}>
            Cancel
          </Button>
        </div>
      </ModalComponent>
      <ModalComponent
        show={showTimeModal}
        header="Time Sheet"
        size="lg"
        closeModal={() => setShowTimeModal(false)}
      >
        <TimeSheetShow
          data={data}
          docURL={docURL}
          closeModal={() => setShowTimeModal(false)}
        ></TimeSheetShow>
        <div className="mt-4">
          <Col sm={{ size: 8, offset: 8 }}></Col>
        </div>
      </ModalComponent>
      <ModalComponent
        show={showpdf}
        header="Send Report"
        closeModal={() => setShowpdf(false)}
      >
        <ShowReport
          report={pdf}
          payroll={true}
          closeModal={() => setShowpdf(false)}
        ></ShowReport>
      </ModalComponent>
    </div>
  );
};

export default EmployeePayrollWidgetVerified;
