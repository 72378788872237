import React from "react";
import { connect } from "react-redux";
import { ModalBody, ModalFooter, ModalHeader, Modal, Button } from "reactstrap";

const ModalComponent = (props) => {
  const {
    modalActions,
    children,
    header,
    show = false,
    closeModal,
    className,
    scroll = true,
    size,
    heightFactor="fit-content",
    disableBackdropClick = false, 
  } = props;

  return (
    <>
      <Modal
        isOpen={show}
        className={className}
        scrollable={scroll}
        size={size}
        style={{  overflow: 'auto', height:heightFactor }}
        toggle={closeModal}
        backdrop={disableBackdropClick ? "static" : true}
      >
        {header && (
          <ModalHeader
            className="modal-header bg-primary"
            close={
              <button className="close" onClick={closeModal}>
                ×
              </button>
            }
          >
            <span style={{ color: "#ffffff" }}>{header}</span>
          </ModalHeader>
        )}
        <ModalBody className="p-4">{children}</ModalBody>
        {modalActions && (
          <ModalFooter className="p-4">{modalActions}</ModalFooter>
        )}
      </Modal>
    </>
  );
};

function mapStateToProps(state) {
  return {
    height: state.appSettings.windowHeight,
  };
}

export default connect(mapStateToProps, {})(ModalComponent);
