import {
  faTrashAlt,
  faEdit,
  faCheckCircle,
  faCircleNotch,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import moment from "moment";
import filter from "lodash/filter";
import { connect } from "react-redux";
import { TODO } from "../../configs/api-config";
import { Button, Alert, Input } from "reactstrap";
import loading from "../../../src/images/puff.svg";
import React, { useState, useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import ToastCommon from "../../components/toastCommon";
import Delete from "../../components/Modal/ModalBody/delete";
import NoConnection from "../../pages/errorPages/NoConnection";
import FormCheckLabel from "react-bootstrap/esm/FormCheckLabel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalComponent from "../../components/Modal/modalComponent";
import TodoModal from "../../components/Modal/ModalBody/Admin/todoModal";
const TodoWidget = (props) => {
  //API
  const { auth } = props;
  const MexxarApi = TODO;
  const [rows, setRows] = useState([]);
  const [rowID, setRowID] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [rowNameData, setRowNameData] = useState([]);
  const [showAddRow, setShowAddRow] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [showUpdateRow, setShowUpdateRow] = useState(false);
  const [showDeleteRow, setShowDeleteRow] = useState(false);
  const [showDeleteAll, setShowDeleteAll] = useState(false);

  //++++++Error Handling+++++//
  const [error, setError] = useState(null);
  const [pageLoading, setPageLoading] = useState(false);

  const { addToast } = useToasts();

  const addRow = (data, status) => {
    let createDate = moment(data.notifyDate).add(data.notifyTime).toISOString();
    axios
      .post(MexxarApi, {
        userId: auth.userTypeId,
        createDate: new Date(),
        notifyDate: createDate,
        body: data.todo,
      })
      .then((res) => {
        if (res.data.status == "success") {
          getAllRows();
          setShowAddRow(!showAddRow);
          ToastCommon(true, "success", addToast);
        } else {
          ToastCommon(true, "duplicate", addToast);
        }
      })
      .catch((err) => {
        //console.log(err)
        ToastCommon(true, "error", addToast);
      });
  };

  const deleteRow = () => {
    //console.log(rowID);

    axios
      .delete(MexxarApi + "/" + rowID)
      .then((res) => {
        getAllRows();
        ToastCommon(true, "delete", addToast);
      })
      .catch((err) => {
        //console.log(err)
        ToastCommon(true, "update", addToast);
      });
  };

  const updateTodoStatus = (row, status) => {
    axios
      .post(MexxarApi, {
        id: row.id,
        userId: auth.userTypeId,
        createDate: row.createDate,
        notifyDate: row.createDate,
        body: row.body,
        done: status,
      })
      .then((res) => {
        if (res.data.status == "success") {
          getAllRows();

          ToastCommon(true, "update", addToast);
        } else {
          ToastCommon(true, "duplicate", addToast);
        }
      })
      .catch((err) => {
        //console.log(err)
        ToastCommon(true, "error", addToast);
      });
  };

  const getAllRows = () => {
    setPageLoading(true);
    let currentDate = moment(new Date()).format("YYYY/MM/DD");
    axios
      .get(MexxarApi + "/filter-by?notifyDate=" + currentDate)
      .then((res) => {
     
        setRows(res.data.body);
        setPageLoading(false);
      })

      .catch((err) => {
        //console.log(err)
        setError(err);
        //  ToastCommon(true, "error", addToast);
      });
  };

  useEffect(() => {
    getAllRows();
    return () => {};
  }, []);

  if (pageLoading && !error)
    return (
      <div className="d-flex justify-content-center align-items-center">
        <div>
          <img src={loading} style={{ height: 100 }}></img>
        </div>
      </div>
    );

  if (error)
    return (
      <div>
        <NoConnection error={error}></NoConnection>
      </div>
    );

  return (
    <>
      <div className="d-flex justify-content-between pb-2 pr-1  ml-3">
        <div>
          {selectedItems.length > 0 ? (
            <Button
              color="danger"
              size="sm"
              onClick={() => {
                setShowDeleteAll(true);
              }}
            >
              Delete
            </Button>
          ) : null}
        </div>
        <div>
          <Button
            color="primary"
            size="sm"
            onClick={() => {
              setShowAddRow(true);
            }}
          >
            Add
          </Button>
        </div>
      </div>
      {rows.length === 0 && (
        <Alert>You currently have no items in your To Do list</Alert>
      )}

      {rows.length > 0 &&
        rows
          .map((row, i) => {
            return (
              <div key={i} className="card mb-2 pr-1">
                <div className="b-2" key={i}>
                  <div className="p-1 ">
                    <div className=" list-item ">
                      <div className="timeline timeline-theme animates animates-fadeInUp">
                        <div className="tl-item active d-flex justify-content-between">
                          <div className="d-flex">
                            <div className="mr-3">
                              {row.done ? (
                                <FontAwesomeIcon
                                  icon={faCheckCircle}
                                  color={"#31c971"}
                                  style={{
                                    boxShadow: "none",
                                  }}
                                  className="btn-raised btn-wave pointer"
                                  onClick={() => {
                                    updateTodoStatus(row, false);
                                  }}
                                ></FontAwesomeIcon>
                              ) : (
                                <FontAwesomeIcon
                                  icon={faCircleNotch}
                                  color={"#f4c414"}
                                  style={{
                                    boxShadow: "none",
                                  }}
                                  className="btn-raised btn-wave pointer"
                                  onClick={() => {
                                    setRowID(row.id);
                                    updateTodoStatus(row, true);
                                  }}
                                ></FontAwesomeIcon>
                              )}
                            </div>
                            <div className="flex">
                              <div className="">{row.body}</div>
                              <div
                                className="tl-date text-muted mt-1"
                                style={{ maxWidth: "300px" }}
                              >
                                {row.notifyDate == undefined
                                  ? null
                                  : moment(row.notifyDate).format(
                                      "ddd MMM DD YYYY"
                                    ) +
                                    " " +
                                    "@" +
                                    " " +
                                    moment(row.notifyDate).format("hh:mm a")}
                              </div>
                            </div>
                          </div>

                          <div className="d-flex align-items-center justify-content-center">
                            <FontAwesomeIcon
                              // color="#FF5603"
                              icon={faTrashAlt}
                              onClick={() => {
                                setShowDeleteRow(true);
                                setRowID(row.id);
                              }}
                              className="shadow-none fa-lg  mx-3 btn-raised btn-wave pointer "
                              size="1x"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
          .reverse()}

      <ModalComponent
        show={showAddRow}
        header="Add New To Do Item"
        closeModal={() => setShowAddRow(false)}
      >
        <TodoModal
          mode="Add"
          modalType="To Do Item"
          closeModal={() => setShowAddRow(false)}
          onCreate={(data) => addRow(data)}
        />
      </ModalComponent>
      <ModalComponent
        show={showUpdateRow}
        header="Update To Do"
        closeModal={() => setShowUpdateRow(false)}
      >
        {/* <NewOneField
          mode="Update"
          modalType="Todo"
          fieldData={rowNameData}
          closeModal={() => setShowUpdateRow(!showUpdateRow)}
          onCreate={(data) => updateRow(data)}
        /> */}
      </ModalComponent>
      <Delete
        show={showDeleteRow}
        isConfirm={() => {
          deleteRow();
          setShowDeleteRow(!showDeleteRow);
        }}
        closeModal={() => setShowDeleteRow(false)}
      />
      {/* <Delete
        show={showDeleteAll}
        isConfirm={() => {
          deleteAllRows();
          setShowDeleteAll(!showDeleteAll);
        }}
        closeModal={() => setShowDeleteAll(!showDeleteAll)}
      /> */}
    </>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth.item,
  };
}

export default connect(mapStateToProps, {})(TodoWidget);
