import React, { useState } from "react";
import axios from "axios";
import { forEach } from "lodash";
import { useEffect } from "react";
import Select from "react-select";
import { useForm } from "react-hook-form";
import {
  CANDIDATE_SPECIALITY,
  TRAININGPROVIDERS,
} from "../../../../configs/api-config";
import { Button, Label, Input, Form, FormGroup, Col, Row } from "reactstrap";
import DateInput from "../../../../util/common/DateInput";
import dateFormatter from "../../../../util/common/dateFormatter";
import moment from "moment";

export default function BankHolidayField(props) {


  const { register, handleSubmit, errors } = useForm();
  const {
    onCreate,
    closeModal,
    mode,
    modalType,
    modalType1,
    modalType2,
    fieldData,
    rowNameData,
    rowNameDay

  } = props;


  const [addKillLog, setaddKillLog] = useState(false);
  const [rowDate, setRowDate] = useState(null);

 
  const onSubmit = (data) => {
    console.log(data)
    setaddKillLog(true);
    setRowDate(data.rowDate);
  const formattedRowDate = rowDate ? dateFormatter.formatDateToYYYYMMDD(rowDate) : null;
    let arrangedData = {
      rowName: data.rowName,
      rowDate: formattedRowDate,
      rowDay: data.rowDay,
    };
    onCreate(arrangedData);
  
  };



  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup row>
        <Label sm={4}>{modalType}</Label>
        <Col sm={8}>
          <Input
            type="text"
            name="rowName"
            className="capitalize"
            defaultValue={fieldData}
            invalid={errors.bName}
            innerRef={register({
              required: {
                value: true,
                message: "This field is required!",
              },
              maxLength: {
                value: 150,
                message: "This field only allowed only 150 characters",
              },
            })}
          ></Input>
        </Col>

      </FormGroup>

      <FormGroup row>
        <Label sm={4}>{modalType1}</Label>
        <Col sm={8}>
        
   <DateInput
        id="rowDate"
        invalid={false} 
        register={() => {}} 
        value={rowDate} 
        onChange={setRowDate}
  
      />

        </Col>
      </FormGroup>


      <Row className="justify-content-end">

        <div className="d-flex ">
          {" "}
          <Button
            className="mr-2"
            color="success"
            type="submit"
            disabled={addKillLog}
          >
            {mode}
          </Button>{" "}


          <Button color="secondary" onClick={() => closeModal()}>
            Close
          </Button>
        </div>
      </Row>
    </Form>
  );
}
