import moment from "moment";

class DateFormatter {
  static instance = null;

  constructor() {
    if (DateFormatter.instance) {
      return DateFormatter.instance; 
    }
    DateFormatter.instance = this; 
  }

  formatDateToYYYYMMDD(date) {
    const formattedDate = moment(date, "DD/MM/YYYY").format("YYYY-MM-DD");
    return formattedDate === "Invalid date" ? "" : formattedDate;
  }
  formatDateToYYYYMMDDSlash(date) {
    const formattedDate = moment(date, "DD-MM-YYYY").format("YYYY/MM/DD");
    return formattedDate === "Invalid date" ? "" : formattedDate;
  }
  formatDateToDDMMYYYY(date) {
    const formattedDate = moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
    return formattedDate === "Invalid date" ? "" : formattedDate;
  }
  formatDateToDDMMYYYYFROMYYYYMMDDSLASH(date) {
    const formattedDate = moment(date, "YYYY/MM/DD").format("DD/MM/YYYY");
    return formattedDate === "Invalid date" ? "" : formattedDate;
  }
  formatDateToYYYYMMDDSLASHFROMDDMMYYYY(date) {
    const formattedDate = moment(date, "DD/MM/YYYY").format("YYYY/MM/DD");
    return formattedDate === "Invalid date" ? "" : formattedDate;
  }
  formatDateToMMDDYYYY(date) {
    const formattedDate = moment(date, "YYYY-MM-DD").format("MM/DD/YYYY");
    return formattedDate === "Invalid date" ? "" : formattedDate;
  }
}

const dateFormatter = new DateFormatter();
export default dateFormatter;
