import React, { useState, useEffect } from "react";
import {
  CANDIDATE_BAND,
  CANDIDATE_SPECIALITY,
  CANDIDATE_ROLE,
  REGISTER_CANDIDATE,
  EMPLOYEE_PERMISSIONS,
  HOSTMexxar,
  CVSOURCE,
} from "../../../configs/api-config";
import axios from "axios";
import "./register.style.css";
import { filter, forEach } from "lodash";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { useToasts } from "react-toast-notifications";
import ToastCommon from "../../../components/toastCommon";
import { FormGroup, Label, Input, Button, Spinner, Alert } from "reactstrap";
import DashboardLayout from "../../../layouts/dashboard-layout/dashboard";
import useDidMountEffect from "../../../components/Modal/ModalBody/intialRunUseEffect";
import Select, { OnChangeValue } from "react-select";
import usePostalCodeValidation from "../../../customHooks/usePostalCodeValidation";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import dateFormatter from "../../../util/common/dateFormatter";
import DateInput from "../../../util/common/DateInput";

const onFormSubmit = (reguser, formData) => {
  formData.specialtyId = parseInt(formData.specialtyId);
  formData.roleId = parseInt(formData.roleId);
  formData.gender = parseInt(formData.gender);
  formData.bandId = parseInt(formData.bandId);
  reguser(formData);
};

const HeadhunterRegister1 = (props) => {
  const MexxarApiBand = CANDIDATE_BAND;
  const MexxarApiRole = CANDIDATE_ROLE;
  const MexxarApi = REGISTER_CANDIDATE;
  const MexxarApiCVSources = CVSOURCE;
  const MexxarApiEmployee = EMPLOYEE_PERMISSIONS;
  const MexxarApiSpeciality = CANDIDATE_SPECIALITY;

  const { register, handleSubmit, watch, errors } = useForm();
  const { headhunter, auth } = props;
  const { addToast } = useToasts();


  const location = useLocation();
  const { user } = location?.state;
  const [band, setBand] = useState([]);
  const [cvSources, setCvSources] = useState([]);



  const [payementMethod, setPayementMethod] = useState([
    { id: "PAYEE", name: "Payee" },
    { id: "LIMITED", name: "Limited" },
    { id: "UMBRELLA", name: "Umbrella" },
  ]);
  const [role, setRole] = useState([]);

  const [selectedSpecialitiesError, setSelectedSpecialitiesError] =
    useState(false);
  const [selectedSpecialities, setSelectedSpecialities] = useState(null);

  const [value, setValue] = useState("");
  const [conflict, setConflict] = useState(false);
  const [speciality, setSpeciality] = useState([]);
  const [assignID, setAssignID] = useState({ id: 0 });
  const [refferalNote, setRefferalNote] = useState("");
  const [referralPerson, setReferralPerson] = useState(null);
  const [headunterName, setHeadunterName] = useState([]);
  const [selectedRole, setSelectedRole] = useState([]);
  const [selectedSource, setSelectedSource] = useState([]);


  const {
    loadingPostalCode,
    errorPostCode,
    postalData,
    setPostalData,
    setPostalCode,
  } = usePostalCodeValidation();

  useDidMountEffect(() => {
    //referal code length is 6.
    if (refferalNote.length === 6) {
      axios
        .get(HOSTMexxar + "candidates/referral/" + refferalNote)
        .then((res) => {
          let name;
          if (res.data.status === "success") {
            name =
              res.data.body.user.firstName + " " + res.data.body.user.lastName;
            setReferralPerson(name);
          } else {
            setReferralPerson("Referral code not valid");
          }
        })
        .catch((err) => {
          setReferralPerson("Something went wrong.Please Retry");
        });
    }

    if (refferalNote.length === 0) {
      setReferralPerson(null);
    }
    return () => { };
  }, [refferalNote]);

  const changeHandler = ({ target }) => {
    setValue(target.value.toUpperCase());
  };



  const onSubmit = (data, e) => {
    let specialityIds = [];

    forEach(selectedSpecialities, function (speciality) {
      specialityIds.push(speciality?.id);
    });


    let conflictTemp;

    if (data.gender == "Female" && data.title == "Mr") {
      setConflict(true);
      conflictTemp = true;
    } else if (data.gender == "Male" && data.title != "Mr") {
      setConflict(true);
      conflictTemp = true;
    } else {
      setConflict(false);

      conflictTemp = false;
    }

    if (conflictTemp) {
      ToastCommon(true, "fieldCheck", addToast);
    }

    if (specialityIds.length == 0) {
      setSelectedSpecialitiesError(true);
    } else {
      setSelectedSpecialitiesError(false);
    }

    if (conflictTemp == false && specialityIds.length) {
      axios
        .post(MexxarApi, {
          firstName: data.firstName.replace(/\s+/g, ""),
          middleName: data.middleName.replace(/\s+/g, ""),
          lastName: data.lastName.replace(/\s+/g, ""),
          email: data.email.replace(/\s+/g, ""),
          primaryMobile: data.primaryMobile.split(" ").join(""),
          secondaryMobile: data.secondaryMobile.split(" ").join(""),
          landLine: data.landLine.split(" ").join(""),
          houseNumber: data.houseNumber,
          streetName: data.streetName,
          city: data.city,
          state: data.state,
          postCode: data.postCode.toUpperCase(),
          linkedIn: data.linkedIn,
          facebookUrl: data.facebookUrl,
          gender: data.gender,
          title: data.title,
          // dateOfBirth: data.dateOfBirth,
          dateOfBirth:dateFormatter.formatDateToYYYYMMDD(data.dateOfBirth),
          candidateRoleId: Number(data.candidateRoleId),
          candidateBandId: Number(data.candidateBandId),
          candidateSpecialitiesIds: specialityIds,
          locality: data.locality,
          workFlowStatus: 0,
          status: 0,
          headhunters: [Number(data.assigneeId)],
          preferredClientsIds: [],
          paymentMethodStatus: data.payementMethod,
          candidateServiceType: data.placement,
          candidateSourceId: Number(data.cvSource),
        })
        .then((res) => {
          if (res.data.status == "success") {
            ToastCommon(true, "success", addToast);
            e.target.reset();
            setSelectedSpecialities(null);
          } else if (res.data.status == "duplicate") {
            ToastCommon(true, "duplicateEmail", addToast);
          } else if (res.data.message == "Postal code not found") {
            ToastCommon(true, "postCode", addToast);
          } else {
            ToastCommon(true, "error", addToast);
          }
        })
        .catch((err) => {
          //console.log(err)
          ToastCommon(true, "error", addToast);
        });
    }
  };

  const getHeadhunters = () => {
    axios
      .get(MexxarApiEmployee + "/permissions/HEADHUNTER")
      .then((res) => {
        let reponse = res.data.body;

        let names = [];
        reponse.forEach((item) => {
          names.push({
            id: item.id,
            firstName: item.firstName,
            lastName: item.lastName,
          });
        });
        setHeadunterName(names);
      })
      .catch((err) => {
        //console.log(err)
      });
  };

  const getSpecialityData = (candidateData) => {
    axios
      .all([
        axios.get(HOSTMexxar + "candidate-specialities/role/" + selectedRole),

      ])
      .then(
        axios.spread((res1 /*, res2, res3, res4, res5, res6, res7, res8, res9, res10*/) => {

          let specialitiesArray = [];


          res1.data.body.forEach((item) => {
            specialitiesArray.push({
              value: item.name,
              label: item.name + `${item.type == "DATABASE" ? "-DB" : ""}`,
              id: item.id,
            });
          });


          setSpeciality(specialitiesArray);

        })
      )
      .catch((err) => {

        console.error(err);

      });
  };



  useDidMountEffect(() => {

    getSpecialityData()

    return () => { };
  }, [selectedRole]);
  const getRoles = () => {
    axios
      .get(MexxarApiRole)
      .then((res) => {
        setRole(res.data.body);
      })
      .catch((err) => {
        //console.log(err)
      });
  };
  const getBands = () => {
    axios
      .get(MexxarApiBand)
      .then((res) => {
        setBand(res.data.body);
      })
      .catch((err) => {
        //console.log(err)
      });
  };

  const getCVSources = () => {
    axios
      .get(MexxarApiCVSources)
      .then((res) => {
        setCvSources(res.data.body);
      })
      .catch((err) => {
        //console.log(err)
      });
  };

  const handleChange = (e) => {
    setAssignID({ id: e.target.value });
  };

  const checkPermissions = () => {
    let permission = filter(auth.item.userRole.permissions, (item) => {
      return item.name == "HEADHUNTER";
    });

    if (permission.length > 0) {
      return setAssignID({ id: auth.item.userTypeId });
    } else {
      return setAssignID({ id: 0 });
    }
  };

  useEffect(() => {
    checkPermissions();
    getHeadhunters();
    getBands();
    getRoles();
    getCVSources();
  }, []);

  const colourStyles = {
    option: (provided, state) => ({
      ...provided,
      color: "#6A6767",
    }),
  };
  const handleSpecialityChange = (selectedOptions) => {
    setSelectedSpecialities(selectedOptions);
  };
  useEffect(() => {
    if (user?.candidateSpecialities) {
      const existingSpecialities = user?.candidateSpecialities.map(spec => ({
        value: spec.name,
        label: spec.name,
        id: spec.id,
      }));
      setSelectedSpecialities(existingSpecialities);
    }
  }, [user]);
  useEffect(() => {
    if (user?.candidateRole?.id) {
      setSelectedRole(user?.candidateRole?.id);
    }
  }, [user]);

  useEffect(() => {
    if (user?.candidateSource?.id) {
      setSelectedSource(user.candidateSource.id);
    }
  }, [user]);


  return (
    <>
      <DashboardLayout
        title="Register Candidate"
        subTitle="Register Candidates to the System"
      >
        <div className="card">
          <div className="card-body">
            <form
              id="myForm"
              className="form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Alert color="info">
                Note: Fields marked with
                <span className="text-danger mx-2">*</span>are required{" "}
              </Alert>
              <div className="form-row">
                <FormGroup className="col-md-2">
                  <Label for="title">
                    Title <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="select"
                    id="title"
                    name="title"
                    invalid={errors.title || conflict}
                    innerRef={register({
                      required: {
                        value: true,
                        message: "This field is required!",
                      },
                    })}
                  >
                    <option value={""} style={{ color: "#6A6767" }}>
                      Select
                    </option>
                    <option value={"Mr"} style={{ color: "#6A6767" }}>
                      Mr.
                    </option>
                    <option value={"Miss"} style={{ color: "#6A6767" }}>
                      Miss.
                    </option>
                    <option value={"Ms"} style={{ color: "#6A6767" }}>
                      Ms.
                    </option>
                    <option value={"Mrs"} style={{ color: "#6A6767" }}>
                      Mrs.
                    </option>
                    {/* <option value={"Doc"}>Doc.</option>
                    <option value={"Rev"}>Rev.</option> */}
                  </Input>
                  {errors.title && (
                    <ul
                      className="list-unstyled text-sm mt-1 text-muted filled"
                      id="parsley-id-119"
                    >
                      <li className="parsley-required text-danger">
                        {errors.title?.message}
                      </li>
                    </ul>
                  )}
                  {conflict && (
                    <ul
                      className="list-unstyled text-sm mt-1 text-muted filled"
                      id="parsley-id-119"
                    >
                      <li className="parsley-required text-danger">
                        Title and gender mismatch !
                      </li>
                    </ul>
                  )}
                </FormGroup>
                <FormGroup className="col-md-3">
                  <Label for="first_name">
                    First Name <span className="text-danger ">*</span>
                  </Label>
                  <Input
                    type="text"
                    id="first_name"
                    name="firstName"
                    defaultValue={user?.firstName}
                    invalid={errors.firstName}
                    // className="capitalize"
                    style={{ textTransform: "capitalize" }}
                    innerRef={register({
                      required: {
                        value: true,
                        message: "This field is required!",
                      },
                      maxLength: {
                        value: 30,
                        message: "This field only allowed only 30 characters",
                      },
                      pattern: {
                        value: "[A-Z][a-z]* [A-Z][a-z]*",
                        message: "Letters only allowed for this field",
                      },
                    })}
                  ></Input>
                  {errors.firstName && (
                    <ul
                      className="list-unstyled text-sm mt-1 text-muted filled"
                      id="parsley-id-119"
                    >
                      <li className="parsley-required text-danger">
                        {errors.firstName?.message}
                      </li>
                    </ul>
                  )}
                </FormGroup>
                <FormGroup className="col-md-4">
                  <Label for="middle_Name">
                    Middle Name(s) <span className="text-danger"></span>
                  </Label>
                  <Input
                    type="text"
                    id="middle_Name"
                    name="middleName"
                    // className="capitalize"
                    style={{ textTransform: "capitalize" }}
                    invalid={errors.middleName}
                    innerRef={register({
                      maxLength: {
                        value: 30,
                        message: "This field only allowed only 30 characters",
                      },
                      pattern: {
                        // value: /^[a-zA-Z]+$/,

                        value: "[A-Z][a-z]* [A-Z][a-z]*",
                        message: "Letters only allowed for this field",
                      },
                    })}
                  ></Input>
                  {errors.middleName && (
                    <ul
                      className="list-unstyled text-sm mt-1 text-muted filled"
                      id="parsley-id-119"
                    >
                      <li className="parsley-required text-danger">
                        {errors.middleName?.message}
                      </li>
                    </ul>
                  )}
                </FormGroup>
                <FormGroup className="col-md-3">
                  <Label for="last_name">
                    Last Name <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="text"
                    id="last_name"
                    name="lastName"
                    defaultValue={user?.lastName}
                    // className="capitalize"
                    style={{ textTransform: "capitalize" }}
                    invalid={errors.lastName}
                    innerRef={register({
                      required: {
                        value: true,
                        message: "This field is required!",
                      },
                      maxLength: {
                        value: 30,
                        message: "This field only allowed only 30 characters",
                      },
                      pattern: {
                        value: "[A-Z][a-z]* [A-Z][a-z]*",
                        message: "Letters only allowed for this field",
                      },
                    })}
                  ></Input>
                  {errors.lastName && (
                    <ul
                      className="list-unstyled text-sm mt-1 text-muted filled"
                      id="parsley-id-119"
                    >
                      <li className="parsley-required text-danger">
                        {errors.lastName?.message}
                      </li>
                    </ul>
                  )}
                </FormGroup>
                <FormGroup className="col-md-3">
                <Label for="dateOfBirth">Date of Birth <span className="text-danger ">*</span></Label>
                  <DateInput
    id="dateOfBirth"
    invalid={errors.dateOfBirth}
    register={register({
      required: {
        value: true,
        message: "This field is required!",
      },
    })}
    isDOB={true} 
  ></DateInput>
                  {errors.dateOfBirth && (
                    <ul className="list-unstyled text-sm mt-1 text-muted filled">
                      <li className="parsley-required text-danger">
                        {errors.dateOfBirth?.message}
                      </li>
                    </ul>
                  )}
                </FormGroup>
              </div>
              <div className="form-row">
                <FormGroup className="col-md-6">
                  <Label for="email">
                    Email <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="text"
                    id="email"
                    name="email"
                    defaultValue={user?.email}
                    invalid={errors.email || props.headhunter.alreadyExsist}
                    innerRef={register({
                      required: {
                        value: true,
                        message: "This field is required!",
                      },
                      maxLength: {
                        value: 50,
                        message: "This field only allowed only 50 characters",
                      },
                      pattern: {
                        value:
                          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: "Please enter a valid email address!",
                      },
                    })}
                  ></Input>

                  {props.headhunter.alreadyExsist && (
                    <ul
                      className="list-unstyled text-sm mt-1 text-muted filled"
                      id="parsley-id-119"
                    >
                      <li className="parsley-required text-danger">
                        This email address already registered on the system!
                      </li>
                    </ul>
                  )}
                </FormGroup>
                <FormGroup className="col-md-6">
                  <Label for="email">
                    Placement <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="select"
                    id="placement"
                    name="placement"
                    defaultValue={"TEMPORARY"}
                    invalid={errors.placement}
                    innerRef={register({
                      required: {
                        value: true,
                        message: "This field is required!",
                      },
                    })}
                  >
                    <option value={"PERMANENT"} style={{ color: "#6A6767" }}>
                      Permanent
                    </option>
                    <option value={"TEMPORARY"} style={{ color: "#6A6767" }}>
                      Temporary
                    </option>
                  </Input>
                  {errors.placement && (
                    <ul
                      className="list-unstyled text-sm mt-1 text-muted filled"
                      id="parsley-id-119"
                    >
                      <li className="parsley-required text-danger">
                        {errors.placement?.message}
                      </li>
                    </ul>
                  )}
                </FormGroup>
              </div>
              <div className="form-row">
                <FormGroup className="col-md-4">
                  <Label for="primary_Mobile">
                    Mobile Number (Primary){" "}
                    <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="text"
                    id="primary_Mobile"
                    name="primaryMobile"
                    defaultValue={user?.primaryMobile}
                    invalid={errors.primaryMobile}
                    innerRef={register({
                      required: {
                        value: true,
                        message: "This field is required!",
                      },
                      pattern: {
                        value:
                          /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/,
                        message: "Please enter a valid phone number",
                      },
                    })}
                  ></Input>
                  {errors.primaryMobile && (
                    <ul
                      className="list-unstyled text-sm mt-1 text-muted filled"
                      id="parsley-id-119"
                    >
                      <li className="parsley-required text-danger">
                        {errors.primaryMobile?.message}
                      </li>
                    </ul>
                  )}
                </FormGroup>
                <FormGroup className="col-md-4">
                  <Label for="secondary_Mobile">
                    Mobile Number (Secondary)
                  </Label>
                  <Input
                    type="text"
                    id="secondary_Mobile"
                    name="secondaryMobile"
                    defaultValue={user?.secondaryMobile}
                    invalid={errors.secondaryMobile}
                    innerRef={register({
                      pattern: {
                        value:
                          /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/,
                        message: "Please enter a valid phone number",
                      },
                    })}
                  ></Input>
                  {errors.secondaryMobile && (
                    <ul
                      className="list-unstyled text-sm mt-1 text-muted filled"
                      id="parsley-id-119"
                    >
                      <li className="parsley-required text-danger">
                        {errors.secondaryMobile?.message}
                      </li>
                    </ul>
                  )}
                </FormGroup>{" "}
                <FormGroup className="col-md-4">
                  <Label for="land_Line">Land Phone Number</Label>
                  <Input
                    type="text"
                    id="land_Line"
                    name="landLine"
                    defaultValue={user?.landLine}
                    invalid={errors.landLine}
                    innerRef={register({
                      pattern: {
                        value:
                          /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/,
                        message: "Please enter a valid phone number",
                      },
                    })}
                  ></Input>
                  {errors.landLine && (
                    <ul
                      className="list-unstyled text-sm mt-1 text-muted filled"
                      id="parsley-id-119"
                    >
                      <li className="parsley-required text-danger">
                        {errors.landLine?.message}
                      </li>
                    </ul>
                  )}
                </FormGroup>
                <FormGroup className="col-md-4">
                  <Label for="gender">
                    Gender <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="select"
                    id="gender"
                    name="gender"
                    defaultValue={user?.gender}
                    // defaultValue={0}
                    invalid={errors.gender || conflict}
                    innerRef={register({
                      required: {
                        value: true,
                        message: "This field is required!",
                      },
                    })}
                  >
                    <option value="" style={{ color: "#6A6767" }}>
                      Select
                    </option>
                    <option value={"Male"} style={{ color: "#6A6767" }}>
                      Male
                    </option>
                    <option value={"Female"} style={{ color: "#6A6767" }}>
                      Female
                    </option>
                    {/* <option value={"OTHER"}>Other</option> */}
                  </Input>
                  {errors.gender && (
                    <ul
                      className="list-unstyled text-sm mt-1 text-muted filled"
                      id="parsley-id-119"
                    >
                      <li className="parsley-required text-danger">
                        {errors.gender?.message}
                      </li>
                    </ul>
                  )}

                  {conflict && (
                    <ul
                      className="list-unstyled text-sm mt-1 text-muted filled"
                      id="parsley-id-119"
                    >
                      <li className="parsley-required text-danger">
                        Title and Gender missmatch !
                      </li>
                    </ul>
                  )}
                </FormGroup>
                <FormGroup className="col-md-4">
                  <Label for="referral_note">
                    Referral Note
                    {refferalNote !== "" && (
                      <span className="text-danger text-sm">
                        * Please enter 6 characters
                      </span>
                    )}
                  </Label>
                  <Input
                    type="text"
                    id="referral_note"
                    name="referralNote"
                    invalid={errors.referralNote}
                    innerRef={register({
                      required: {
                        value: refferalNote !== "" ? true : false,
                        message: "This field is required!",
                      },
                      maxLength: {
                        value: 30,
                        message: "This field should be less than 30 characters",
                      },
                    })}
                    onChange={(e) => setRefferalNote(e.target.value)}
                  ></Input>
                  {errors.referralNote && (
                    <ul
                      className="list-unstyled text-sm mt-1 text-muted filled"
                      id="parsley-id-119"
                    >
                      <li className="parsley-required text-danger">
                        {errors.referralNote?.message}
                      </li>
                    </ul>
                  )}
                  {referralPerson && (
                    <ul
                      className="list-unstyled text-sm mt-1 text-muted filled"
                      id="parsley-id-119"
                    >
                      <li className="parsley-required text-warning">
                        {referralPerson}
                      </li>
                    </ul>
                  )}
                </FormGroup>
                <FormGroup className="col-md-4">
                  <Label for="assigneeId">
                    Assign To <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="select"
                    id="assigneeId"
                    name="assigneeId"
                    invalid={errors.assigneeId}
                    innerRef={register({
                      required: {
                        value: true,
                        message: "This field is required!",
                      },
                    })}
                  >
                    <option value="" style={{ color: "#6A6767" }}>
                      Select
                    </option>
                    {headunterName.map((item, i) => {
                      return (
                        <option
                          value={item.id}
                          key={i}
                          style={{ color: "#6A6767" }}
                        >
                          {item.firstName} {item.lastName}
                        </option>
                      );
                    })}
                  </Input>
                  {errors.assigneeId && (
                    <ul
                      className="list-unstyled text-sm mt-1 text-muted filled"
                      id="parsley-id-119"
                    >
                      <li className="parsley-required text-danger">
                        {errors.assigneeId?.message}
                      </li>
                    </ul>
                  )}
                </FormGroup>
              </div>
              <hr />
              <label>Address Information</label>
              <div className="form-row">
                <FormGroup className="col-md-2">
                  <Label for="houseNumber">Property Number</Label>
                  <Input
                    type="text"
                    id="houseNumber"
                    className="capitalize"
                    name="houseNumber"
                    invalid={errors.houseNumber}
                    innerRef={register({
                      maxLength: {
                        value: 30,
                        message: "This field should be less than 30 characters",
                      },
                    })}
                  ></Input>
                  {errors.houseNumber && (
                    <ul
                      className="list-unstyled text-sm mt-1 text-muted filled"
                      id="parsley-id-119"
                    >
                      <li className="parsley-required text-danger">
                        {errors.houseNumber?.message}
                      </li>
                    </ul>
                  )}
                </FormGroup>
                <FormGroup className="col-md-5">
                  <Label for="streetName">Street Name</Label>
                  <Input
                    type="text"
                    id="streetName"
                    name="streetName"
                    className="capitalize"
                    invalid={errors.streetName}
                    innerRef={register({
                      maxLength: {
                        value: 30,
                        message: "This field should be less than 30 characters",
                      },
                    })}
                  ></Input>
                  {errors.streetName && (
                    <ul
                      className="list-unstyled text-sm mt-1 text-muted filled"
                      id="parsley-id-119"
                    >
                      <li className="parsley-required text-danger">
                        {errors.streetName?.message}
                      </li>
                    </ul>
                  )}
                </FormGroup>
                <FormGroup className="col-md-5">
                  <Label for="locality">Locality</Label>
                  <Input
                    type="text"
                    id="locality"
                    name="locality"
                    className="capitalize"
                    invalid={errors.locality}
                    innerRef={register({
                      maxLength: {
                        value: 30,
                        message: "This field should be less than 30 characters",
                      },
                    })}
                  ></Input>
                  {errors.locality && (
                    <ul
                      className="list-unstyled text-sm mt-1 text-muted filled"
                      id="parsley-id-119"
                    >
                      <li className="parsley-required text-danger">
                        {errors.locality?.message}
                      </li>
                    </ul>
                  )}
                </FormGroup>
              </div>
              <div className="form-row">
                <FormGroup className="col-md-4">
                  <Label for="city">Town</Label>
                  <Input
                    type="text"
                    id="city"
                    name="city"
                    className="capitalize"
                    defaultValue={user?.address}
                    onChange={changeHandler}
                    invalid={errors.city}
                    innerRef={register({
                      maxLength: {
                        value: 30,
                        message: "This field should be less than 30 characters",
                      },
                    })}
                  ></Input>
                  {errors.city && (
                    <ul
                      className="list-unstyled text-sm mt-1 text-muted filled"
                      id="parsley-id-119"
                    >
                      <li className="parsley-required text-danger">
                        {errors.city?.message}
                      </li>
                    </ul>
                  )}
                </FormGroup>
                <FormGroup className="col-md-4">
                  <Label for="state">County</Label>
                  <Input
                    type="text"
                    id="state"
                    name="state"
                    className="capitalize"
                    invalid={errors.state}
                    value={postalData?.county}
                    onChange={(e) =>
                      setPostalData({ ...postalData, county: e.target.value })
                    }
                    innerRef={register({
                      maxLength: {
                        value: 30,
                        message: "This field should be less than 30 characters",
                      },
                    })}
                  ></Input>
                  {errors.state && (
                    <ul
                      className="list-unstyled text-sm mt-1 text-muted filled"
                      id="parsley-id-119"
                    >
                      <li className="parsley-required text-danger">
                        {errors.state?.message}
                      </li>
                    </ul>
                  )}
                </FormGroup>
                <FormGroup className="col-md-4">
                  <Label for="postCode">
                    Post Code
                    <small className="text-danger">* Ex Format: EC1A 2BP</small>
                  </Label>
                  <Input
                    type="text"
                    id="postCode"
                    name="postCode"
                    defaultValue={user?.postalCode}
                    className="capitalizeUpperCase"
                    invalid={errors.postCode || errorPostCode}
                    onChange={(e) => setPostalCode(e.target.value)}
                    innerRef={register({
                      required: {
                        value: true,
                        message: "This field is required!",
                      },
                      maxLength: {
                        value: 10,
                        message: "This field should be less than 10 characters",
                      },
                    })}
                  ></Input>

                  {errors.postCode && (
                    <ul
                      className="list-unstyled text-sm mt-1 text-muted filled"
                      id="parsley-id-119"
                    >
                      <li className="parsley-required text-danger">
                        {errors.postCode?.message}
                      </li>
                    </ul>
                  )}
                  {errorPostCode && (
                    <ul
                      className="list-unstyled text-sm mt-1 text-muted filled"
                      id="parsley-id-119"
                    >
                      <li className="parsley-required text-danger">
                        Postal code is not valid!
                      </li>
                    </ul>
                  )}
                </FormGroup>
              </div>
              <hr />
              <label>Advance Information</label>
              <div className="form-row">

                <FormGroup className="col-md-6">
                  <Label for="candidateRoleId">
                    Role <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="select"
                    id="candidateRoleId"
                    name="candidateRoleId"
                    value={selectedRole || ""}
                    invalid={errors.candidateRoleId}
                    onChange={(e) => {
                      setSelectedRole(e.target.value);
                    }}
                    innerRef={register({
                      required: {
                        value: true,
                        message: "This field is required!",
                      },
                    })}
                  >
                    <option value="" style={{ color: "#6A6767" }}>
                      Select
                    </option>
                    {role.map((items, i) => {
                      return (
                        <option
                          value={items.id}
                          key={i}
                          style={{ color: "#6A6767" }}
                        >
                          {items.name}
                        </option>
                      );
                    })}
                  </Input>
                  {errors.candidateRoleId && (
                    <ul
                      className="list-unstyled text-sm mt-1 text-muted filled"
                      id="parsley-id-119"
                    >
                      <li className="parsley-required text-danger">
                        {errors.candidateRoleId?.message}
                      </li>
                    </ul>
                  )}
                </FormGroup>




                <FormGroup className="col-md-6">
                  <Label for="candidateBandId">
                    Band <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="select"
                    id="candidateBandId"
                    name="candidateBandId"
                    // defaultValue={0}
                    invalid={errors.candidateBandId}
                    innerRef={register({
                      required: {
                        value: true,
                        message: "This field is required!",
                      },
                    })}
                  >
                    {" "}
                    <option value="" style={{ color: "#6A6767" }}>
                      Select
                    </option>
                    {band.map((value, i) => {
                      return (
                        <option
                          value={value.id}
                          key={i}
                          style={{ color: "#6A6767" }}
                        >
                          {value.name}
                        </option>
                      );
                    })}
                    {/* <option value={3}>Band Id</option> */}
                  </Input>
                  {errors.candidateBandId && (
                    <ul
                      className="list-unstyled text-sm mt-1 text-muted filled"
                      id="parsley-id-119"
                    >
                      <li className="parsley-required text-danger">
                        {errors.candidateBandId?.message}
                      </li>
                    </ul>
                  )}
                </FormGroup>
                <FormGroup className="col-md-6">
                  <Label for="candidateSpecialityId">
                    Candidate Speciality <span className="text-danger">*</span>
                  </Label>
                  <Select
                    value={selectedSpecialities || []}
                    isMulti
                    name="candidateSpecialityIds"
                    styles={colourStyles}
                    className="basic-multi-select"
                    onChange={handleSpecialityChange}
                    options={speciality}
                    innerRef={register({
                      required: {
                        value: true,
                        message: "This field is required!",
                      },
                    })}
                  />

                  {/* Error message for validation */}
                  {selectedSpecialitiesError && (
                    <ul
                      className="list-unstyled text-sm mt-1 text-muted filled"
                      id="parsley-id-119"
                    >
                      <li className="parsley-required text-danger">
                        Please add at least one speciality
                      </li>
                    </ul>
                  )}
                </FormGroup>

                <FormGroup className="col-md-6">
                  <Label for="payementMethod">
                    Payment Method <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="select"
                    id="payementMethod"
                    name="payementMethod"
                    // defaultValue={0}
                    invalid={errors.payementMethod}
                    innerRef={register({
                      required: {
                        value: true,
                        message: "This field is required!",
                      },
                    })}
                  >
                    <option value="" style={{ color: "#6A6767" }}>
                      Select
                    </option>
                    {payementMethod.map((value, i) => {
                      return (
                        <option
                          value={value.id}
                          key={i}
                          style={{ color: "#6A6767" }}
                        >
                          {value.name}
                        </option>
                      );
                    })}
                  </Input>
                  {errors.payementMethod && (
                    <ul
                      className="list-unstyled text-sm mt-1 text-muted filled"
                      id="parsley-id-119"
                    >
                      <li className="parsley-required text-danger">
                        {errors.payementMethod?.message}
                      </li>
                    </ul>
                  )}
                </FormGroup>
              </div>
              <hr />
              <label>Optional Information</label>
              <div className="form-row">
                <FormGroup className="col-md-4">
                  <Label for="facebookUrl">Facebook Profile </Label>
                  <Input
                    type="text"
                    id="facebookUrl"
                    name="facebookUrl"
                    invalid={errors.facebookUrl}
                    innerRef={register({
                      maxLength: {
                        value: 100,
                        message:
                          "This field should be less than 100 characters",
                      },
                    })}
                  ></Input>
                  {errors.facebookUrl && (
                    <ul
                      className="list-unstyled text-sm mt-1 text-muted filled"
                      id="parsley-id-119"
                    >
                      <li className="parsley-required text-danger">
                        {errors.facebookUrl?.message}
                      </li>
                    </ul>
                  )}
                </FormGroup>
                <FormGroup className="col-md-4">
                  <Label for="linkedIn">LinkedIn Profile</Label>
                  <Input
                    type="text"
                    id="linkedIn"
                    name="linkedIn"
                    invalid={errors.linkedIn}
                    innerRef={register({
                      maxLength: {
                        value: 100,
                        message: "This field only allowed only 100 characters",
                      },
                    })}
                  ></Input>
                  {errors.linkedIn && (
                    <ul
                      className="list-unstyled text-sm mt-1 text-muted filled"
                      id="parsley-id-119"
                    >
                      <li className="parsley-required text-danger">
                        {errors.linkedIn?.message}
                      </li>
                    </ul>
                  )}
                </FormGroup>
                <FormGroup className="col-md-4">
                  <Label for="cvSource">CV Source</Label>
                  <Input
                    type="select"
                    id="cvSource"
                    name="cvSource"
                    value={selectedSource || ""}
                    invalid={errors.cvSource}
                    onChange={(e) => {
                      setSelectedSource(e.target.value);
                    }}
                    innerRef={register({
                      required: {
                        value: false,
                        message: "This field is required!",
                      },
                    })}
                  >
                    <option value={null} style={{ color: "#6A6767" }}>
                      Select
                    </option>
                    {cvSources.map((value, i) => {
                      return (
                        <option
                          value={value.id}
                          key={i}
                          style={{ color: "#6A6767" }}
                        >
                          {value.name}
                        </option>
                      );
                    })}
                  </Input>

                  {errors.cvSource && (
                    <ul
                      className="list-unstyled text-sm mt-1 text-muted filled"
                      id="parsley-id-119"
                    >
                      <li className="parsley-required text-danger">
                        {errors.cvSource?.message}
                      </li>
                    </ul>
                  )}
                </FormGroup>
              </div>
              <br></br>
              <FormGroup>
                <Button className="btn btn-primary" type="submit">
                  {props.headhunter.inProgress && (
                    <Spinner size="sm" animation="border" />
                  )}
                  {!props.headhunter.inProgress
                    ? "Create New Candidate"
                    : " Please Wait"}
                </Button>
              </FormGroup>
            </form>
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};

function mapStateToProps(state) {
  return {
    headhunter: state.headhunter,
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {})(HeadhunterRegister1);