import {
  GET_EMPLOYEES,
  GET_SPECIALITIES,
  GET_CLIENTS,
  GET_DEPARTMENTS,
  GET_ROLES,
  GET_BANDS,
  GET_COUNTIES,
  GET_ADDRESS_WARDS,
  GET_DISTRICTS,
  GET_CV_SOURCES,
  GET_GRADES
} from "./types";
import Axios from "axios";
import {
  Add_NEW_EMPLOYEE,
  CANDIDATE_SPECIALITY,
  CLIENTS,
  CANDIDATE_ROLE,
  WARDS,
  CANDIDATE_BAND,
  ADDRESSWARDS,
  COUNTIES,
  DISTRICTS,
  CANDIDATE_CV_COURCES,
  GRADES
} from "../../configs/api-config";
import store from "../store";

export const employeesGetAction = () => (dispatch) => {
  if (store.getState().initialDataGetReducer.employees == null) {
    Axios.get(Add_NEW_EMPLOYEE)
      .then((res) => {
        dispatch({
          type: GET_EMPLOYEES,
          payload: res.data.body,
        });
      })
      .catch((err) => {});
  }
};

export const specialitiesGetAction = () => (dispatch) => {
  if (store.getState().initialDataGetReducer.specialities == null) {
    Axios.get(CANDIDATE_SPECIALITY)
      .then((res) => {
        dispatch({
          type: GET_SPECIALITIES,
          payload: res.data.body,
        });
      })
      .catch((err) => {});
  }
};

export const clientsGetAction = () => (dispatch) => {
  if (store.getState().initialDataGetReducer.clients == null) {
    Axios.get(CLIENTS)
      .then((res) => {
        dispatch({
          type: GET_CLIENTS,
          payload: res.data.body,
        });
      })
      .catch((err) => {});
  }
};

export const departmentsGetAction = () => (dispatch) => {
  if (store.getState().initialDataGetReducer.departments == null) {
    Axios.get(WARDS)
      .then((res) => {
        dispatch({
          type: GET_DEPARTMENTS,
          payload: res.data.body,
        });
      })
      .catch((err) => {});
  }
};

export const rolesGetAction = () => (dispatch) => {
  if (store.getState().initialDataGetReducer.roles == null) {
    Axios.get(CANDIDATE_ROLE)
      .then((res) => {
        dispatch({
          type: GET_ROLES,
          payload: res.data.body,
        });
      })
      .catch((err) => {});
  }
};

export const bandsGetAction = () => (dispatch) => {
  if (store.getState().initialDataGetReducer.bands == null) {
    Axios.get(CANDIDATE_BAND)
      .then((res) => {
        dispatch({
          type: GET_BANDS,
          payload: res.data.body,
        });
      })
      .catch((err) => {});
  }
};

export const gradesGetAction = () => (dispatch) => {
  if (store.getState().initialDataGetReducer.grades == null) {
    Axios.get(GRADES)
      .then((res) => {
        dispatch({
          type: GET_GRADES,
          payload: res.data.body,
        });
      })
      .catch((err) => {});
  }
};


export const addressWardGetAction = () => (dispatch) => {
  if (store.getState().initialDataGetReducer.addressWards == null) {
    Axios.get(ADDRESSWARDS)
      .then((res) => {
        dispatch({
          type: GET_ADDRESS_WARDS,
          payload: res.data.body,
        });
      })
      .catch((err) => {});
  }
};

export const countiesGetAction = () => (dispatch) => {
  if (store.getState().initialDataGetReducer.counties == null) {
    Axios.get(COUNTIES)
      .then((res) => {
        dispatch({
          type: GET_COUNTIES,
          payload: res.data.body,
        });
      })
      .catch((err) => {});
  }
};

export const districtsGetAction = () => (dispatch) => {
  if (store.getState().initialDataGetReducer.districts == null) {
    Axios.get(DISTRICTS)
      .then((res) => {
        dispatch({
          type: GET_DISTRICTS,
          payload: res.data.body,
        });
      })
      .catch((err) => {});
  }
};

export const cvSourcesGetAction = () => (dispatch) => {
  if (store.getState().initialDataGetReducer.districts == null) {
    Axios.get(CANDIDATE_CV_COURCES)
      .then((res) => {
        dispatch({
          type: GET_CV_SOURCES,
          payload: res.data.body,
        });
      })
      .catch((err) => {});
  }
};

