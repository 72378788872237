import React, { useEffect, useState } from "react";
import {
  EMPLOYEE_PERMISSIONS,
  EMPLOYEE_TEAMS,
  HOSTMexxar,
} from "../../configs/api-config";
import axios from "axios";
import moment from "moment";
import Select from "react-select";
import { Bar } from "react-chartjs-2";
import { Label, Button, ButtonGroup, Col, Row, Input } from "reactstrap";
import { forEach, uniqWith, isEqual, filter, isNull, sumBy } from "lodash";
import useDidMountEffect from "../../components/Modal/ModalBody/intialRunUseEffect";
import DateInput from "../../util/common/DateInput";
import dateFormatter from "../../util/common/dateFormatter";

const VerticalBar = (props) => {
  const MexxarApi = HOSTMexxar;
  const MexxarTeams = EMPLOYEE_TEAMS;
  const MexxarTeamMembers = EMPLOYEE_PERMISSIONS;
  var today = dateFormatter.formatDateToDDMMYYYY(
    new Date()
  );
  const datatype = props;

  const [team, setTeam] = useState(null);
  const [dateTo, setDateTo] = useState();
  const [range, setRange] = useState(false);
  const [dateFrom, setDateFrom] = useState();
  const [dataList, setDataList] = useState([]);
  const [rangeType, setRangeType] = useState("YEARLY");
  const [noOfbookings, setNoOfBookings] = useState([]);
  const [noOfCallLogs, setNoOfCallLogs] = useState([]);
  const [teamMembers, setTeamMembers] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [noOfClearances, setNoOfClearances] = useState([]);
  const [dummpyDataBooking, setDummpyDataBooking] = useState();
  const [dummpyDataClearance, setDummpyDataClearance] = useState();
  const [dummpyDataCalls, setDummpyDataCalls] = useState();

  const yearData = [
    String(Number(currentYear) - 9),
    String(Number(currentYear) - 8),
    String(Number(currentYear) - 7),
    String(Number(currentYear) - 6),
    String(Number(currentYear) - 5),
    String(Number(currentYear) - 4),
    String(Number(currentYear) - 3),
    String(Number(currentYear) - 2),
    String(Number(currentYear) - 1),
    String(Number(currentYear)),
  ];

  const monthData = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const weekData = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]

  const data = {
    labels: dataList,
    datasets: [
      {
        label: "No of Bookings",
        // data: dummpyDataBooking,  //use this for dummy data
        data: noOfbookings,
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      },
      {
        label: "No of Clearances",
        // data: dummpyDataClearance, //use this for dummy data
        data: noOfClearances,
        backgroundColor: "rgb(54, 162, 235)",
        borderColor: "rgba(54, 162, 235, 0.2)",
        // yAxisID: 'y-axis-2',
      },
      {
        label: "No of Calls",
        // data: dummpyDataCalls,     //use this for dummy data
        data: noOfCallLogs,
        backgroundColor: "rgb(244, 196, 20)",
        borderColor: "rgba(244, 196, 20, 0.2)",
        // yAxisID: 'y-axis-2',
      },
    ],
  };




  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  var currentYear = moment().year();

  const getTeams = () => {
    axios
      .get(MexxarTeams)
      .then((res) => {
        let teamArray = [];

        forEach(res.data.body, function (item) {
          teamArray.push({
            value: item.name,
            label: item.name,
            id: item.id,
          });
        });

        setTeam(teamArray);
      })
      .catch((err) => {
        //console.log("cannot get specialities ",err)
        // toastCommon(true, "error");
      });
  };

  const getTeamMembers = () => {
    axios
      .get(MexxarTeamMembers + "/team-members/" + selectedTeam.id)
      .then((res) => {
        let teamMembersArray = [];

        if (res.data.status == "success") {
          forEach(res.data.body, function (item) {
            teamMembersArray.push(item.firstName + " " + item.lastName);
          });

          setTeamMembers(teamMembersArray);
          setDataList(teamMembersArray);
        }
      })
      .catch((err) => {
        //console.log("cannot get specialities ",err)
        // toastCommon(true, "error");
      });
  };

  const [startDateShowOnly, setStartDateShowOnly] = useState();
  const [endDateShowOnly, setEndDateShowOnly] = useState();

  const getAllRows = () => {
    //according to the userrole change the performance data in the chart
    //currently showing the looged in user's data

    // setStartAndEndDates();

    let startTempDate=today;
    let endTempDate=today;
    // if (rangeType == "DAILY") {
    //   startTempDate = moment(startDate).format("YYYY/MM/DD");
    // } else

    if (rangeType == "MONTHLY" && range == true) {
      startTempDate = moment(dateFormatter.formatDateToYYYYMMDD(dateFrom)).format("YYYY/MM/DD");

      endTempDate = moment(dateFormatter.formatDateToYYYYMMDD(dateTo)).format("YYYY/MM/DD");
    } else if (rangeType == "DAILY") {
      startTempDate = moment(dateFormatter.formatDateToYYYYMMDD(dateFrom)).format("YYYY/MM/DD");

      endTempDate = moment(dateFormatter.formatDateToYYYYMMDD(dateTo)).format("YYYY/MM/DD");
    } else if (rangeType == "WEEKLY") {
      startTempDate = moment(moment().startOf("isoWeek").toDate()).format(
        "YYYY/MM/DD"
      );

      endTempDate = moment(moment().endOf("isoWeek").toDate()).format(
        "YYYY/MM/DD"
      );
    } else if (rangeType == "MONTHLY") {
      startTempDate = moment(moment().startOf("month").toDate()).format(
        "YYYY/MM/DD"
      );

      endTempDate = moment(moment().endOf("month").toDate()).format(
        "YYYY/MM/DD"
      );
    } else if (rangeType == "YEARLY") {
      startTempDate = moment(moment().startOf("year").toDate()).format(
        "YYYY/MM/DD"
      );

      endTempDate = moment(moment().endOf("year").toDate()).format(
        "YYYY/MM/DD"
      );
    }
    setStartDateShowOnly(startTempDate);
    setEndDateShowOnly(endTempDate);
    axios
      .get(
        MexxarApi +
          "charts-and-reports/team-performance-report-chart/team/" +
          selectedTeam.id +
          "?chartType=" +
          rangeType +
          "&start=" +
          startTempDate +
          "&end=" +
          moment(moment(endTempDate).add(1, "days")).format("YYYY/MM/DD")
      )
      .then((res) => {
        let datesTemp = [];
        let monthsTemp = [];
        let yearsTemp = [];

        for (
          let m = moment(startTempDate);
          m.isBefore(
            moment(moment(endTempDate).add(1, "days")).format("YYYY/MM/DD")
          );
          m.add(1, "days")
        ) {
          datesTemp.push(m.format("DD"));
          monthsTemp.push(m.format("MM"));
          yearsTemp.push(m.format("YYYY"));
        }

        let months = uniqWith(monthsTemp, isEqual);
        let years = uniqWith(yearsTemp, isEqual);

        setNoOfBookings(getIndividualResults(res.data.body[0].booking));
        setNoOfCallLogs(getIndividualResults(res.data.body[0].call_log));
        setNoOfClearances(getIndividualResults(res.data.body[0].clearance));
      })
      .catch((err) => {
        //console.log(err)
        // ToastCommon(true, "error", addToast);
      });
  };

  const getIndividualResults = (response) => {
    let arrangedDataTemp = [];
    let valuesTemp = [];
    forEach(teamMembers, function (member) {
      let A = filter(response, (item) => {
        let checkName = item.firstName + " " + item.lastName;

        return checkName === member;
      });

      let total = sumBy(A, function (item) {
        return item.value;
      });

      arrangedDataTemp.push({
        label: member,
        value: A.length > 0 ? total : 0,
      });

      valuesTemp.push(A.length > 0 ? total : 0);
    });
    return valuesTemp;
  };

  useDidMountEffect(() => {
    getAllRows();
    getTeamMembers();
  }, [ dateFrom, dateTo, selectedTeam, rangeType]);

  useEffect(() => {
    getTeams();
  }, []);
  const colourStyles = {
    option: (provided, state) => ({
      ...provided,
      color: "#6A6767",
    }),
  };

  return (
    <div className="card">
      <div className="card-body">
        <div className="header">
          <Row>
            <Col>
              <Label>Team Performance </Label>
              <ButtonGroup
                className="ml-3"
                size="sm"
                style={{
                  zIndex: 0,
                }}
              >
                <Button
                  disabled={isNull(selectedTeam) ? true : false}
                  onClick={() => {     
                    setRangeType("DAILY");
                    setDateFrom(today);
                    setDateTo("");
                    setRange(false);
                  }}
                  className={`${rangeType === "DAILY" && "active"}`}
                >
                  Daily
                </Button>
                <Button
                  disabled={isNull(selectedTeam) ? true : false}
                  onClick={() => {
                    setRangeType("WEEKLY");
                    setRange(false);
                  }}
                  className={`${rangeType === "WEEKLY" && "active"}`}
                >
                  Weekly
                </Button>
                <Button
                  disabled={isNull(selectedTeam) ? true : false}
                  onClick={() => {
                    setRangeType("MONTHLY");
                    setRange(false);
                  }}
                  className={`${rangeType === "MONTHLY" && "active"}`}
                >
                  Monthly
                </Button>
                <Button
                  disabled={isNull(selectedTeam) ? true : false}
                  onClick={() => {
                    setRangeType("YEARLY");
                    setRange(false);
                  }}
                  className={`${rangeType === "YEARLY" && "active"}`}
                >
                  Yearly
                </Button>
                <Button
                  disabled={isNull(selectedTeam) ? true : false}
                  onClick={() => {
                    setRangeType("MONTHLY");
                    setRange(true);
                    setDateFrom(today);
                    setDateTo(today);
                  }}
                  className={`${rangeType === "MONTHLY" && "active"}`}
                >
                  Range
                </Button>

                {/* <Button >Yearly</Button> */}
              </ButtonGroup>
              {rangeType != "DAILY" && range == false ? (
                <>
                  <Label className="mx-3">From : {startDateShowOnly}</Label>
                  <Label className="mx-3">To : {endDateShowOnly} </Label>
                </>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6} lg={6} xl={6}>
              <Label>Team </Label>
              {isNull(selectedTeam) ? (
                <Label className="mx-3">
                  * Please select a team to load the chart
                </Label>
              ) : null}

              <Select
                // isMulti
                name="candidateSpecialityId"
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(data) => setSelectedTeam(data)}
                options={team}
                styles={colourStyles}
              />
            </Col>
            {rangeType == "DAILY" || range == true ? (
              <Col>
                {rangeType == "DAILY" ? (
                  <Label for="exampleDate">Date</Label>
                ) : (
                  <Label for="exampleDate">Start Date</Label>
                )}

                <DateInput
                  id="dateFromTEAM"
                  value={dateFrom}
                  onChange={(date) =>
                    setDateFrom(date)
                  }
                />
              </Col>
            ) : null}

            {range == true ? (
              <Col>
                <Label for="exampleDate">End Date</Label>

                <DateInput
                  id="dateToTEAM"
                  value={dateTo}
                  onChange={(date) =>
                    setDateTo(date)
                  }
                />
              </Col>
            ) : null}
          </Row>

          <div className="links"></div>
        </div>
        <Bar data={data} options={options} />
      </div>
    </div>
  );
};

export default VerticalBar;
