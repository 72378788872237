import React, { useState } from "react";
import moment from "moment";
import { useForm } from "react-hook-form";
import { useToasts } from "react-toast-notifications";
import ToastCommon from "../../toastCommon";
import { Button, Label, Input, Form, FormGroup, Col, Row } from "reactstrap";
import DateInput from "../../../util/common/DateInput";
import dateFormatter from "../../../util/common/dateFormatter";

export default function SmallCalendar(props) {
  const { onCreate, closeModal } = props;
  
  const { register, handleSubmit, errors } = useForm();
  const [addKillLog, setaddKillLog] = useState(false);

  const { addToast } = useToasts();
  const [rowDate, setRowDate] = useState(null);
  const onSubmit = (data) => {
    console.log(" DATA", data);
    let diffDays;

    //dateDifferenceCheck

    let currentDate = moment(new Date()).format("YYYY-MM-DD");
    diffDays = moment
      .duration(moment(data.date).diff(moment(currentDate)))
      .asDays();
    if (diffDays >= 0) {
      const formattedRowDate = rowDate ? dateFormatter.formatDateToYYYYMMDD(rowDate) : null;
      let arrangedData = {
        date:formattedRowDate,
        time: data.time,
      
      };
      onCreate(arrangedData);
      console.log(" arrang DATA", arrangedData);
      
    } else {
      ToastCommon(true, "basicDateIssue", addToast);
    }

    setaddKillLog(true);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup row>
        <Label sm={4}>Date</Label>
        <Col sm={8}>
          {/* <Input
            type="date"
            name="date"
            innerRef={register({
              required: {
                value: true,
                message: "This field is required!",
              },
            })}
          /> */}
            <DateInput
        id="date"
        invalid={false} 
        register={() => {}} 
        value={rowDate} 
        onChange={setRowDate}
  
      />
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label sm={4}>Time</Label>
        <Col sm={8}>
          <Input
            type="time"
            name="time"
            innerRef={register({
              required: {
                value: true,
                message: "This field is required!",
              },
            })}
          />
        </Col>
      </FormGroup>
      <Row className="justify-content-end">
        <div className="d-flex ">
          {" "}
          <Button
            className="mr-2"
            color="success"
            type="submit"
            disabled={addKillLog}
          >
            Save
          </Button>{" "}
          <Button color="secondary" onClick={closeModal}>
            Close
          </Button>
        </div>
      </Row>
    </Form>
  );
}
